Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.contentTypeApiAddDetail = "application/json";
exports.validationApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.apiMethodTypeAddDetail = "POST";
//get cases for controller
exports.getCasesApiEndPoint = "bx_block_custom_form/cases";
//create cases for controller
exports.createCaseApiEndPoint = "bx_block_custom_form/cases";
exports.getMoodsApiEndPoint = "bx_block_dashboard/moods/get_all_moods?client_id=";
exports.getClientUpcomingAppointment='bx_block_appointment_management/availabilities/client_appointments';
exports.getUserGoalsEndApi = "bx_block_goal_management/goal_managements/completed_incompleted_goals?client_id=";
exports.getTaskEndApi = "bx_block_tasks/tasks/show_client_all_tasks?client_id=";
exports.tasksForControllerApiEndPoint = "bx_block_tasks/tasks/case_controller_task_list?client_id=";
exports.createUserApiEndPoint = "bx_block_custom_form/case_managements";
exports.appointmentsForControllerApiEndPoint = "bx_block_appointment_management/availabilities/case_controller_appointment_list?case_id=";
exports.caseByIdApiEndPoint = "bx_block_custom_form/cases/";
exports.getViewDetails = "bx_block_custom_form/case_managements/view_details?case_id=";
exports.deallocateApiEnd = "bx_block_custom_form/case_managements/deallocated?"
exports.getDetailsByIdApiEndPoint = "bx_block_custom_form/case_managements/view_associate_role?id=";
exports.addTaskApiEndPoint = "bx_block_tasks/tasks";
exports.addAppointmentApiEndPoint='bx_block_appointment_management/availabilities';
exports.editStatusApiEndPoint = 'bx_block_custom_form/cases/update_status?case_id='
exports.patchApiMethod = "PATCH";
exports.roleWiseUserList = "bx_block_custom_form/case_managements/list_roles?role_name=";
exports.assessmentsApiEndPoint = "bx_block_assessmenttest/assessments?client_id=";
exports.addAssessmentApiEndPoint= "bx_block_assessmenttest/assessments";
// Customizable Area End