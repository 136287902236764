// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import React, { Component, ReactNode } from 'react';
import './NavHeader.css';
import NavHeader from './NavHeader.web';
import back from "./go_back.png";

interface PageWrapperProps {
    pageHeader?: string;
    children?: ReactNode;
    title?: string;
    hasBack?: boolean;
    type?:string;
    function?:Function;
    mood?:boolean
}

class PageWrapper extends Component<PageWrapperProps> {
    goBackward =()=>{
        localStorage.setItem("longGoalStatus","pending")
       const view= localStorage.getItem("changeView")
       if(view){
        window.history.back()
       }
       else{
       this.props.function()
       localStorage.setItem("changeView",true)
       }
    }
    render() {
        
        const { pageHeader, children, title, hasBack,type,mood } = this.props;
        console.log("mood",mood);
        

        const _pageHeader = (
            <>
               <div style={{ display: 'flex', alignItems: 'center' }}>
                {type === "Goal" ? (
                    <>
                    {hasBack && (
                        <Button
                        type="text"
                        onClick={this.goBackward}
                        style={{ padding: 0, marginRight: '0.5em' }}
                        >
                        <img src={back} style={{ height: '1.5em', width: '1.5em' }} />
                        </Button>
                    )}
                    </>
                ) : (
                    <>
                    {hasBack && (
                        <Button
                        type="text"
                        onClick={() => window.history.back()}
                        style={{ padding: 0, marginRight: '0.5em' }}
                        >
                        <img src={back} className="page-header-back-icon" />
                        </Button>
                    )}
                    </>
                )}
                <p className='page-header-title'>{title}</p>
                </div>

            </>
        
      );

        return (
            
            <React.Fragment>

                <div className="page_header">
                    {pageHeader ?? _pageHeader}
                    <NavHeader showMood={this.props.mood} />
                </div>
                <div className="page_content">
                    {children}
                </div>
            </React.Fragment>
                    );
                }
            }

export default PageWrapper;
// Customizable Area End