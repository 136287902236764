Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypeAddDetail = "POST";
exports.apiDeleteMethodTypeAddDetail = "DELETE";
exports.apiUpdateMethodTypeGet = "PUT";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.searchClientApiEnnPoint = "bx_block_catalogue/folders/search";
exports.createFileApiEnnPoint = "bx_block_catalogue/user_libraries";
exports.getFileApiEndPoint = "bx_block_catalogue/user_libraries/client_user_library";
exports.deleteFileApiEndPoint = "bx_block_catalogue/user_libraries";
exports.createRehabJournalApiEndPoint = "bx_block_catalogue/user_libraries/create_rehab_journal";
exports.dateWiseEntriesApiEndPoint = "bx_block_catalogue/user_libraries/show_rehab_journal";
exports.replaceFileApiEndPoint = "bx_block_catalogue/user_libraries/replace_file"
// Customizable Area End