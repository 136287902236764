// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import './AppointmentStyles.css';
import AppointmentDashboardController from "./AppointmentDashboardController.web";
import PageWrapper from '../../../components/src/Pagewrapper.web';
import moment from 'moment';
import appoint from "../assets/calender.png"
import { Divider, Badge, Row, Col, Modal, Button, Form, Input, Select, TimePicker, DatePicker, Spin, Checkbox, Carousel} from 'antd';
import { view_image_img, delete_icon, edit_icon, cancel, chevron_left, chevron_right } from './assets';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {LeftOutlined,RightOutlined} from '@ant-design/icons'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import CalendarScheduler from "./CalendarScheduler.web"
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { FailedToastCenter } from '../../../components/src/Toast';
const { TextArea } = Input;
const dropdown = <ExpandMoreOutlinedIcon />;
const calender = <CalendarTodayIcon style={{ height: '24px', width: '24px' }} />;
const settings = {
	rows: 1,
	slidesToScroll: 1,
	dots: false,
	className: "center",
	infinite: false,
	centerPadding: "10px",
	slidesPerRow: 3,
	arrows: true,
	nextArrow: <div><RightOutlined style={{ color: '#94A3B8', fontSize: '18px' }} /></div>,
	prevArrow: <div><LeftOutlined style={{ color: '#94A3B8', fontSize: '18px' }} /></div>,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesPerRow: 2,
			}
		},
		{
			breakpoint: 900, 
			settings: {
				slidesPerRow: 2,
			}
		},
		{
			breakpoint: 650,
			settings: {
				slidesPerRow: 1,
			}
		}
	]
};
class AppointmentDashboardDetails extends AppointmentDashboardController {
	formRef: any;
	constructor(props: any) {
		super(props);
		this.formRef = React.createRef<FormInstance>();
	}
	async componentDidMount() {

		if(localStorage.getItem('userType') === 'client' ) {
			this.setState({pageTitle:"My Appointments",hasBack:false});
			this.getClientProfile();
		}else{
			this.setState({pageTitle:"Appointments",hasBack:true});
			this.getUpcomingAppointments();
		}	
		if(this.state.clientStatus === "closed"){
			FailedToastCenter("This case has been closed");
		}	
		setTimeout(()=>{this.setState({loadPage:false})},500)
	
	}
	
	renderAddAppointmentModals() {
		return (
			<Modal
				className="appointment-modal"
				id="appointment-modal"
				title={<div class="header-container1" style={{ padding: "20px 24px 15px 24px" }}>
					<h5 className="APP-title-heading" style={{ fontSize: '14px',
					 color: '#64748B',
					 fontWeight: 'bold' }}>Book Appointment </h5>
					<img class="image" data-testid="image-Data" onClick={this.cancelImage.bind(this,this.formRef)} style={{ height: '24px',
					 width: '24px' }}
					  src={cancel} />
				</div>}
				open={this.state.showAddModel}
				closable={false}
				footer={null}
				okButtonProps={{
					style: {
						display: "none", 
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none", 
					},
				}}
			>
				<Form
					data-testid="save-data"
					ref={this.formRef}
					layout="vertical"
					className="form"
					style={{ padding: "0 24px 20px 24px",
					 position: "relative" }}
					onFinish={() => {
						this.saveData(this.formRef.current.getFieldsValue());
						this.formRef.current.resetFields();
					}}

				>
					<Row gutter={12}>
						<Col xs={24}>
							<Form.Item
								label="Name of The Person"
								name="name"
								className="bold-label"
								validateFirst
								rules={[
									{
										required: true,
										message: "Please enter name",
									},
									{ min: 3 },
									{
										validator: this.customNameValidator,
									
									}
								]}

							>
								<Input 
								maxLength={30} 
								className="font-style" 
								placeholder='Enter name' />
							</Form.Item>
						</Col>
						<Col xs={24}>
						
							<div>
								<div style={{ padding: "10px 0", fontSize: "15px", fontWeight: "700", color: "#334155" }}>Date</div>
								<DatePicker
									id="date"
									key={this.state.dateValue}
									data-testid="StartDatePicker"
									className="font-style time-design"
									format="DD MMM YYYY"
									picker="date"
									inputReadOnly={true}
									disabledDate={this.handledatepicker}
									allowClear={false}
									suffixIcon={calender}
									value={this.state.dateValue ? moment(this.state.dateValue, "DD MMM YYYY") : null}
									getPopupContainer={this.handlePopupContainer}
									onChange={(date, dateString) => this.setState({ dateValue: dateString, dateError: null })}
									style={{ border: this.state.dateError !== null && "1px solid red" }}
								/>

							</div>
							{this.state.dateError !== "" ?
								<p data-test-id="error-msg" style={{
									color: '#DC2626',
									textAlign: 'left',
									marginTop: "1px",
									fontWeight:"400",
									fontSize: "12px"
								}}>{
										this.state.dateError}
								</p> : null}
							
						</Col>
						<Col xs={24}>
							<Form.Item
								label="Start Time"
								name="starttime"
								className="bold-label"
								rules={[
									{
										required: true,
										message: "Please select start time"
									}
								]}

							>

								<TimePicker
									className="font-style time-design"
									format={'HH:mm'}
									defaultOpenValue={moment('00:00', 'HH:mm')}
									id="start-time"
									suffixIcon={dropdown}
									inputReadOnly={true}
									allowClear={false}
									showNow={false}
									placeholder={"Set Time Slot"}
									onChange={this.handleStartDate}
									getPopupContainer={this.handlePopupContainer}
								/>
							</Form.Item>
							{this.state.startErrorMsg ?
								<p data-test-id="error-msg" style={{ 
									color: '#DC2626',
								 textAlign: 'left'
								 }}>{
									this.state.startErrorMsg}
								</p> : null}
						</Col>

						<Col xs={24}>
							<Form.Item
								label="End Time"
								name="endtime"
								dependencies={["starttime"]}
								className="bold-label"
								disabled={true}
								validateFirst
								rules={[
									{
										required: true,
										message: "Please select end time"
									},
								]}

							>

								<TimePicker
									className="font-style time-design"
									format={'HH:mm'}
									defaultOpenValue={moment('00:00', 'HH:mm')}
									id="end-time"
									suffixIcon={dropdown}
									inputReadOnly={true}
									allowClear={false}
									showNow={false}
									placeholder={"Set Time Slot"}
									onChange={() => this.setState({ errorMsg: '' })}
									getPopupContainer={this.handlePopupContainer}
								/>

							</Form.Item>
							{this.state.endDateTime  && this.state.errorMsg !== '' ?
								<p data-test-id="error-msg" style={{ 
									color: '#DC2626',
								 textAlign: 'left'
								 }}>{
									this.state.errorMsg}
								</p> : null}
						</Col>
						<Col xs={24}>
							<Form.Item
								name="title"
								label="Title"
								className="bold-label"
								rules={[
									{
										required: true,
										message: "Please enter title"
									},
									{ min: 4 }
								]}

							>

								<Input maxLength={64} className="font-style" placeholder='Enter title' onChange={()=>this.setState({titleError:false})}/>

							</Form.Item>
							{this.state.titleError && this.state.errorMsg !== '' ?
								<p data-test-id="error-msg" style={{ 
									color: '#DC2626',
								 textAlign: 'left'
								 }}>{
									this.state.errorMsg}
								</p> : null}
							
						</Col>
						<Col xs={24}>
							<Form.Item
								name="type"
								label="Type of Appointment"
								className="bold-label"
								rules={[
									{
										required: true,
										message: "Please select tye of appointment"
									},
								]}

							>

								<Select
									className="font-style apt-design"
									id="select-type"
									placeholder="Select Type of Appointment"
									options={[
										{ value: 'in_person', label: 'In Person' },
										{ value: 'online', label: 'Online' },
									]}
									suffixIcon={dropdown}
									getPopupContainer={this.handlePopupContainer}
								/>
							</Form.Item>

						</Col>
						<Col xs={24}>
							<Form.Item
								name="location"
								label="Location"
								className="bold-label"
								rules={[
									{
										required: true,
										message: "Please enter location"
									},
								]}

							>
								<Input
								 maxLength={100}
								  className="font-style"
								   placeholder='Enter location'
								 />
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
								name="purpose"
								label="Purpose (Optional)"
								className="bold-label"
							>
								<TextArea 
								maxLength={150} c
								lassName="font-style" 
								style={{ 
									resize: "none"
									 }} rows={4} 
									 placeholder="Enter purpose" />
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
								label="Additional Information (Optional)"
								name="information"
								className="bold-label"
							>
								<Input maxLength={200} className="font-style" placeholder='Enter Additional Information' />
							</Form.Item>
						
						</Col>
						<Col xs={24}>
							<Form.Item className='btnContainerapmnt'>
								<Button
									className="App-cancel-btn-style"
									data-testid="hide-model"
									loading={this.state.loading}
									type="primary"
									onClick={() => {
										this.hideModel()
										this.formRef.current.resetFields()
									}}
								>
									Cancel
								</Button>
								<Button
									className="App-submit-heading"
									loading={this.state.loadingData}
									htmlType="submit"
									type="primary"
									style={{
										height: "32px", width: '108px', color: "white",
										background: "#830065", borderColor: "#830065",
										borderRadius: "8px",
										fontWeight: "bold", fontSize: "14px",
									}}
									onClick={this.checkDate}
								>
									Save
								</Button>
							</Form.Item>
						</Col>

					</Row>
				</Form>

			</Modal>
		);
	}
	renderUpdateAppointmentModals() {
		return (
			<Modal
				className="appointment-modal"
				id="update-modal"
				title={<div class="header-container1" style={{ padding: "20px 24px 15px 24px" }}>
					<h5 className="APP-title-heading">Update Appointment </h5>
					<img class="image" onClick={() => {
						this.formRef.current.resetFields()
						this.hideModel1()
					}} style={{ height: '24px',
					 width: '24px' }} src={cancel} />
				</div>}
				open={this.state.showUpdateModel}
				closable={false}
				footer={null}
				okButtonProps={{
					style: {
						display: "none",
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
			>
				<Form
					data-testid="update-data"
					style={{ padding: "0 24px 20px 24px",
					 position: "relative" }}
					ref={this.formRef}
					onFinish={() => {
						this.updateData(this.formRef.current.getFieldsValue());
						this.formRef.current.resetFields();
					}}
					layout="vertical"
					className="form"
					fields={[{name: ['name'], value: this.state.name},{name: ['date'], value: this.state.date},{name: ['title'], value: this.state.title},{name: ['location'], value: this.state.location},{name: ['purpose'], value: this.state.purpose},{name: ['information'], value: this.state.information},{name: ['type'], value: this.state.appointmentType},{name: ['starttime'], value: this.state.startTime},{name: ['endtime'], value: this.state.endTime}]}
				>
					<Row gutter={12}>
						<Col xs={24}>
							<Form.Item
								name="name"
								className="bold-label"
								label="Name of The Person"
								validateFirst
								rules={[
									{ min: 3 },
									{
										required: true,
										message: "Please enter name"
									},
									{
										validator:this.customNameValidator,
									}
								]}

							>
								<Input className="font-style" placeholder='Enter name' maxLength={30} />
							</Form.Item>
						</Col>
						<Col xs={24}>
							<div>
								<div style={{ padding: "10px 0", fontSize: "15px", fontWeight: "700", color: "#334155" }}>Date</div>
								<DatePicker
									id="date"
									key={this.state.dateValue}
									data-testid="StartDatePicker1"
									className="font-style time-design"
									format="DD MMM YYYY"
									picker="date"
									inputReadOnly={true}
									disabledDate={this.handledatepicker}
									allowClear={false}
									value={this.state.dateEditValue? moment(this.state.dateEditValue, "DD MMM YYYY") : moment(this.state.date, "DD MMM YYYY")}
									suffixIcon={calender}
									onChange={(date, dateStringValue) => this.setState({ dateEditValue: dateStringValue, dateError: null })}
									getPopupContainer={this.handlePopupContainer}
								/>
							</div>
							
						</Col>
						<Col xs={24}>
							<Form.Item
							    name="starttime"
								className="bold-label"
								label="Start Time"
								rules={[
									{
										required: true,
										message: "Please select start time"
									},
								]}

							>

								<TimePicker
									id="start-time timer"
									suffixIcon={dropdown}
									inputReadOnly={true}
									allowClear={false}
									showNow={false}
									placeholder={"Set Time Slot"}
									getPopupContainer={this.handlePopupContainer}
									className="font-style time-design"
									format={'HH:mm'}
									defaultOpenValue={moment('00:00', 'HH:mm')}
								/>
							</Form.Item>
						</Col>

						<Col xs={24}>
							<Form.Item
								name="endtime"
								dependencies={["starttime"]}
								className="bold-label"
								validateFirst
								rules={[
									{
										required: true,
										message: "Please select end time"
									},
								]}
								disabled={true}
								label="End Time"
							>
								<TimePicker
									id="end-time"
									suffixIcon={dropdown}
									inputReadOnly={true}
									allowClear={false}
									showNow={false}
									placeholder={"Set Time Slot"}
									getPopupContainer={this.handlePopupContainer}
									className="font-style time-design"
									format={'HH:mm'}
									defaultOpenValue={moment('00:00', 'HH:mm')}
									
								/>

							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
								label="Title"
								className="bold-label"
								name="title"
								rules={[
									{ min: 4 },
									{
										required: true,
										message: "Please enter title"
									}
								]}

							>

								<Input className="font-style" placeholder='Enter title' maxLength={64}/>

							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
								label="Type of Appointment"
								className="bold-label"
								name="type"
								rules={[
									{
										required: true,
										message: "Please select tye of appointment"
									},
								]}

							>

								<Select
									id="select-type"
									className="font-style apt-design"
									placeholder="Select Type of Appointment"
									options={[
										{ value: 'in_person', label: 'In Person' },
										{ value: 'online', label: 'Online' },
									]}
									suffixIcon={dropdown}
								getPopupContainer={this.handlePopupContainer}
								/>
							</Form.Item>

						</Col>
						<Col xs={24}>
							<Form.Item
								label="Location"
								className="bold-label"
								name="location"
								rules={[
									{
										required: true,
										message: "Please enter location"
									},
								]}

							>
								<Input className="font-style" placeholder='Enter location' maxLength={100}/>
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
								label="Purpose (Optional)"
								className="bold-label"
								name="purpose"
							>
								<TextArea  className="font-style" style={{
									 resize: "none"
									 }} rows={4} placeholder="Enter purpose" maxLength={150}/>
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
								name="information"
								className="bold-label"
								label="Additional Information (Optional)"
							>
								<Input className="font-style" placeholder='Enter Additional Information' maxLength={200}/>
							</Form.Item>
							{this.state.errorMsg !== '' ?
								<p data-test-id="error-msg" style={{ color: '#DC2626',
								 textAlign: 'left' }}>{
									this.state.errorMsg}
								</p> : null}
						</Col>
						<Col xs={24}>
							<Form.Item className='btnContainerapmnt'>
								<Button
									className="App-cancel-btn-style"
									data-testid="hide-model1"
									onClick={() => {
										this.hideModel1()
										this.formRef.current.resetFields()
									}}
									
									loading={this.state.loading}
									type="primary"
									
								>
									Cancel
								</Button>
								<Button
									className="App-submit-heading"
									htmlType="submit"
									type="primary"
									loading={this.state.loadingData}
								>
									Update
								</Button>
							</Form.Item>
						</Col>

					</Row>
				</Form>

			</Modal>
		);
	}
	renderModalsDelete() {
		return (
			<Modal
			  className="delete-modal"
			  id="delete-modal"
			  open={this.state.showDeleteModel}
			  onCancel={this.hideDeleteModel}
			  footer={null}
			>
			<>
			  <div style={{
				padding: '50px 25px 20px 25px', fontSize: '16px',
				marginTop: '20px',fontWeight: 'bold'
			  }}>
				Are you sure you want to delete this Appointment?
			  </div>
			  <div style={{ display: "flex",
			   justifyContent: "right", padding: '0 22px 22px 22px' }} >
				  <Button
				 	data-testid="hide-app"
					style={{
					  height: "56px",width: "120px",
					  background: "#ffff",
					  borderColor: "#ffff",
					  borderRadius: "8px",color: "#830065",
					  fontSize: '16px',
					  fontWeight: '700'
					}}
					onClick={()=>this.hideDeleteModel()} >Cancel</Button>
				<Button
					data-testid="delete-app"
					style={{
					  height: "56px",
					  width: "120px",background: "#830065",
					  borderColor: "#830065",
					  borderRadius: "8px",color: "white",
					  fontSize: '16px',
					  fontWeight: '700'}}
					disabled={this.state.deleteLoad}
					onClick={()=>this.deleteAppointments(this.state.appointmentID)} >Yes</Button>
				</div>
			</>
			</Modal>
		);
	}
	hasUpcomingAppointments() {
		const { upcomingAppointments } = this.state;
		return upcomingAppointments && upcomingAppointments.length > 0;
	}
	isValidDate = (day, month, year) => {
        const date = new Date(year, month - 1, day);
        return date instanceof Date && !isNaN(date);
    };
	sortAppointment = (filteredData:any)=>{
		return filteredData.sort((a, b) => {
			const [dayA, monthA, yearA] = a.attributes.availability_date.split('/');
			const [dayB, monthB, yearB] = b.attributes.availability_date.split('/');
			
			const dateA = new Date(yearA, monthA - 1, dayA);
			const dateB = new Date(yearB, monthB - 1, dayB);

			if (dateA.getTime() === dateB.getTime()) {
				return a.attributes.start_time.localeCompare(b.attributes.start_time);
			}
			return dateA - dateB;
		});
	}
	deleteOutline = (id:number)=>{
		if(this.state.clientStatus !== "closed"){
			return this.showDeleteModel(id)
		} 
		else {
			return null
		}
	}
	editOutline = (id:number)=>{
		if(this.state.clientStatus !== "closed"){
			return this.getAppointmentDetails(id, "edit") 
		} 
		else {
			return null
		}
	}

	sortAppointments = (appointments) => {
		const filteredAppointments = appointments
			.filter((appointment) => {
				const [day, month, year] = appointment.attributes.availability_date.split('/');
				return this.isValidDate(day, month, year) && /^\d{2}:\d{2}$/.test(appointment.attributes.start_time);
			});

		if (filteredAppointments) {
			return this.sortAppointment(filteredAppointments);

		}


	};
	getCrousal = (sortedAppointments,deleteIcon,editIcon)=>{
		if (sortedAppointments.length > 0  ){
			return <Carousel {...settings} className='carousal'>
			{
				sortedAppointments
				.map((item, index) => {
					return (
						<div key={index}>
							<div className='list-item'>
								<div class="header-container1">
									<h5 className="font-style2">
										{item.attributes && item.attributes.title}</h5>
									<div className='iconContainer' style={{width:"58px"}}>
									<DeleteOutlineIcon
										className="image"
										data-testid="delete-data"
										onClick={this.deleteOutline.bind(this,item.attributes.id)}
										style={deleteIcon}
										alt="Delete"
									/>
	
									<EditIcon
										className="image"
										data-testid="edit-data"
										onClick={this.editOutline.bind(this,item.attributes.id)}
										style={editIcon}
										alt="Edit"
									/>
									</div>
	
								</div>
								<h5 className="name-style">
									{item.attributes.name}</h5>
								<h5 className='date'
									style={{
										fontSize: '14px',
										fontWeight:400
									}}>
									{this.getConvertDate(item.attributes.availability_date)} | {item.attributes.start_time}-{item.attributes.end_time}</h5>
								<div class="header-container1">
									<h5 className="font-style3" >
										<span style={{marginRight:"10px"}}>Added By:</span>
										{this.getAddedBy(item.attributes.added_by)}</h5>
									<h5 className="font-style"
										class="image1" data-testid="view-data"
										onClick={() => this.getAppointmentDetails(item.attributes.id, "view")}>View</h5>
								</div>
							</div>
						</div>
					);
				})}
			</Carousel>
		}
		else{
			return <Carousel {...settings} className='carousal2'>
			<div>
				<div className='list-itemEmpty'>
					<div className='cardInternal'>
						<img src={appoint} alt='appointment' />
						<span className='cardText'>
							No appointments scheduled yet</span>
					</div>
				</div>
			</div>

		</Carousel>
		}
		
	}
	render(): React.ReactNode {
		const { clientStatus, upcomingAppointments } = this.state;
		const sortedAppointments = this.sortAppointments(upcomingAppointments);
		let buttonStyle = {};
		let onClickHandler = null;
		if (clientStatus === "closed") {
			buttonStyle = {
			color: "grey",
			};
		} else {
			buttonStyle = {
			cursor: "pointer",
			color: "#830065",
			};
			onClickHandler = () => this.showModel();
		}
		let deleteIconStyle = {};
		let editIconStyle = {};
		let deleteOnClickHandler = null;
		let editOnClickHandler = null;
		const {itemId} = this.props;
		if (clientStatus === "closed") {
			deleteIconStyle = {
			color: "grey",
			fontSize:"24px"
			};
			editIconStyle = {
			color: "grey",
			};
		} else {
			deleteIconStyle = {
			cursor: "pointer",
			color: "#830065",
			fontSize:"24px"
			};
			editIconStyle = {
			cursor: "pointer",
			color: "#830065",
			fontSize:"24px"
			};
			
		}
		
		const selectedDayEvents = this.state.upcomingAppointments.filter(evt => {
			return evt.attributes.type_of_appointment.toLowerCase() === this.state.filterType.toLowerCase();
		  });
		const userType = localStorage.getItem('userType');
		return (
			this.state.loadPage ?
				<div className='spinEmpty'>
					<Spin spinning={this.state.loadPage} />
				</div>
				:
				<>
					{
						this.hasUpcomingAppointments() ?
							<PageWrapper title={this.state.pageTitle} hasBack={this.state.hasBack}>
								{userType !== "client" && 
									<div class="header-container3">
									<div class="clientheadContainer">
										<h3 class="client-text">
											Client :
										</h3>
										<h1 class="header-item1">
											{localStorage.getItem("clientName")}
										</h1>
									</div>
								</div>
								}
								{userType !== "client" && 
								<Divider type="horizontal" className='devider' />
								}
								<div class="header-container1">
									<div class="header-container">
										<h1 class="header-item2">
											UPCOMING APPOINTMENTS
										</h1>
										<Badge className="font-style1"
											showZero={true} color="#830065"
											count={this.state.upcomingAppointmentsCount}>
										</Badge>


									</div>
									<span
										data-testid="add"
										className="header-container"
										onClick={onClickHandler}
										style={buttonStyle}
										>
										<AddCircleOutlineIcon style={buttonStyle} alt="Add Icon" />
										<h1 className="header-item3" style={{ color: buttonStyle.color || "initial" }}>Add Appointment</h1>
									</span>

								</div>
								{this.getCrousal(sortedAppointments, deleteIconStyle,editIconStyle)}
								
								<Divider type="horizontal"
									className='devider' />
								{
									this.state.showCalendar && !this.state.deleteLoad &&
									<Row className='rowstyle' >
										<Col xs={24} sm={24} md={16} lg={12} xl={9}>
											<div className='apt-calender'>
												<Calendar
													data-testid="calendar-data"
													value={
														this.state.calenderDate
													}
													onChange={
														(value) => this.onDateChange(value)
													}
													prevLabel={
														<img data-testid="calendar-prev"
															class="previmage"
															src={chevron_left}
															onClick={
																this.previousmonth
															} />}
													nextLabel={<img data-testid="calendar-next"
														class="nextimage"
														src={chevron_right}
														onClick={this.nextmonth
														} />}
													next2Label={null}
													prev2Label={null}
													tileContent={(date) => this.state.upcomingDateList.includes(moment(date.date).format("DD/MM/YYYY")) ? <div className="apt-highlight">.</div> : null}

													activeStartDate={
														this.state.calenderDate
													}
												/>
												<div className="filter-event">
													<h5 className="filter-text">Filter </h5>
													{
														this.state.filterOption && this.state.filterOption.map((item) => {
															return (
																<div className="filter-checkbox">
																	<Checkbox
																		data-testid="filter-checkbox"
																		key={item.label}
																		onChange={this.onFilterChange}
																		checked={
																			item.value == this.state.filterType}
																		value={item.value}
																	>
																		{item.label}
																	</Checkbox>
																</div>
															);
														})}

												</div>

											</div>
										</Col>
										<Col className='apt-scheduler' xs={24} sm={24} md={24} lg={12} xl={15}>
											<CalendarScheduler
												data-test-id="calendarTestId"
												calenderDate={
													this.state.calenderDate}
												events={this.state.upcomingAppointments}
												filterType={
													this.state.filterType}
												setCalendarDate={this.setCalendarDate}
												ChangeBackwardDate={this.ChangeBackwardDate}
												showDate={
													this.state.showDate}
												prevDate={this.state.prevDate}
												selectedDayEvents={selectedDayEvents}
											/>
										</Col>
									</Row>
								}
							</PageWrapper>
							:

							<PageWrapper title={this.state.pageTitle} hasBack={this.state.hasBack}>
								<div class="header-container3">
									<div class="clientheadContainer">
										<h3 class="client-text">Client :</h3>
										<h1 class="header-itemEmpty">{localStorage.getItem("clientName")}</h1>
									</div>
								</div>

								<Divider type="horizontal" className='devider' />
								<div class="header-container1">
									<div class="header-container">
										<h1 class="header-item2" >UPCOMING APPOINTMENTS</h1>
										
									</div>
									<span
										data-testid="add"
										className="header-container"
										onClick={onClickHandler}
										style={buttonStyle}
										>
										<AddCircleOutlineIcon className="add-icon" alt="Add Icon" />
										<h1 className="header-item3" style={{ color: buttonStyle.color || "initial" }}>Add Appointment</h1>
									</span>
								</div>
								<Carousel {...settings} className='carousal2'>
									<div>
										<div className='list-itemEmpty'>
											<div className='cardInternal'>
												<img src={appoint} alt='appointment' />
												<span className='cardText'>
													No appointments scheduled yet</span>
											</div>
										</div>
									</div>

								</Carousel>
								<Divider type="horizontal" className='devider' />
								<Row
									className='rowstyle'>
									<Col span={9}>
										<div className='apt-calender'
											style={{ marginRight: '30px' }}>
											<Calendar
												data-testid="calendar-datavalue"
												value={this.state.calenderDate}
												onChange={(value) => this.onDateChange(value)}
												prevLabel={<img data-testid="calendar-prev"
													class="previmage" src={chevron_left}
													onClick={this.previousmonth} />}
												nextLabel={<img data-testid="calendar-next"
													class="nextimage" src={chevron_right} onClick={this.nextmonth
													} />}
												next2Label={null}
												prev2Label={null}
												activeStartDate={this.state.calenderDate}
											/>
											<div className="filter-event"><h5 className="filter-text">Filter </h5>
												{
													this.state.filterOption &&
													this.state.filterOption.map((item) => {
														return (
															<div className="filter-checkbox">
																<Checkbox data-testid="filter-checkbox"
																	key={item.label}
																	onChange={
																		this.onFilterChange}
																	checked={
																		item.value == this.state.filterType}
																	value={item.value}>
																	{item.label}
																</Checkbox>
															</div>);
													})}
											</div></div>
									</Col>
									<Col
										className='apt-scheduler'
										span={15} >
										<CalendarScheduler
											data-test-id="calendarTestId"
											calenderDate={
												this.state.calenderDate}
											events={
												this.state.upcomingAppointments}
											filterType={
												this.state.filterType}
											setCalendarDate={this.setCalendarDate}
											ChangeBackwardDate={this.ChangeBackwardDate}
											showDate={
												this.state.showDate}
											prevDate={this.state.prevDate} />
									</Col>
								</Row>
							</PageWrapper>

					}
					{this.renderAddAppointmentModals()}
					{this.renderUpdateAppointmentModals()}
					{this.renderModalsDelete()}

				</>
		);
	}
}

export default AppointmentDashboardDetails;
// Customizable Area End