import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from 'react-toastify'
type ScoreOptionValue = number | string | boolean;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  scoreValue?: {
    score_options: {
      [key: string]: ScoreOptionValue
    }[]
  }
  setError?: {
    score_title0?: string;
    score_title1?: string;
    score_title2?: string;
    score_title3?: string;
    score_title4?: string;
  };
  isEdit?: boolean;
  scoringQuestionFn: Function
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  score_options?: {
    [key: string]: ScoreOptionValue
  }[];
  option?: {
    [key: string]: ScoreOptionValue
  }[];
  erorrs: {
    [key: string]: ScoreOptionValue
  }[];

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ScoringController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  deleteOptionCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    let tempScoreOptionChecked = (this.props.isEdit) ? this.props?.scoreValue?.score_options?.map((item) => { return { ...item, checked: item.answer > 0 } }) : this.props?.scoreValue?.score_options?.map((item) => { return { ...item, answer: item.answer ? item.answer : "0", checked: false } });
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '',
      score_options: tempScoreOptionChecked ? tempScoreOptionChecked : [],
      erorrs: [{value: '', title: ''},{value: '', title: ''}]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        if (this.deleteOptionCallId == apiRequestCallId) {
          toast.success("Option Deleted Successfully");
        }
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = localStorage.getItem("authToken");
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    // Customizable Area End
  }
  changeChecked = (index: number, checked: boolean) => {
    let tempArr = (this.state.score_options && this.state.score_options.length) ? this.state.score_options : [];
    if (tempArr.length > 0) {
      tempArr[index]["checked"] = checked;
    }else{
      tempArr=[];
    }

    this.setState({ ...this.state, score_options: tempArr })
  }
  deleteOptions = (deletionId: number,scoringQuestionFn:Function) => {
    let tempArr = (this.state.score_options && this.state.score_options.length) ? this.state.score_options : [];
    if (tempArr.length > 0) {
      const scoringIndex = tempArr.findIndex((item)=>item.id===deletionId)
      if(scoringIndex !== -1){
        tempArr[scoringIndex]["is_deleted"] = true;
      }
      this.setState({ ...this.state, score_options: tempArr })
      scoringQuestionFn(tempArr)
    }else{
      tempArr=[];
      this.setState({ ...this.state, score_options: tempArr })
    }
  }

  handleEdit = (index: number, scoringQuestionFn: Function) => {
    if (!this.props.isEdit) {
      let tempArr = (this.state.score_options && this.state.score_options.length)  ? this.state.score_options : [];
      let error =   (this.state.erorrs && this.state.erorrs.length) ? this.state.erorrs : [];
      if(tempArr.length > 2){
        tempArr.splice(index,1)
        error.splice(index,1)
        
        this.setState({ ...this.state, score_options: tempArr, erorrs: error })
        this.props.scoringQuestionFn(tempArr);
      }
    } else {
      this.deleteOptions(this.props.scoreValue?.score_options[index].id as number,scoringQuestionFn)
    }
  }

  addOption = (scoringQuestionFn: Function) => {
    const initailScoringData = (this.state.score_options && this.state.score_options.length)  ? this.state.score_options : [];
    const scoreData = { title: '', answer: '0', checked: false }
    const erorrs = (this.state.erorrs && this.state.erorrs.length) ? this.state.erorrs : [];
    erorrs.push({title: '', answer: ''})
    initailScoringData.push(scoreData)
    this.setState({
      score_options: initailScoringData,
      erorrs
    });
    scoringQuestionFn(initailScoringData);
  }

  handleChange = (event: number | string , index: number, name: string, scoringQuestionFn: Function) => {
    const option =  (this.state.score_options && this.state.score_options.length) ? this.state.score_options : [{value: '', title: ''}];
    option[index][name] = event;
    if(name === 'answer' && 100 < Number(event)){
      const error = (this.state.erorrs && this.state.erorrs.length) ? this.state.erorrs : [{value: '', title: ''}];
      error[index]['value'] = 'Max number is 100'
      this.setState({
        erorrs: error
      })
      return false
    }

    this.setState({
      score_options: option
    })
    scoringQuestionFn(option)
  }

  // Customizable Area End
  
}
