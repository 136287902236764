Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.getAssessementMethod = "GET";
  exports.patchAssessementMethod = "PATCH";
  exports.submitAssessementTestAPiMethod = "POST";
  exports.allAssessementTestApiContentType = "application/json";
  exports.getAllAssessementTestUrl = `bx_block_assessmenttest/assessments`;
  exports.apiSubmitAssessmentQuestions = `bx_block_assessmenttest/submit_que_answer`;
  exports.submitAssessementTestUrl = `bx_block_assessmenttest/submit_assessment?assessment_id=`;
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "AssessmentTest";
  exports.labelBodyText = "AssessmentTest Body";
  exports.scalerScoreApi1 = "bx_block_assessmenttest/assessments/submit_client_score?id=";
  exports.scalerScoreApi2= "&score=";
  exports.accept1= "bx_block_assessmenttest/assessments/accept?id=";
  exports.accept2= "&score=";
  exports.gadAnxietyapi="bx_block_assessmenttest/assessment_problems";
  exports.viewAssessment="bx_block_assessmenttest/assessments/view_functional_assessment?id="
  exports.redoAssessment="bx_block_assessmenttest/assessments/redo_assessment?id="
  exports.calculateScore="bx_block_assessmenttest/assessments/calculate_score?id="
  exports.viewAssessmentAdmin="bx_block_assessmenttest/assessments/view_assessment?id="

    // Customizable Area End