// Customizable Area Start
import React from 'react';
import { award, complete, completed, dash, happy, incomplete, joy, noGoal, ongoing, pending, positive, sad, noAppointment } from "./assets";
import moment from 'moment';
import CaseController from './CaseController.web';
import {Button, Row, Col} from 'antd';
import { Link } from "react-router-dom";
import PageWrapper from '../../../components/src/Pagewrapper.web';


const moodImages: { [key: string]: string } = {
    "not_feeling_good": sad,
    "neutral": positive,
    "positive": happy
}; 
class ClientDashboard extends CaseController {
	constructor(props: any) {
		super(props);
	}
    async componentDidMount(){
		this.getMoods();
        this.getUpcomingAppointments();
        this.getAlTermsGoals();
        this.getTasks();
	}

    renderGoalsSection(title: string, goals: any[], icon: string) {
        return goals?.length > 0 && (
            <>
                <p className="goal-term-title">{title}</p>
                <div className="vertical-flex-container">
                    {goals.slice(0, 4).map((goal: any) => (
                        <div className="horizontal-flex-item" key={goal.id}>
                            <img src={icon} alt="goal" />
                            <p className="term-title">{goal.name}</p>
                        </div>
                    ))}
                </div>
                <hr className="term-divider" />
            </>
        );
    }
    

    renderCompletedGoals() {
        const completedGoals = this.state.goalData?.completed_goals || {};
        const { short_term = [], medium_term = [], long_term = {} } = completedGoals;
        const longTermGoal = long_term?.name ? (
            <div>
              <p className="goal-term-title">Long Term Goals</p>
              <div className="horizontal-flex-item">
                <img src={award} alt="goal" />
                <p className="term-title">{long_term.name}</p>
              </div>
              <hr className="term-divider" />
            </div>
          ) : null;
        return (
            <div className="goal-content" id="goal">
                <p className="mood-header">Completed Goals</p>
                {longTermGoal}
                {this.renderGoalsSection("Short Term Goals", short_term, award)}
                {this.renderGoalsSection("Medium Term Goals", medium_term, award)}
                {(short_term.length > 0 || medium_term.length > 0) ? (
                    <div className="horizontal-flex-item">
                        <img src={joy} style={{ height: '48px', width: '48px' }} alt="goal" />
                        <p className="term-title">
                            Hi, <strong>{this.getCapitalizedFirstName()}</strong> great job. You have completed the above goals assigned to you.
                        </p>
                    </div>
                ) : ( 
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                        <img src={noGoal} alt="No completed goals" style={{ height: '48px', width: '48px', marginBottom: '6px' }} />
                        <p className='no-goal-text'>You have yet to complete your goals!</p>
                    </div>
                )}
            </div>
        );
    }

    renderIncompleteGoals() {
        const incompleteGoals = this.state.goalData?.incompleted_goals || {};
        const { long_term = [], short_term = [], medium_term = [] } = incompleteGoals;
        const userType = localStorage.getItem("userType");
        const noIncompleteGoals = long_term.length === 0 && short_term.length === 0 && medium_term.length === 0;
        return (
            <div className="goal-content-incomplete">
                <div className="task-header">
                    <p style={{margin: '0px'}} className="mood-header">Incomplete Goals</p>
                    {userType !== "solicitor" && userType !== "guest" &&
                        <Link to={`/goal-management-details`}>
                            <Button
                                data-testid="submit-mood"
                                className="update-task-button"
                                htmlType="submit"
                                type="primary"
                                disabled={noIncompleteGoals}
                            >
                                Update
                            </Button>
                        </Link>
                    }
                </div>
                {this.renderGoalsSection("Long Term Goals", long_term, incomplete)}
                {this.renderGoalsSection("Short Term Goals", short_term, incomplete)}
                {this.renderGoalsSection("Medium Term Goals", medium_term, incomplete)}
                {(short_term.length > 0 || medium_term.length > 0 || long_term.length > 0) ? (
                    <div className="horizontal-flex-item">
                        <p className="term-title">
                            Hi, <strong>{this.getCapitalizedFirstName()}</strong> you're almost there. Please complete your pending goal.
                        </p>
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={completed} alt="No incomplete goals" style={{ height: '48px', width: '48px', marginBottom: '6px' }} />
                        <p className='no-goal-text'>Great! You have no incomplete goals!</p>
                    </div>
                )}
            </div>
        );
    }

    getCapitalizedFirstName() {
        const fullName = localStorage.getItem("fullName") || "";
        const firstWord = fullName.split(" ")[0];
        return firstWord.charAt(0).toUpperCase() + firstWord.slice(1).toLowerCase();
    }

    getStatusImage(status: 'completed' | 'ongoing' | 'pending'): string {
        switch (status) {
            case 'completed':
                return complete;
            case 'ongoing':
                return ongoing;
            case 'pending':
                return pending;
            // default:
            //     return '';
        }
    }

    getMoodImage(mood: any) {
        if (!mood.mood || !mood.mood.client_mood) {
            return dash;
        }
        return moodImages[mood.mood.client_mood] || dash;
    }
	render(): React.ReactNode {
        const pendingTasks = this.state.taskData?.filter((task: any) => task.attributes.status === 'pending').length;
        const userType = localStorage.getItem("userType")
		return (
			<PageWrapper 
                title="Dashboard"
                mood={true}
                hasBack={userType !== "client"}>
                    <div style={{ padding: '13px' }}>
                        <Row gutter={[20, 20]} className="responsive-row">
                            <Col xs={24} md={14} lg={14} xl={16} className="responsive-col">
                            {userType !== "solicitor" && userType !== "guest" && (
                                <div id='case-id' className='mood-content'>
                                <p className="mood-header">My Mood score</p>
                                <div className="mood-list">
                                    {this.state.moodData.map((mood: any, index: number) => (
                                    <div key={`${mood.id}-${index}`} className="mood-item" data-test-id="mood-list">
                                        <img 
                                        src={this.getMoodImage(mood)}
                                        alt={mood.client_mood} 
                                        title={mood.client_mood}
                                        style={this.getMoodImage(mood) === dash ? { width: '48px', height: '48px' } : {}}
                                        />
                                        <span className="mood-date">{moment(mood.created_date).format('D MMM')}</span>
                                        <span className="mood-day">{mood.created_day}</span>
                                    </div>
                                    ))}
                                </div>
                                </div>
                            )}
                            <div className='task-dashboard-content'>
                                <div className="task-header">
                                <p className="task-status-title">Task Status</p>
                                {userType !== "solicitor" && userType !== "guest" && (
                                    <Link to={`/view-all-task?id=${localStorage.getItem("clientId")}`}>
                                    <Button
                                        data-testid="submit-mood"
                                        className="update-task-button"
                                        htmlType="submit"
                                        type="primary"
                                        style={{ marginRight: '-40px' }}
                                    >
                                        Update
                                    </Button>
                                    </Link>
                                )}
                                </div>
                                <p id="client-task" className="pending-title">
                                Pending tasks {pendingTasks}/{this.state.taskData?.length}
                                </p>
                                {this.state.taskData?.slice(0, 5).map((task: any) => (
                                <div className="task-item" key={task.id}>
                                    <p className="task-title">{task.attributes.title}</p>
                                    <img src={this.getStatusImage(task.attributes.status)} alt={task.attributes.status} />
                                </div>
                                ))}
                            </div>
                            <div className='appoint-dashboard-content'>
                                <div className="up-header">
                                <p className="upcoming-appoint-text">Upcoming Appointments</p>
                                {userType !== "solicitor" && (
                                    <Link to="/appointment-details">
                                    <Button
                                        className="view-all-button"
                                        htmlType="submit"
                                        type="primary"
                                        style={{ backgroundColor: this.state.upcomingAppointmentList.length > 0 ? '#830065' : '#94A3B8' }}
                                        disabled={this.state.upcomingAppointmentList.length === 0}
                                    >
                                        View All
                                    </Button>
                                    </Link>
                                )}
                                </div>
                                {this.state.upcomingAppointmentList?.length > 0 ? (
                                    <div style ={{width: "100%"}}>
                               <Row gutter={[16, 16]}>
                               {this.state.upcomingAppointmentList.slice(0, 6).map((e: any, index: number) => (
                                 <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} key={index}>
                                   <div id="up-card" className="up-appoint-card-2">
                                     <p className="appoint-title">{e.attributes.title}</p>
                                     <p className="appoint-name">{e.attributes.name}</p>
                                     <p className="appoint-date">{moment(e.attributes.availability_date, 'DD/MM/YYYY').format('MMM D')}</p>
                                     <p className="appoint-time">{e.attributes.start_time} - {e.attributes.end_time}</p>
                                   </div>
                                   {/* <div style = {{background: 'red', padding: "20px", width: "100%"}}>
                                    hello
                                   </div> */}
                                 </Col>
                               ))}
                             </Row>
                             </div>
                                ) : (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                                    <img src={noAppointment} alt="No Upcoming Appointments" style={{ height: '48px', width: '48px' }} />
                                    <p>No Upcoming Appointments</p>
                                </div>
                                )}
                            </div>
                            </Col>
                            <Col xs={24} md={10} lg={10} xl={8} className="responsive-col">
                            {this.renderCompletedGoals()}
                            {this.renderIncompleteGoals()}
                            </Col>
                        </Row>
                    </div>

			</PageWrapper>
		);
	}
}

export default ClientDashboard;

// Customizable Area End