import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  CircularProgress
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider,styled } from "@material-ui/core/styles";
const noData = require( "../../assets/EmptyAssess.png")
import PageWrapper from "../../../../components/src/Pagewrapper.web";
import {Col, Form, Modal, Row, Spin, Table,Button as AntdButton,Select as AntdSelect, FormInstance } from 'antd';
import { view_image_img } from "../../../appointmentmanagement/src/assets";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { Assessment2,History } from "./AssessmentDetailController";

const dropdown = <ExpandMoreOutlinedIcon />;

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AssessmentDetailController, {
  Props,
} from "./AssessmentDetailController";

export default class AssessmentDetail extends AssessmentDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
		this.formRef = React.createRef<FormInstance>();
    const Client= localStorage.getItem("userType") === "client"
    this.title= Client ? "My Assessments" : "Assessment"
    // Customizable Area End
  }

  // Customizable Area Start
  title;
  getIcon = (data:string)=>{
    if(data === "pending"){
      return <RadioButtonUncheckedIcon style={{color:"#F7D6D0",fontSize:"12px",marginRight:"6px",height:"12px",width:"12px"}}/>
    }
   else if(data === "completed"){
      return <CheckCircleIcon style={{color:"#006845",fontSize:"12px",marginRight:"6px"}}/>
    }
    else if(data === "approval_pending"){
      return <RadioButtonUncheckedIcon style={{color:"#F7D6D0",fontSize:"12px",marginRight:"6px",height:"12px",width:"12px"}}/>
    }
    else{
      return <CancelIcon style={{color:"#FF9595",fontSize:"12px",marginRight:"6px"}}/>
    }
  }
  formRef: any;
  dropdownStyle = {
		border: '1px solid #CBD5E1 ',
		borderRadius: "10px",
		padding:"0px" ,
		marging:"0px",
	  };
  
    renderAddAssessmentModal() {
      return (<AntDModal
          className="appointment-modal"
          title={<TitleContainer 
          style={{width:"440px"}}>
            <TitleHeading >Add Assessment </TitleHeading>
  
          </TitleContainer>}
          open={this.state.showAddModal}
          closable={false}
          footer={null}
          okButtonProps={{
            style: {display: "none"},
          }}
          cancelButtonProps={{
            style: {display: "none"},
          }}
        >
          <CustomForm
            data-testid="Edit-save-data"
            ref={this.formRef}
            layout="vertical"
            onFinish={
              ()=>this.saveAddAssessmentForm(this.formRef)
            }
          >
            <Row gutter={12}>
              <AntDRow>
                <Col xs={24}>
                <AntDFormItem
                  label="Assessment"
                  className="bold-lable"
                  name="Assessment"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Assessment"
                    },
                  ]}
                >
                  <AntDSelectBox
                    id="select-type"
                    className="bold-lable"
                    dropdownStyle={this.dropdownStyle}
                    placeholder="Select Assessment"
                    suffixIcon={dropdown}
                    getPopupContainer={this.getPopupContainer}
                  >
                    <option value= "Functional-Pain" style={{color:"#94A3B8"}}> Functional Pain</option>
                    <option value= "GAD-7-Anxiety"  style={{color:"#94A3B8"}}> GAD 7 Anxiety</option>
                    <option value= "Pittsburgh-Sleep-Quality-Index"  style={{color:"#94A3B8"}}> Pittsburgh Sleep Quality Index</option>
                    <option value= "Modified-Fatigue-Impact-Scale" style={{color:"#94A3B8"}}> Modified Fatigue Impact Scale</option>
                    <option value= "PHQ-9-Depression"  style={{color:"#94A3B8"}}> PHQ 9 Depression</option>
                    </AntDSelectBox>
                </AntDFormItem>
  
                </Col>
                <Col xs={24}>
                <AntDFormItem
                  label="Frequency"
                  className="bold-lable"
                  name="Frequency"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Frequency"
                    },
                  ]}
                >
                  <AntDSelectBox
                    id="select-type1"
                    className="bold-lable"
                    dropdownStyle={this.dropdownStyle}
                    placeholder="Select Frequency"
                    suffixIcon={dropdown}
                    getPopupContainer={this.getPopupContainer}
                  >
                    <option  value= 'weekly'  style={{color:"#94A3B8"}}> Weekly</option>
                    <option value= "monthly"  style={{color:"#94A3B8"}}> Monthly</option>
                    </AntDSelectBox>
                </AntDFormItem>
			  			</Col>
						</AntDRow>
						<div className="formfooter">
						<Col xs={24}>
							<AntDFormItemContainer className='btnContainer' >
								<AntdCancelButton
									data-testid="hide-model1"
									type="primary"
									onClick={() => {
										this.cancelButton(this.formRef)
									}}
								>
									Cancel
								</AntdCancelButton>
								<AntdAddButton
									className="submit-heading"
									loading={this.state.addAssessLoader}
									htmlType="submit"
									type="primary"
								>
									Add
								</AntdAddButton>
							</AntDFormItemContainer>
						</Col>
						</div>
					</Row>
				</CustomForm>
			</AntDModal>
		);
	}
  CustomEmpty = (loader:boolean) => (
		<div style={{ textAlign: 'center',}}><div style={{width:"100%",
			display:"flex",justifyContent:"center",
			backgroundColor:"red",height:"30%"}}>
		  <Spin spinning={loader} />
			</div> <div
			style={{
			  height: '50px',backgroundSize: 'contain',
			  backgroundRepeat: 'no-repeat',backgroundPosition: 'center',
			  padding:"0px 20px", marginBottom:"10px"
			}}
		  />
		 <span >
      No Data
      </span>
		</div>
	  );
   getSelectBox = (data:string)=>{
    if(data === "approval_pending"){
      return <SelectBox2
        data-testid="selectGoalStatus2"
        native
        value={"approval-pending"}
        disabled={true}
        inputProps={{
          name: 'goalStatus',
          id: 'select-goal-status',
          }}
          IconComponent={CustomIcon}
      >
        <option value={"approval-pending"} >Approval Pending</option>
      </SelectBox2>
    }
    if(data === "reassess"){
      return 	<SelectBox1
        data-testid="selectGoalStatus2"
        native
        value={"reassess"}
        disabled={true}
        inputProps={{
          name: 'goalStatus',
          id: 'select-goal-status',
          }}
          IconComponent={CustomIcon}
      >
        <option value={"pending"}>Pending</option>
        <option value={"approval_pending"} >Approval Pending</option>
        <option value={"reassess"} >Reassess</option>

      </SelectBox1>
    }
    else{
      return <SelectBox
        data-testid="selectGoalStatus2"
        native
        value={"pending"}
        disabled={true}
        inputProps={{
          name: 'goalStatus',
          id: 'select-goal-status',
          }}
      >
        <option value={"pending"}>Pending</option>
      </SelectBox>
    }
  }
  renderEmptyData = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '40%', width: '100%' }}>
            <img src={noData} alt="No Data" className='image-q' />
            <div className='text-no'>No assessments added yet</div>
        </div>
    );
}
  columns:any = [
		{
			title: (<SpanName>Assessment Name</SpanName>),
			width: 200,
			ellipsis: true,
			render: (event: Assessment2) => (
				<TableData1>
					{this.getIcon(event.attributes.status)}
          { event.attributes.assessment_name === "GAD-7-Anxiety"? "GAD-7 Anxiety":
          event.attributes.assessment_name.replace(/-/g, ' ')}
				</TableData1>
			),
		},
		{
			title: (<Span>Assigned By</Span>),
			ellipsis: true,
      width:80,
			render: (event: Assessment2) => (
				<TableData>
					{event.attributes.assigned_by}
				</TableData>
			),
		},
    {
			title: (<Span>Assigned Date</Span>),
			ellipsis: true,
      width:80,
			render: (event: Assessment2) => (
				<TableData>
					{event.attributes.assigned_date}
				</TableData>
			),
		},
    {
			title: (<Span>Score</Span>),
			ellipsis: true,
      width:80,
			render: (event: Assessment2) => (
        <TableData3>
          {
            event.attributes.score && event.attributes.status === "completed" ? (
              <>
                {event.attributes.score }
              </>
            ) : (
              <span style={{ color: "#94A3B8", fontSize: "12px", fontWeight: 600,textTransform:"none" }}>Score is pending</span>
            )
          }
        </TableData3>
			),
		},
    {
			title: (<Span>Period</Span>),
			ellipsis: true,
      width:80,
			render: (event: Assessment2) => (
				<TableData2>
					{event.attributes.period}
				</TableData2>
			),
		},
    {
			title: (<Span>Status</Span>),
			ellipsis: true,
      width:80,
			render: (event: Assessment2) => (
        event.attributes.status === "completed"?
				<span 
				style={{
					textTransform:"capitalize"
				}}
				 className={`name-text ${event.attributes.status === "completed" ?
				  "status-completed" : ""}`}>
					{event.attributes.status}
				</span>
				:
		
				 	<FormControl variant="outlined">
					{this.getSelectBox(event.attributes.status)}
    
				 </FormControl>
			),
		},
		{
			title: (<Span>Action</Span>),
			dataIndex: '',
      width:80,
			key: (event: Assessment2) => event.attributes.client_id,
			render: (event:Assessment2) => <a className='view-text'  onClick={this.assessmentDetail.bind(this,event)}><Span3>{this.getButtonName(event.attributes.status)}</Span3></a>,
		},
	];
  
  historycolumns:any = [
		{
			title: (<Span2>Name Of Assessment</Span2>),
      width:280,
			ellipsis: true,
			render: (event: History) => (
				<TableData1>
          {event.assessment_name}
				</TableData1>
			),
		},
		{
			title: (<Span2>Date of Submission</Span2>),
			ellipsis: true,
      width:280,
			render: (event: History) => (
				<TableData>
					{event.submit_date ? event.submit_date  : "Nil"}
				</TableData>
			),
		},

    {
			title: (<Span2>Score</Span2>),
			ellipsis: true,
      width:280,
      key: (event: Assessment2) => event.id,
			render: (event: History) => (
        <TableData3>
          {
            event.score ? (
              <>
                {event.score}
               
              </>
            ) : (
              <span style={{ color: "#94A3B8", fontSize: "12px", fontWeight: 600 }}>Score is pending</span>
            )
          }
        </TableData3>
			),
		},

	];
  getHistory = ()=>{
    return this.state.assessmentHistory
  }
  handlePagination = (page:any) =>{
    localStorage.setItem("PageNum",page);
    this.setState({pagination:page})
  } 
  renderAssessmentDetailWebLoader() {
    if (this.state.isLoading) return (
      <Box
        data-test-id={"loaderContainer"}
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }



  renderAssessmentDetailWebError() {
    if (this.state.isDetailError) return (

      <Box
        className=""
        style={{
          alignItems: 'center',
          flexDirection: 'column',
          height: '100vh',
          display: 'flex',
          width: '100%',
          justifyContent: 'center',

        }}
      >
        <Typography
          variant="subtitle1"
          component="h1"
          className=""
          data-test-id={"errorMessageId"}
        >
          Something went wrong, please try again 
        </Typography>
        <Button
          color="primary"
          style={{
            backgroundColor: '#210042'
          }}
          data-test-id={"retryButtonId"}
          variant="contained"

          onClick={() => this.onReFetch()}
        >
          Retry

        </Button>
      </Box>
    )
  }

 renderAssessmentPage = ()=>{
  return (
    <>
       <TopHeading>
        {
          this.state.userType !=="client" ?
          <TopButton onClick={this.handlepage} >
					View Assessment History
				</TopButton>:
        null
        }
        </TopHeading>
        <HeadingContainer>
          <HeadingBox>
            <P style={{marginLeft:"1.438rem"}} >Assessment Tests</P>
            {
              this.state.userType !=="client" ?
              <ButtonContainer onClick={this.showAssessmentModal} style={{marginRight:"24px",cursor:this.state.clientStatus !== "closed" ? "pointer" : "not-allowed"}}>
              <Anchor data-testid="showlongTermModelId" >
              <AddCircleOutlineIcon style={{
											color: this.state.clientStatus === "closed" ? "grey" :"#830065"
										}} />
                <Heading1
                style={{
                  color: this.state.clientStatus === "closed" ? "#808080" :"#830065"
                }} 
                >Add Assessment</Heading1>
                </Anchor>
              </ButtonContainer>:
              null
            }
          
          </HeadingBox>
          {this.state.assessmentList.length > 0 ?
            <TableDesign
            data-testid="AssessmentTable"
            columns={this.columns}
            bordered
            dataSource={this.getDataSource()}
            scroll={{ x: 'max-content' }} 
            pagination={{
              current: this.state.pagination ?this.state.pagination:1 ,
              onChange:this.handlePagination
            }}
            locale={{
							emptyText: this.CustomEmpty(this.state.clientListLoader)
						  }}
          />
          :
          this.renderEmptyData()
          }
        
        </HeadingContainer>
    </>
  )
 }
 renderAssessmentHistory= ()=>{
  return [
    <>
    <HeadingContainer>
          <HeadingBox>
            <HistoryTitle>Assessment History</HistoryTitle>
          </HeadingBox>
          {this.state.assessmentList.length > 0 ?
            <TableDesign
            data-testid="AssessmentTable"
            columns={this.historycolumns}
            bordered
            dataSource={this.getHistory()}
            scroll={{
              y: "calc(100vh - 300px)"
            }}
            locale={{
							emptyText: this.CustomEmpty(this.state.clientListLoader)
						  }}
          />
          :
          this.renderEmptyData()
          }
        
        </HeadingContainer>
    </>
  ]
 }

  renderContent() {
    if (this.state.isSuccess) return (
      <ThemeProvider
        theme={theme}>

        <Box
          data-test-id={"AssessmentDetailContainer"}
          style={{
            position: "relative",
            padding: "12px 30px",
            background: "rgb(37, 0, 65)",
            color: "rgb(255, 255, 255)",
          }}>
          <Typography
            style={{
              fontSize: '1.3rem'
            }}
            variant="subtitle1" component="h2">
          </Typography>
        </Box>
        <Container
          style={{
            minHeight: "100vh",
            paddingTop: "1rem",
            padding: "2rem",
          }}
        >
          <Box sx={{
            ...webStyle.card
          }}>
            <Box
              style={{
                position: "relative",
                height: "250px",
                background: "rgb(81, 81, 81)",
                overflow: "hidden",
                borderRadius: "6px",
              }}
            >
              <img
                style={{
                  width: "100%"
                }}
                src={this.state.itemContent.attributes?.image} alt="" />
            </Box>
            <Box >

              <Typography
                data-test-id={"assessmentName"}
                style={{
                  marginBottom: '1rem'
                }}
                component="p">
                {this.state.itemContent?.attributes?.name}
              </Typography>

              <Typography
                style={{
                  marginBottom: '1rem'
                }}
                component="p">
                Assessment instruction:
              </Typography>
              <Typography
                style={{
                  marginBottom: '1rem'
                }}
                component="p">
                {this.state.itemContent.attributes?.description}
              </Typography>

              <Typography
                style={{
                  marginBottom: '1rem'
                }}
                
                component="p">
                Total Question:
                {this.state.itemContent?.attributes?.number_of_questions}
              </Typography>
              <Typography
                style={{
                  marginBottom: '1rem'
                }}
                component="p">
                {this.convertMinutesToHoursAndMinutes(this.state.itemContent.attributes?.duration ? this.state.itemContent.attributes?.duration : 0)}
              </Typography>
              <Button
                data-test-id={"startTestBtn"}
                style={{
                  backgroundColor: '#210042'
                }}
                variant="contained"
                color="primary"
                onClick={() => this.startAssessment()}
              >
                Start Test
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
  // Customizable Area End


  render() {
    // Customizable Area Start
    return (
      <>
      <PageWrapper title={this.title} hasBack={this.state.userType==="client"? false : true}>
        {this.state.showAssessmentPage ?
          this.renderAssessmentPage()
          :
          this.renderAssessmentHistory()
        }
     
      </PageWrapper>
      {this.renderAddAssessmentModal()}
      </>
    )
    // Customizable Area End

  }
}

// Customizable Area Start
const HeadingContainer = styled("div")({
  marginTop: "1.25rem",
  background: "white",
  borderRadius: "0.5rem 0.5rem 2rem 0.5rem",
  marginBottom: "0.625rem",
});
const TopHeading = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "1.25rem",
});
const HeadingBox = styled("div")(({theme})=>({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("xs")]:{
   flexDirection:"column",
   alignItems:"start"
  }
})
)

const ButtonContainer = styled("div")(({theme})=>({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xs")]:{
    width:"100%",
    alignItems:"end",
    justifyContent: "end",
  }
})
)

const P = styled("p")(({theme})=>({
  color: "#0F172A",
  fontSize: "1.25rem",
  fontWeight: 700,
  marginTop: "0.875rem",
  [theme.breakpoints.down("xs")]:{
    fontSize: "14px",
  }
})
)

const HistoryTitle = styled("p")({
  color: "#64748B",
  fontSize: "14px",
  fontWeight: 700,
  marginTop: "0.875rem",
  marginLeft:"22px"
});
const Span = styled("span")({
  color: "#0F172A",
  marginTop: "0.25rem",
  fontSize: "0.875rem",
  fontWeight: 700,
});
const SpanName = styled("span")({
  color: "#0F172A",
  marginTop: "0.25rem",
  fontSize: "0.875rem",
  fontWeight: 700,
  marginLeft: "0.3125rem",
});
const Span2 = styled("span")({
  fontSize: "0.875rem",
  fontWeight: 700,
  color: "#64748B",
  marginTop: "0.25rem"
});
const Span3= styled("span")({
cursor:"pointer",
});
const TableData = styled("span")(({theme})=>({
  color: "#000",
  fontSize: "0.75rem",
  textTransform:"capitalize",
  fontWeight: 400,
  [theme.breakpoints.down("xs")]:{
    fontSize: "12px",
  }
})
)

const TableData1 = styled("span")(({theme})=>({
  display: "flex",
  textTransform: "capitalize",
  alignItems: "center",
  color: "#000",
  fontSize: "0.875rem",
  fontWeight: 400,
  [theme.breakpoints.down("xs")]:{
    fontSize: "12px",
  }
})
)

const TableData2 = styled("span")({
  display: "flex",
  textTransform: "capitalize",
  alignItems: "center",
  color: "#000",
  fontSize: "0.75rem",
  fontWeight: 600,
});
const TableData3 = styled("span")({
  display: "flex",
  alignItems: "center",
  color: "#059669",
  fontSize: "0.75rem",
  fontWeight: 600,
  textTransform: "capitalize",
});
const TableDesign = styled(Table)({
  borderBottomLeftRadius: "0.5rem",
  borderBottomRightRadius: "2rem",
  overflow: "hidden",
  "& .ant-table-thead .ant-table-cell": {
    borderBottom: "1px solid #E2E8F0 !important",
    background: "#F1F5F9 !important",
  }
});
const CustomIcon = styled(ExpandMoreIcon)({
  color: 'black',
  fontSize: "1.25rem",
  marginTop: "0.125rem"
});
const SelectBox = styled(Select)({
  "& .MuiSelect-icon": {
    display:"none",
  },
  "& .MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-formControl": {
    width: "80px !important",
    padding: "0.3125rem 0.5rem !important",
    height: "1.6875rem !important",
  },
  "& .MuiOutlinedInput-input": {
    height: "1.6875rem !important",
    width: "80px !important",
    textAlign:"center",
    padding: "4px 8px 4px 8px !important",
    color: "black !important",
    fontWeight: 400,
    fontSize: "0.75rem !important",
    backgroundColor: "#FEF3C7 !important",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "red !important"
  },
});
const SelectBox1 = styled(Select)({
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "red !important"
  },
  "& .MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-formControl": {
    width: "5.9375rem !important",
    padding: "0.3125rem 0.5rem !important",
    height: "1.6875rem !important",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0.3125rem 0.5rem !important",
    fontSize: "0.75rem !important",
    width: "80px !important",
    textAlign:"center",
    height: "1.6875rem !important",
    color: "#334155 !important",
    backgroundColor: "#FF9595 !important",
  },
  "& .MuiSelect-icon": {
    display:"none",
  },
});
const SelectBox2 = styled(Select)({
  "& .MuiOutlinedInput-input": {
    width: "125px !important",
    height: "1.6875rem !important",
    color: "black !important",
    backgroundColor: "#FEF3C7 !important",
    padding: "0.3125rem 0.5rem !important",
    fontSize: "0.75rem !important",
    fontWeight: 400,
  },
  "& .MuiSelect-icon": {
    display:"none",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "red !important"
  },
  "& .MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-formControl": {
    padding: "0.3125rem 0.5rem !important",
    height: "1.6875rem !important",
  },
});
const Anchor = styled("a")({
  display: "flex",
  alignItems: "center",
});
const Heading1 = styled("h1")(({theme})=>({
  marginLeft: "0.1875rem",
  marginTop: "0.625rem",
  fontSize: "1rem",
  fontWeight: 700,
  color: "#830065",
  [theme.breakpoints.down("xs")]:{
    marginTop: "7px",
    marginLeft:"5px",
    fontSize: "14px",
    marginRight:"10px"
  }
})
)

const TopButton = styled("span")(({theme})=>({
  backgroundColor: "#830065",
  fontSize: "1rem",
  marginRight:"10px",
  fontWeight: 700,
  color: "#FFFFFF",
  padding: "0.375rem 0.625rem",
  width: "auto",
  height: "2rem",
  borderRadius: "0.5rem",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  whiteSpace: "nowrap",
  [theme.breakpoints.down("xs")]:{
    width: "200px",
    height: "2rem",
  fontSize: "12px",

  }
})
)

const AntDModal = styled(Modal)(({theme})=>({
  top: "12.5rem",
  height: "29.5rem !important",
  width: "27.5rem !important",
  padding: "28px 24px 15px 26px",
  [theme.breakpoints.down("xs")]:{
    width:"19rem !important",
    height:"200px !important",
    "& .ant-modal-content":{
      height:"23rem",
      top:"-5rem",
      padding: "14px 17px 7px 13px",
    }, 
  }
})
)
const TitleHeading = styled("h5")(({theme})=>({
      color: "#000000 !important",
      fontSize: "24px",
      fontWeight: 700,
  [theme.breakpoints.down("xs")]:{
      fontSize: "18px",
      fontWeight: 700,  
  }
})
)

const AntdCancelButton = styled(AntdButton)(({theme})=>({
  height: "50px",
  width: "120px" ,
  color: "#830065" ,
  fontWeight: 700 ,
  fontSize: "16px" ,
	background: "#ffff" ,
  border: "none",
  outline: "none",
  borderColor: "#ffff" ,
	marginRight: "16px",
  boxShadow: "none" ,
  "&.ant-btn-primary:hover" :{
    color:"#830065",
    backgroundColor:"white",
    borderColor:"white"
  },
  [theme.breakpoints.down("xs")]:{
	marginRight: "0px",
  "&.Button-root-79":{
    width:"100px !important"
  }

  }
})
)
const AntdAddButton = styled(AntdButton)(({theme})=>({
  width: "120px" ,
  fontWeight: 700 ,
  fontSize: "16px" ,
  boxShadow: "none" ,
  color: "white",
  border: "none",
  outline: "none",
  background: "#830065",
  borderColor: "#830065",
  borderRadius: "8px !important",
  [theme.breakpoints.down("xs")]:{
	marginRight: "10px",
  height: "40px !important",
  "&.Button-root-79":{
    width:"90px !important",
  }

  }
})
)
const CustomForm = styled(Form)(({theme})=>({
      padding: "0 24px 20px 24px",
      position: "relative" ,
      [theme.breakpoints.down("xs")]:{
        padding: "0px !important",

    }
})
)
const TitleContainer = styled("div")(({theme})=>({
  display: "flex",
  justifyContent:"space-between",
  width: "100%",
  height: "80px",
  margin: "0 !important",
  padding: "35px 24px 5px 40px",
[theme.breakpoints.down("xs")]:{
  height: "60px",
  padding: "25px 12px 5px 15px",
}
})
)

const AntDRow = styled("div")(({theme})=>({
  padding: "1.25rem 1.25rem 1.875rem 1.25rem",
  width: "100%",
  [theme.breakpoints.down("xs")]:{
    padding: "2px 12px 5px 15px",
  }
})
)

const AntDSelectBox = styled(AntdSelect)(({theme})=>({
  color: "#94A3B8 !important",
  fontSize: "1rem !important",
  textTransform: "capitalize",
  fontWeight: 400,
  position: "relative",
  [theme.breakpoints.down("xs")]:{
    "&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector":{
      padding:"5px !important"
    },
  }
})
)

const AntDFormItem = styled(Form.Item)(({theme})=>({
  "& .ant-form-item-label > label": {
    fontSize: "1rem !important",
    fontWeight: 700,
  },
})
)

const AntDFormItemContainer = styled(Form.Item)(({theme})=>({
  [theme.breakpoints.down("xs")]:{
    width:"100%",
    alignItems:"none !important"
  }
})
)

const webStyle = {
  card: {
    position: "relative",
    overflow: "hidden",
    padding: "12px",
    width: "50%",
    background: "linear-gradient(60deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.6))",
    borderRadius: "8px",
    color: " rgb(255, 255, 255)",
    boxShadow: " 5px 5px 15px rgb(43, 43, 43)",

  }

};
// Customizable Area End
