// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import './Settings.css';
import SettingController from '../../email-account-registration/src/EmailAccountRegistrationController.web';

import PageWrapper from '../../../components/src/Pagewrapper.web';

class TermsAndConditions extends SettingController {
	constructor(props: any) {
		super(props);
	}
    async componentDidMount(){
		this.getTermsAndConditions();
	}
	
	render(): React.ReactNode {
		
		return (
			<PageWrapper title = "Terms and conditions" hasBack>
				<>
					<div id="terms" className='setting-content'>
						{this.state.content_type == "terms_and_condition" ? <div id="tncDiv" dangerouslySetInnerHTML={{__html: this.state.content_description}}></div> : <></>}	
					</div>
				</>
			</PageWrapper>
		);
	}
}

export default TermsAndConditions;
// Customizable Area End