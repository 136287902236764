// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import './Settings.css';
import SettingController from '../../email-account-registration/src/EmailAccountRegistrationController.web';
import PageWrapper from '../../../components/src/Pagewrapper.web';

interface SettingControllerProps {
	someProp: string;
	anotherProp: number;
}
class Cookies extends SettingController {
	constructor(props: SettingControllerProps) {
		super(props);
	}
    async componentDidMount(){
		this.getAllCookiePolicy();
	}
	render(): React.ReactNode {
		return (
			<PageWrapper title = "Cookies Policy" hasBack>
				<div id="cookies" className='setting-content'>
					 <div id="c-div" dangerouslySetInnerHTML={{__html: this.state.content_descriptions}}></div>
				</div>
			</PageWrapper>
		);
	}
}

export default Cookies;
// Customizable Area End