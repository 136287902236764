// Customizable Area Start 
import React from 'react';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import { Input, Space, Table, TableColumnType, Row, Col } from 'antd';
import { noData, search } from "./assets";
import TaskController from "./TaskController.web";
import { Link } from "react-router-dom";
import moment from 'moment';

interface Task {
	id: string;
	full_name?: string;
	tasks: number;
  status: string;
  }

class TasksListWeb extends TaskController {
  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {
    this.getTasks();
  }

  columns: TableColumnType<Task>[] = [
    {
		title: (<span className="client-name">Client Name</span>),
		width: 280,
		ellipsis: true,
		dataIndex: 'full_name',
		key: 'full_name',
		render: (text: string | undefined) => {
			const getFirstWordWithCapital = (text: string | undefined) => {
        if (!text) return "No Name";  
        const words = text.split(" ");
        const firstWord = words[0];
        const capitalizedFirstWord = firstWord.charAt(0).toUpperCase() + firstWord.slice(1).toLowerCase();
        return capitalizedFirstWord + (words.length > 1 ? ' ' + words.slice(1).join(" ") : '');
      };
		  
			return (
			  <span className='name-text'>
				{getFirstWordWithCapital(text)}
			  </span>
			);
		  },
	  },
    {
      title: (<span className="client-name">Total tasks</span>),
      width: 280,
      ellipsis: true,
      dataIndex: 'tasks',
      key: 'tasks',
      render: (text: number, e: Task) => (
        <span className='name-text'>
          {e.tasks}
        </span>
      ),
    },
    {
      title: (<span className="client-name">Action</span>),
      dataIndex: '',
      width: 280,
      key: 'action',
      render: (text: any, event: Task) => (
        <span data-testid="tablecolumnID" id="view-task" className='view-text'>
          <Link onClick={this.onViewClick.bind(this,event.status)} to={`/task-details-view?id=${event.id}&name=${event.full_name?.split(" ")[0]?.replace(/^./, str => str.toUpperCase()) || "No Name"}&date=${moment(new Date()).format('yyyy-MM-DD')}`}>
            <p id="view-task">View</p>
          </Link>
        </span>
      ),
    },
  ];

  CustomEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <div style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "red", height: "20%" }} />
      <div
        style={{
          height: '50px',
          backgroundSize: 'contain',
          backgroundImage: `url(${noData})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          padding: "20px",
          marginTop: "30px",
          marginBottom: "10px"
        }}
      />
      <span style={{color: '#b3b1b0'}}>Results not found</span>
    </div>
  );
  onViewClick=(status:string)=>{
    localStorage.setItem("ClientStatus",status);
    window.scrollTo(0, 0);
}
  render(): React.ReactNode {
    return (
      <PageWrapper title="Task Management" hasBack>
        <div className='client-content'>
        <div id='case-id' className='search-content-main' style={{ width: '100%' }}>
          <Row justify="space-between" align="middle" style={{ width: '100%' }}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <p className='case-title' style={{ margin: 0 }}>ALL CLIENTS</p>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right', marginTop: '8px' }}>
                <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                  <Input
                    className='input-search'
                    name="search"
                    onChange={this.handle_Search}
                    placeholder="Search by Client name"
                    prefix={<img src={search} style={{ marginLeft: '6px', width: '18px' }} />}
                    style={{
                      width: '100%',
                      maxWidth: '360px',
                      color: '#64748B',
                      fontSize: '16px',
                      borderRadius: '28px',
                      height: '44px',
                      border: '1px solid #CBD5E1',
                      padding: '4px'
                    }}
                  />
                </Space>
              </Col>
          </Row>
        </div>
        <div data-test-id="client-list" style={{ marginTop: '20px', marginBottom: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Table
            className="custom-table"
            data-testid="table"
            columns={this.columns}
            bordered
            dataSource={this.getDataSource()}
            scroll={{
              y: "calc(100vh - 410px)"
            }}
            locale={{
              emptyText: this.CustomEmpty
            }}
          />
        </div>
        </div>
      </PageWrapper>
    );
  }
}

export default TasksListWeb;

// Customizable Area End