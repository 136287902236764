// Customizable Area Start
import React, { Component } from 'react';
import { Row, Col, Avatar} from 'antd';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import './MyContact.css';
import ContactController from './ContactController.web';
import { noData } from '../../catalogue/src/assets';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
class MyContacts extends ContactController {
	constructor(props: any) {
		super(props);
	}

    async componentDidMount(){
        this.getContactList();
    }
   
	render(): React.ReactNode {
		return (
            <PageWrapper title="My Contacts">
               <div className='contact-content'>
                {this.state.contactList.length > 0 ? (
                      <div style={{ width: "100%" }}>
                      <Row gutter={[30, 20]}>
                          {this.state.contactList.map((e: any, index: number) => (
                               <Col xs={24} sm={12} md={12} lg={12} xl={12} key={index}>
                               <div id="up-card" className="contact-card">
                                   <p className="role-type">{e.attributes?.manager_type.replace(/_/g, ' ').replace(/\b\w/g, (l: any) => l.toUpperCase())} Details</p>
                                   <div className='contact-elements'>
                                   <div
                                        id="img"
                                        style={{ marginRight: '20px' }}
                                        >
                                        <div
                                            style={{
                                                width: '70px',
                                                height: '70px',
                                                borderRadius: '50%',
                                                padding: '4px',
                                                boxSizing: 'border-box',
                                              }}
                                        >
                                            {e.attributes.photo ? (
                                            <img
                                                src={e.attributes.photo}
                                                alt="user"
                                                style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover' }}
                                            />
                                            ) : (
                                            <AccountCircleIcon style={{ fontSize: '62PX', color: 'grey' }} />
                                            )}
                                        </div>
                                        </div>
                                       <div className='margin-style'>
                                           <p style={{textTransform:"capitalize"}} className="contact-name">
                                           {e.attributes.case_handlers.full_name}
                                           </p>
                                           <p className="contact-email">{e.attributes?.case_handlers?.email}</p>
                                           <p className="contact-phone">{`+${e.attributes?.case_handlers?.full_phone_number.substring(0, 2)} ${e.attributes?.case_handlers?.full_phone_number.substring(2)}`}</p>
                                       </div>
                                   </div>
                               </div>
                           </Col>
                          )

                          )}
                         
                      </Row>
                  </div>
                ) : 
                <div style={{ padding: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%',marginTop: '160px'  }}>
                    <img src={noData} style={{ height: '88px', width: '87px', marginBottom: '10px' }} />
                    <p className="no-data-font">Results not found</p>
                </div>
                }
                  
                </div>
        </PageWrapper>
		);
	}
}

export default MyContacts;

// Customizable Area End