// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import './DefaultLayout.css';
import React from 'react';
import { Button, Col, Form, Input, Row } from "antd";
import CaseController from '../../blocks/dashboard/src/CaseController.web';
import { Redirect } from "react-router-dom";
import PageWrapper from './Pagewrapper.web';

class CreateCaseScreen extends CaseController {
    
	constructor(props: any) {
		super(props);
        this.formRef = React.createRef<FormInstance>();
        this.state = {
            errorMessages: {}
            
        };
	}
    
    hasErrors = () => {
        return Object.values(this.state.errorMessages).some(message => message);
    };
    removeError = ()=>{
        this.setState({caseRefError:null})
    }
	render(): React.ReactNode {
        if(this.state.redirectToDashboard){
			window.history.back();
		}
        const validateEmail = (_, value) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!value || !emailRegex.test(value)) {
              return Promise.reject('Please enter a valid email address!');
            } else {
              return Promise.resolve();
            }
          };
		return (
            <PageWrapper title = "Create Case" hasBack>
                <div className='create-case-content'>
                    <div className='case-form-div'>
                        <Form
                            ref={this.formRef}
                            layout="vertical"
                            initialValues={{}}
                            className="form"
                            style={{ padding: "20px" }}
                        >
                            <Row gutter={12}>
                                <Col xs={24}>
                                    <Form.Item
                                        label="Case Reference Number"
                                        name="unique_reference_number"
                                        className="bold-label"
                                        rules={[
                                            
                                            {
                                                validator: (_, value) => {
                                                    if (!value || value.trim() === '' || value.includes('  '))  {
                                                        return Promise.reject('Please enter case ref. number');
                                                    }
                                                    return Promise.resolve(); 
                                                }
                                            }
                                          ]}
                                    >
                                        <Input maxLength={20} placeholder='Enter case ref. number' onBlur={this.removeError} onChange={this.removeError} />
                                    </Form.Item>
                                    {this.state.caseRefError ?
                                     <p data-test-id="createCaseErrorMsg" className="case-error-msg2" style={{color: '#DC2626', textAlign: 'left', fontSize: '14px', fontWeight: '400'}}>{
                                        this.state.caseRefError}
                                        </p> : null}
                                </Col>
                                
                                    <p className='headline-3'>CLIENT INFORMATION</p>
                                
                                <Col xs={24}>
                                    <Form.Item
                                        label="First Name"
                                        name="first_name"
                                        className="bold-label"
                                        rules={[
                                            { required: true, message: '' },
                                            {
                                                validator: (_, value) => {
                                                    if (!value || value.trim() === '' || value.includes('  ')) {
                                                        return Promise.reject('Please enter first name');
                                                    }else if (!value || !/^[a-zA-Z\s]*$/.test(value)) {
                                                        return Promise.reject('Please enter valid first name');
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }
                                          ]}
                                    >
                                        <Input maxLength={20} placeholder='Enter first name' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item
                                        label="Last Name"
                                        name="last_name"
                                        className="bold-label"
                                        rules={[
                                            { required: true, message: '' },
                                            {
                                                validator: (_, value) => {
                                                    if (!value || value.trim() === '' || value.includes('  ')) {
                                                        return Promise.reject('Please enter last name');
                                                    }else if (!value || !/^[a-zA-Z\s]*$/.test(value)) {
                                                        return Promise.reject('Please enter valid last name');
                                                    }
                                                    return Promise.resolve();
                                                }
                                            }
                                          ]}
                                    >
                                        <Input maxLength={20} placeholder='Enter last name' />
                                    </Form.Item>
                                </Col>

                                <Col xs={24}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        className="bold-label"
                                        rules={[
                                            {
                                              validator: validateEmail,
                                            },
                                          ]}
                                    >
                                        <Input placeholder='Enter email' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item
                                        name="mobile_number"
                                        label="Mobile Number"
                                        className="bold-label"
                                        rules={[ {
                                            validator: (_, value) => {
                                                if (!value || value.trim() === '' || value.includes('  ')) {
                                                    return Promise.reject('Please enter mobile number');
                                                }
                                                return Promise.resolve();
                                            }
                                        }]}
                                    >
                                        <Input placeholder='Enter mobile number' />
                                    </Form.Item>
                                    {this.state.createCaseErrorMsg !== '' && this.state.createCaseErrorMsg !== 'I'?
                                     <p data-test-id="createCaseErrorMsg" className="case-error-msg2" style={{color: '#DC2626', textAlign: 'left', fontSize: '14px', fontWeight: '400'}}>{
                                        this.state.createCaseErrorMsg}
                                        </p> : null}
                                </Col>
                            
                                <Col xs={14}>
                                    <Form.Item>
                                    <a onClick={() => window.history.back()} style={{color: '#830065', fontSize: '14px', fontWeight: '700', marginRight: '20px', marginTop: '20px'}} >Cancel</a>
                                    <Button
                                        data-test-id="create-case"
                                        onClick={() => {
                                           let errors = this.formRef.current.getFieldsError(['first_name', 'last_name', 'unique_reference_number', 'email','mobile_number'])
                                           for(let i=0; i< errors.length; i++){
                                            if(errors[i].errors.length > 0) {
                                                return;
                                            }
                                           }
                                            this.onCreateCase(this.formRef.current.getFieldsValue())
                                        }}
                                        loading={this.state.loadingCreateCase}
                                        htmlType="submit"
                                        type="primary"
                                        style={{
                                            background: "#830065",
                                            borderColor: "#830065",
                                            borderRadius: "8px",
                                            marginBottom: "20px",
                                            height: "32px",
                                            width: '108px',
                                            color: "white",
                                            fontWeight: "bold",
                                            marginTop: '20px'
                                        }}
                                    >
                                        Create
                                    </Button>
                                    
                                    </Form.Item>
                                </Col>
                                
                            </Row>
                        </Form>
                    </div>
                </div>
		    </PageWrapper>
           
		);
	}
}

export default CreateCaseScreen;
// Customizable Area End