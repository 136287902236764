// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from "react";
import rehab_image from "../assets/rehab.png";
import otp_image from "../assets/otp.png";
import back from "../assets/back.png";
import { Col, Row, Button, Spin, Modal } from "antd";
import "./EmailAccountRegistration.css";
import { Link, Redirect } from "react-router-dom";

import EmailAccountRegistrationController from "./EmailAccountRegistrationController.web";
import TimerVerify from "./TimerVerify";

interface OTPControllerProps {
  someProp: string;
  anotherProp: number;
}

class OTP extends EmailAccountRegistrationController {
  constructor(props: OTPControllerProps) {
    super(props);
    this.handleKeyPress=this.handleKeyPress.bind(this)
    this.generateDeviceId();
  }
  async componentDidMount() {
		this.getDynamicTextAndImageForOtp();
    window.addEventListener('keypress', this.handleKeyPress);
	}
  
  handleKeyPress(event){
    if(event.key === "Enter"){      
        this.verifyUser()
    }
  }
  
  verifyUser=()=>{
    console.log("user handkeypress");
      localStorage.getItem("newDevice")=== "true" ? this.onClickVerifyDevice() : this.onClickVerify()
      }

  renderModalsForResendSucess() {
    return (
        <Modal
          className="cookies-modal"
          data-test-id="resend-modal"
          open={this.state.resendModel}
          maskClosable={false}
          onCancel={this.hideResendSucessModel}
          footer={
            <div style={{ display: "flex", justifyContent: "right", padding: '0 22px 22px 22px' }} >

              <Button
                style={{
                  height: "56px", width: "120px",
                  background: "#830065",borderColor: "#830065",
                  borderRadius: "8px",color: "white",
                  fontSize: '16px',fontWeight: '700'
                }}
                onClick={this.hideResendSucessModel} >Okay</Button>
            </div>
          }
        >
          <div style={{
            padding: '40px 25px 20px 25px', fontSize: '16px',
            fontWeight: '500', marginTop: '10px'
          }}>
            OTP sent successfully
          </div>

        </Modal>
    );
  }
  onClickResend = () => {
    if (this.state.resendFlag) {
       this.resendOTP(localStorage.getItem("phone"));
       this.updateResendFlag();
       this.state.digit1 = '';
       this.state.digit2 = '';
       this.state.digit3 = '';
       this.state.digit4 = '';
       this.state.digit5 = '';
    }
  };
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const { value } = event.target;
    const fieldIndex = parseInt(field.charAt(5));
    this.setState({ [field]: value });
    if (value && fieldIndex < 5) {
      const nextField = `digit${fieldIndex + 1}`;
      const nextInput = document.getElementById(nextField) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
  };


  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, field: string) => {
    if (event.key === 'Backspace') {
      const fieldIndex = parseInt(field.charAt(5));
      if (this.state[field] === '') {
        if (fieldIndex > 1) {
          const prevField = `digit${fieldIndex - 1}`;
          const prevInput = document.getElementById(prevField) as HTMLInputElement;
          if (prevInput) {
            prevInput.focus();
          }
        }
      } else {
        this.setState({ [field]: '' });
      }
    }
  };
  updateResendFlag = async () => {
    if (this.state.resendFlag) {
      this.setState({ minutes: 0, seconds: 59 });
    }
    this.setState({ resendFlag: !this.state.resendFlag });
  };

  decrementTimer = () => {
    this.setState({ seconds: this.state.seconds - 1 });
  };
  decrementMinute = () => {
    this.setState({ minutes: this.state.minutes - 1, seconds: 59 })
  }

  render(): React.ReactNode {
    const userType = localStorage.getItem('userType');
    const phone = this.state.phonenum;
        if (this.state.redirectToDashboard) {
            return (userType === 'client') ? <Redirect to='/client-dashboard' /> : <Redirect to='/dashboard' />;
        }
    return (
      <>
        <Row style={{ height: '100%' }}>
            <Col style={{ background: "rgba(251,241,249,255)" }} md={12} xs={24} sm={24}>
              <div className="centered-content-signup" id="dynamic-data">
                  <img style={{ width: "100%", maxWidth: "435px",
                          height: "auto",
                          marginTop: "100px",
                          marginBottom: '44px', 
                      }} 
                      alt="Rehab"
                      src={rehab_image}
                  />
                  <img alt="rehab" style={{ height: "auto", 
                          width: "100%",
                          maxWidth: "523px", 
                          marginBottom: '70px' 
                      }}
                      src={this.state.image_data}
                  />
                  <p style={{ margin: "auto", textAlign: "center",marginBottom: "16px", maxWidth: "423px" }} className="headline-1">
                      {this.state.title}
                  </p>
                  <p style={{ maxWidth: "422px", margin: "auto", textAlign: "center", marginBottom: "16px" }}  className="hi-there" >{this.state.description}</p>
              </div>
            </Col>
            <Col sm={24}  xs={24} md={12}>
             <div className="form-container">
                <div
                  style={{
                    marginBottom: "40px", marginTop: "287px",
                    display: "flex",
                    cursor:'pointer'
                  }}
                  onClick={() => history.back()}
                >
                </div>
              
              <p className="otp-headline-2">Two-Factor Authentication</p>
              <p className="bodytext-3">
                Enter the 5-digit code that we sent to your <br /> mobile{" "}
                {
                  this.state.phonenum &&
                <strong style={{ color: "#830065", fontWeight: "600" }}>
                  {`+${phone.substring(0, 2)} ${phone.substring(2)}`}
                </strong>
                }
              </p>
              <div className="otp-container">
                {Array.from({ length: 5 }, (_, i) => (
                  <input
                    key={i}
                    id={`digit${i + 1}`}
                    className={this.state.otpErrorMessage ? "otp-input-error" : "otp-input"}
                    type="text"
                    value={this.state[`digit${i + 1}`]}
                    onChange={(event) => this.handleInputChange(event, `digit${i + 1}`)}
                    onKeyDown={(event) => this.handleKeyDown(event, `digit${i + 1}`)}
                    maxLength="1"
                  />
                ))}
              </div>
        
              {this.state.otpErrorMessage && (
                <p
                  className="otp-error-msg"
                  style={{
                    color: "#DC2626",textAlign: "left",
                    marginTop: 0
                  }}
                  id="loginInErrorMsg"
                >
                  {this.state.otpErrorMessage}
                </p>
              )}
              <div style={{ marginTop: '20px' , width: "376px"}}>
                <TimerVerify
                  decrementMinute={this.decrementMinute}
                  decrementTimer={this.decrementTimer}
                  updateResendFlag={this.updateResendFlag}
                  resendFlag={this.state.resendFlag}
                  seconds={this.state.seconds}
                  minutes={this.state.minutes}
                />
              </div>

              <br />
              <Col xs={24}>
                {this.state.loadingOTP ? (
                  <div style={{marginTop:"-40%",marginLeft:"-12px",width:"100%",height:"20%", display:"fixed",position:"absolute", justifyContent:"center",alignItems:"center"}}>
                  <Spin id="otp-spin" />
                  </div>
                ) : (
                  <Button
                    data-testid="verify"
                    onClick={() => localStorage.getItem("newDevice")=== "true" ? this.onClickVerifyDevice() : this.onClickVerify()
                    }
                    type="primary"
                    style={{
                      background: this.getBackground(), borderColor: this.getBackground(),
                      borderRadius: "8px",marginBottom: "20px",
                      height: "56px",width: "376px",
                      color: "white",fontWeight: "bold",
                      marginTop: "6px",
                      fontSize:"16px",
                      cursor: this.getverifyCursor()
                    }}
                  >
                    Verify
                  </Button>
                )}
              </Col>
              <Col xs={24}>
                <Button
                  data-testid="resend"
                  htmlType="submit"
                  type="text"
                  onClick={() => {
                    this.onClickResend();
                    this.setState({ otpErrorMessage: null })
                  }}
                  style={{
                    marginBottom: "20px",height: "45px",
                    width: "378px", color: this.getColor(),
                    fontWeight:700, fontSize: "16px",
                    cursor:this.getCursor(),
                  }}
                >
                  Resend
                </Button>
              </Col>
            </div>
          </Col>
        </Row>
        {this.renderModalsForResendSucess()}
      </>
    );
  }
}

export default OTP;
// Customizable Area End