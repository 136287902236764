// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import { notification} from "antd";
import {Modal} from "antd";
import successIcon from "./success.png";
import './NavHeader.css';

export const SuccessToast = ( desc: string) => {
  notification.success({
    message: "",
    description:
      desc,
      placement: 'bottomRight'
  });
};
export const SuccessMessage = (desc: string) => {
  notification.success({
    message:"",
    description:
      desc,
      placement: 'bottom',
      icon: <img src={successIcon} style={{height: '24px', width: '24px'}} />,
      closeIcon: null,
      duration: 2
  });
};


export const FailedToast = (desc: string) => {
  notification.error({
    message: "",
    description:
      desc,
      placement: 'bottomRight',
      closeIcon: null,
  });
};
export const FailedToastCenter = (desc: string) => {
  notification.error({
    message: "",
    description:
      desc,
      placement: 'bottom',
      closeIcon: null,
      duration: 3,
      style: {
        inset: 'auto auto 65px 43%',
        transform: 'translateX(-65%)'
      }
  });
};
export const WarningToast = (title: string, desc: string) => {

  Modal.warning({
      title: title,
      content: desc,
    });
};

export const APIMessage = (title: string, desc: string) => {

  notification.error({
    message: title,
    description:
      desc,
      placement: 'bottomRight'
  });
};

interface IToastMessage {
	message: string;
	visible: boolean;
	dataTestId: string;
}

export const ToastMessage: React.FC<IToastMessage> = ({message}) => {
  return (
        <div style ={{width: "100%", display: "flex", justifyContent: "center"}}>
          <div className='toast-div'>
          <img className='check-img' src={successIcon} />
            {message}
          </div>
        </div>
  )
}

// Customizable Area End