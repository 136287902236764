import React, { createRef, RefObject } from 'react';
import './AppointmentStyles.css';
import AppointmentDashboardController from "./AppointmentDashboardController.web";
import { Col, Row } from 'antd';
import moment from 'moment';
import { address1, type1, location1, calender1, chevron_left, chevron_right } from './assets';
export default class CalendarScheduler extends AppointmentDashboardController {
  constructor(props: any) {
    super(props);
    this.firstOnlineAppointmentRef = createRef<HTMLDivElement>();
    this.firstIn_PersonAppointmentRef = createRef<HTMLDivElement>();

    
  }
  firstOnlineAppointmentRef: RefObject<HTMLDivElement>;
  firstIn_PersonAppointmentRef: RefObject<HTMLDivElement>;

  selectRef= ()=>{
    if(this.props.filterType === 'Online') {
      return this.firstOnlineAppointmentRef
    }
     else{ return this.firstIn_PersonAppointmentRef}
  }
  
  handleScrollToFirstAppointment = () => {
    const { selectedDayEvents, filterType } = this.props;
    if (selectedDayEvents && filterType) {
      const firstAppointmentIndex = selectedDayEvents.findIndex((evt: any) => evt.attributes.type_of_appointment.toLowerCase() === filterType.toLowerCase());
  
      if (firstAppointmentIndex !== -1) {
        const firstAppointmentRef = this.selectRef();
        if (firstAppointmentRef.current) {
          firstAppointmentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }
  async componentDidMount() {
      const section = document.querySelector('.timingsRow div');
      section?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      
  }
  async componentDidUpdate(prevProps: any) {
    if (
      prevProps.selectedDayEvents !== this.props.selectedDayEvents ||
      prevProps.filterType !== this.props.filterType
    ) {
      await new Promise((resolve) => setTimeout(resolve, 0));
      this.handleScrollToFirstAppointment();
    }
  }
  


  customToolbar = (date: any) => {
    const displayDate= date ? date : this.props.prevDate
    let today =  moment().startOf('day');
    let yesterday = today.clone().subtract(1, 'days').startOf('day');
    let tommorrow = today.clone().add(1, 'days').startOf('day');
    let todayTitle = moment(displayDate).isSame(today) ? "Today-" : "";
    let yesterdayTitle = moment(displayDate).isSame(yesterday) ? "Yesterday-" : "";
    let tommorrowTitle = moment(displayDate).isSame(tommorrow) ? "Tommorrow-" : "";
    let dateTitle = todayTitle + yesterdayTitle + tommorrowTitle;
    const titleScheduler = dateTitle + moment(displayDate, "DD MMM YYYY").format("dddd, MMM DD YYYY");
    return (
      <Row>
        <Col span={20}>
          <h5 className='title-scheduler'>{this.props.showDate ? this.props.showDate : titleScheduler}</h5>
        </Col>
        <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <img data-test-id="backwardDateId" style={{
             height: '25px',
             width: '25px' }} className="image" src={chevron_left} onClick={() => this.props.ChangeBackwardDate(displayDate)} />
          <img data-test-id="forwardDateId" 
          style={{
           height: '25px',
           width: '25px' }}
           className="image" src={chevron_right} onClick={() => this.props.setCalendarDate(displayDate)}  />
        </Col>
      </Row>
    );
  }

  render() {
      this.handleScrollToFirstAppointment()
    const timeArray = ["01:00", "02:00", "03:00", "04:00", "05:00", "06:00", '07:00', "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "24:00"]
    const selectedDate = this.props.calenderDate;
    const selectedDateEvents = this.props.events.filter((evt:any) => moment(evt.attributes.availability_date, "DD MM YYYY").startOf('day').isSame(selectedDate));
    const selectedDayEvents=this.props.filterType===""?selectedDateEvents:selectedDateEvents.filter((evt:any) =>{
      return evt.attributes.type_of_appointment.toLowerCase()===this.props.filterType.toLowerCase()});

    return (
      <>
        {this.customToolbar(this.props.calenderDate)}
        <div style={{ height: '600px',
         overflowY: "scroll" }}>
          <Row style={{ marginTop: "10px" }}>
            {timeArray.map((time, key) => {

              let aptRowIndex = key + "_" + time;
              return (
                <>
                  <Col className="time-row" span={3} style={{ height: `${this.getHeight(aptRowIndex)}` }}>
                    <div style={{ position: "absolute", top: "-8px" }}>
                      <h5 className="time-rowtext" >{time}</h5>
                    </div>
                  </Col>
                  <Col span={21} className="timingsRow" style={{ height: `${this.getHeight(aptRowIndex)}` }}>
                    {selectedDayEvents.length > 0 && 
                    selectedDayEvents?.map((evt:any, index:any) => {
                      const aptMinutes = (moment(evt.attributes.end_time, "h:mm A").diff(moment(evt.attributes.start_time, "h:mm A"))) / 60000;
                      const startTimePoint = parseInt(evt.attributes.start_time.split(" ")[0].split(":")[1]);
                      if (evt.attributes.start_time.split(" ")[0].split(":")[0] === time.split(":")[0])
                      {
                        const isFirstAppointment = index === 0;
                        const firstAppointmentRef = this.selectRef()
                        return (
                          <div key={index}  ref={this.getRefValue(isFirstAppointment,firstAppointmentRef)}>
                            <Row className="dualcol-test" style={{ height: aptMinutes + "px", marginTop: startTimePoint + "px", borderRadius: "6px" }}>
                              <div className="apt-evt-title text-event">{evt.attributes.title} </div>
                              <div data-testid="view-close-btn" className="view-close-btn text-event" style={{ fontSize: '12px', color: '#830065', fontWeight: 'bold' }} onClick={() => this.updateShowDetailsIndex(evt.attributes.id, aptRowIndex)} >{`${this.state.showDetailsIndex === evt.attributes.id ? "Close" : "View"}`}</div>
                            </Row>
                            <div className={`dualcol-event ${this.state.showDetailsIndex === evt.attributes.id ? "show-apt-details" : ""}`}>
                              <div className="event-container">
                                <h5 className="font-style" style={{ fontSize: '14px',
                                 color: '#0F172A',
                                  fontWeight: 'bold' }}>
                                    {evt.attributes.name}
                                    </h5>
                              </div>
                              <Row>
                                <Col span="11">
                                  <div className="border-right">
                                    <div className="col-container">
                                      <img style={{ height: '15px', width: '15px' }} src={calender1} />
                                      <h5 className="col-text" >{this.getConvertDate(evt.attributes.availability_date)} | {evt.attributes.start_time}-{evt.attributes.end_time}</h5>
                                    </div>
                                    <div className="col-container">
                                      <img style={{ height: '15px', width: '15px' }} src={type1} />
                                      <h5 className="col-text">{this.getTypeText(evt.attributes.type_of_appointment)}</h5>
                                    </div>
                                    <div className="col-container">
                                      <img style={{ height: '15px', width: '15px' }} src={location1} />
                                      <h5 className="col-text">{evt.attributes.location}</h5>
                                    </div>
                                    <div className="col-container">
                                      <img style={{ height: '15px', width: '15px' }} src={address1} />
                                      <h5 className="col-text">Added By:  {this.getAddedBy(evt.attributes.added_by)}</h5>
                                    </div>
                                  </div>
                                </Col>
                                <Col span="11" style={{ marginLeft: '10px' }}>
                                  <h5 className="col-text" style={{ fontWeight: "bold", color: '#0F172A' }}>Purpose</h5>
                                  <h5 className="col-text">{evt.attributes.purpose}</h5>
                                  <h5 className="col-text" style={{ fontWeight: "bold", color: '#0F172A' }}>Additional Information</h5>
                                  <h5 className="col-text">{evt.attributes.additional_information}</h5>
                                </Col>
                              </Row>
                            </div>
                          </div>


                        )
                      }
                    })}
                  </Col>
                </>
              )
            })}
          </Row>
          </div>
      </>
    )
  }
}