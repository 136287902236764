Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.appointmentApiContentType = 'application/json'

exports.appointmentAPiEndPoint = 'appointment_management/availabilities'

exports.addAppointmentAPiMethod = 'POST'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentListAPiMethod = 'GET'
exports.updateAppointmentAPiMethod = 'PUT'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.validationApiMethodType = "GET";
//get cases for controller
exports.getCasesApiEndPoint = "bx_block_custom_form/cases";
exports.addAppointmentApiEndPoint='bx_block_appointment_management/availabilities';
exports.getUpcomingAppointment='bx_block_appointment_management/availabilities/three_months_appointments';
exports.getDeleteAppointment="bx_block_appointment_management/availabilities/";
exports.clientProfileAPiEndPoint = 'bx_block_appointment_management/availabilities/client_details';
exports.getClientUpcomingAppointment='bx_block_appointment_management/availabilities/client_appointments';
exports.getSolicitorCase ='bx_block_appointment_management/availabilities/my_client_list';
exports.updateSolicitorCase ='bx_block_appointment_management/availabilities/';
exports.updateSolicitorCase2 ='/update_solicitor_cases_appointment';





// Customizable Area End
