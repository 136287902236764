// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import './GoalManagementStyle.css';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import GoalManagementController, { Props } from './GoalManagementController.web';

import { view_image_img} from '../../appointmentmanagement/src/assets'; 
import { targetgoal,trophyicon } from './assets';

import { NoDataDashboard} from './assets';
import { Divider, Row, Col, Modal, Button, Form, Input, Table, Select} from 'antd';

import MaterialSelect from '@material-ui/core/Select';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FormControl from '@material-ui/core/FormControl';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import {styled } from "@material-ui/core/styles";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import _ from 'lodash';
import { CaretDownOutlined } from '@ant-design/icons';

const dropdown = <ExpandMoreOutlinedIcon />;
class GoalManagementDashboardDetail extends GoalManagementController {
	formRef: any;
	constructor(props: any) {
		
		super(props);
		this.formRef = React.createRef<FormInstance>();
		this.getShortMediumGoal();
		
	}
	titleHeading=(userLoginData,clientId)=>{
		this.setState({ pageTitle: `${userLoginData === "client" ?"My Goals":"Goal Management"}`, hasBack: true , client_id: clientId});

	}

	async componentDidMount() {
			this.changeGoalFlag()
		    this.changeViewPort()
			const userLoginData=localStorage.getItem('userType')
			userLoginData && this.setState({loginType:userLoginData})
			const status = localStorage.getItem('longGoalStatus');
			this.setState({goalStatus:status})
			const token =  localStorage.getItem("token");
      	    const userid = localStorage.getItem("userGoalId");
			const clientId = localStorage.getItem("clientId");
            const parsedUserID = Number(userid);
			this.titleHeading(userLoginData,clientId)
			if(userLoginData === 'client' ) {
				this.getUserGoals(token, clientId);
			}
			else{
				this.getClientGoals(token, parsedUserID);
			}

	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.editGoalLoader !== this.state.editGoalLoader) {
		  this.changeGoalFlag();	  
		}
	  }
	 changeViewPort(){
		if(this.state.usertype === 'client' && this.state.longTermGoalView ){
			this.setState({longTermGoalView:true})
		}
	  }
	  justifyValue(){
		if(this.state.usertype !== "client"){
			return "center"
		}
		else{
			return "start"

		}
	}
	dropdownStyle = {
		border: '1px solid #CBD5E1 ',
		borderRadius: "10px",
		padding:"0px" ,
		marging:"0px",
		height:"auto",
		zIndex:99,
	  };

	columns = [
		{
			title: (<CustomGoalspan>Name</CustomGoalspan>),
			width:100,
			render: (event: DurationTerm ) => (
				<><span className='name-text'>
					{
					_?.upperFirst(event.data.attributes.name)
					}
				</span>
				{
				 event.data.attributes.client_status && event.data.attributes.client_status.trim().toLowerCase() === "completed" 
				 &&
				<img className="trophyicon" src={trophyicon}  />
				}
				</>
			),
		},
		{
			title: (<CustomGoalspan>Assigned by</CustomGoalspan>),
			width:130,
			render: (event: anDurationTermy) => (
				<span className='name-text2'>
					{event.data.attributes.created_by}
				</span>
			),
		},
	
		{
			title: (<CustomGoalspan>Assigned on</CustomGoalspan>),
			width:130,
			render: (event: DurationTerm) => (
				<span className='name-text2'>
					{this.changeDateFormate(event.data.attributes.created_at)}
				</span>
			),
		},
		{
			title: (<CustomGoalspan >Status</CustomGoalspan>),
			width:130,
			render: (event: DurationTerm) => (
				<span style={{textTransform:"capitalize"}}
				 className={`${event.data.attributes && event.data.attributes.client_status.trim().toLowerCase() === "completed" ?
				  "status-completed" 
				  : "name-text-goalStatus"}`}>
					{
					event.data.attributes.client_status ? 
					event.data.attributes.client_status :
					 "-"}
					{
					event.data.attributes.client_status.length <= 3 && "%"
					}
				</span>
			),
		},
		{
			title: (<CustomGoalspan>Action</CustomGoalspan>),
			width:80,
			dataIndex: '',
			key: (event: EditDurationTerm) => event.data.attributes.id,
			render: (event: EditDurationTerm) => 
			<div style={{display:"flex",alignItems:"center"}}>
			<a className='view-text' data-testid="goalreassign"
			style={{color:this.state.clientStatus === "closed" && "grey",cursor:"pointer"}}
			disabled={event.data.attributes.client_status?.trim().toLowerCase() !== "completed"} 
			onClick={this.reassignShortMediumGoal.bind(this,event)} >Reassign</a>
			{
				event.data.attributes.client_status?.trim().toLowerCase() !== "completed" && 
						<>
							<EditIcon data-testid="goaledit"
								style={{
									marginLeft: "40px", height: "22px", width: "22px", marginLeft: "18px",
									cursor: "pointer",
									color: this.state.clientStatus === "closed" ? "grey" : "#830065"
								}}
								onClick={this.showEditModel.bind(this, event)}
							></EditIcon>
							<DeleteOutlineIcon data-testid="goaldelete"
								style={{
									marginLeft: "40px", height: "22px", width: "22px", marginLeft: "18px",
									cursor: "pointer",
									color: this.state.clientStatus === "closed" ? "grey" : "#830065"
								}} onClick={this.showDeleteModel.bind(this, event)} />
						</>
			}
			</div>,
		},
	];

	longTermColumns = [
		{
			title: (<TableGoalHeading>Name</TableGoalHeading>),
			ellipsis: true,
			width:280,
			render: (event: DurationTerm ) => (
				<>
				<span className='longTerm-name-text'>
					{_?.upperFirst(event.name)}
				</span>
				{event.client_status && event.client_status.trim().toLowerCase() === "completed" &&
					<img className="trophyicon" src={trophyicon}  />
				}
				</>
			),
		},
		{
			title: (<TableGoalHeading >Assigned by</TableGoalHeading>),
			ellipsis: true,
			width:280,
			render: (event: anDurationTermy) => (
				<Assinedby className='name-text'>
					{event.created_by}
				</Assinedby>
			),
		},
	
		
		{
			title: (<TableGoalHeading  style={{marginLeft:"15px"}}>Status</TableGoalHeading>),
			ellipsis: true,
			width:280,
			render: (event: DurationTerm) => (
				event.goal_status === "completed"?
				<span 
				style={{
					textTransform:"capitalize"
				}}
				 className={`name-text ${event.goal_status === "completed" ?
				  "status-completed" : ""}`}>
					Completed
				</span>
				:
		
				 	<FormControl variant="outlined">
					 <MaterialSelect
						 data-testid="selectGoalStatus2"
						 native
						 value={event.goal_status}
						 onClick={this.changeLongTermGoal.bind(this,event.id)}
						 onChange={this.LongTermDropDown}
						 disabled={this.state.loginType === "client" || !this.state.isCompleted}
						 inputProps={{
							 name: 'goalStatus',
							 id: 'select-goal-status',
						   }}
						 
					 >
						 <option value={"pending"}>Pending</option>
						 <option value={"completed"}>Completed</option>
					 </MaterialSelect>
				 </FormControl>
			),
		},
		{
			title: (<TableGoalHeading  style={{display:"flex",justifyContent:this.justifyValue() }}>Action</TableGoalHeading>),
			dataIndex: '',
			width:220,
			key: (event: DurationTerm) => event.id,
			render: (event: DurationTerm) => 
			<div style={{display:"flex",justifyContent:this.state.loginType !=="client" && "center",alignItems:"center"}}>
			<a className='view-text' data-testid="goal-view" onClick={()=>this.viewClientGoals(event)} >View</a>
			{
				this.state.loginType==="client" ?
				null :
				<>

				<EditIcon data-testid="longtermgoaledit"
					 style={{marginLeft:"40px",  height: "22px",width: "22px",marginLeft: "18px",
					 cursor:"pointer",
					 color:this.state.clientStatus ==="closed" ? "grey":"#830065"
					 }}
					 onClick={this.showEditLongModel.bind(this,event)}
					  ></EditIcon>
				<DeleteOutlineIcon  data-testid="longtermgoaldelete"
				 style={{marginLeft:"40px",  height: "22px",width: "22px",marginLeft: "18px",
				 cursor:"pointer",
				 color:this.state.clientStatus ==="closed" ? "grey":"#830065"
				 }} onClick={this.showLongTermDeleteModel.bind(this,event)}/>
				</>
			}
			</div>,
		},
	];

	clientColumns = [
		{
			title: (<CustomGoalspan>Name</CustomGoalspan>),
			width:50,
			render: (event: DurationTerm ) => (
				<>
				<GoalName>
					{_?.upperFirst(event.data.attributes.name)}
				{
				  event.data.attributes.client_status && event.data.attributes.client_status.trim().toLowerCase() === "completed" &&
					<img className="trophyicon" src={trophyicon}  />
				}
				</GoalName>
				</>
			),
		},
	
		{
			title: (<CustomGoalspan >
				Assigned on
				</CustomGoalspan>),
			ellipsis: true,
			width:170,
			render: (event: DurationTerm) => (
				<span 
				className='name-text'>
					{
					this.changeDateFormate(event.data.attributes.created_at)
					}
				</span>
			),
		},
		{
			title: (<CustomGoalspan  style={{marginLeft:"5px"}}>Status</CustomGoalspan>),
			ellipsis: true,
			width:150,
			render: (event: DurationTerm) => (
				<span 
				style={{
					textTransform:"capitalize"
				}}
				 className={`name-text ${event.data.attributes.client_status && event.data.attributes.client_status.trim().toLowerCase() === "completed" ?
				  "status-completed" : ""}`}>
					{event.data.attributes.client_status ?
					 event.data.attributes.client_status  : "-"}
					 	{
					event.data.attributes.client_status.length <= 3 && "%"
					}
				</span>
			),
		},
		{
			title: (<CustomGoalspan >
				Action
				</CustomGoalspan>),
			dataIndex: '',
			width:100,
			key: (event: EditDurationTerm) => event.data.attributes.id,
			render: (event: EditDurationTerm) => 
			<>
			{
				event.data.attributes.client_status && event.data.attributes.client_status.trim().toLowerCase() !== "completed"
				 &&
				<a className='view-text'
				 data-testid="clientgoalstatus1"
				 style={{color:this.state.clientStatus === "closed" && "grey",cursor :"pointer"}}
				onClick={
					()=>this.showClientEditModel(event)
					}>Update Status
				</a>
			}
			</>
		},
	];
	getGoalName=()=>{
		let localdata=localStorage.getItem("longGoalName");
		if(localdata){
			return localdata
		}
	  }
	renderEditModals() {
		
		return (<CustomGoalModal2
				id="appointment-modal"
				title={<HeadingGoalContainer>
					<PopupTitleHeading >Edit Goal </PopupTitleHeading>

				</HeadingGoalContainer>}
				open={this.state.showEditModel}
				closable={false}
				footer={null}
				okButtonProps={{
					style: {
						display: "none",
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
			>
				<CustomGoalForm
					data-testid="Edit-save-data"
					ref={this.formRef}
					layout="vertical"				
					onFinish={()=>this.saveFormDetail(this.formRef)}
					fields={[
					{ name: ['type'], value: this.state.clientstatus } ]}
				>
					<Row gutter={12}>
						<div class="goalForm">
						<Col xs={24}>
							<Form.Item
								label="Title"
								className="bold-label"
								validateFirst={true}
								rules={[
									{
										required: true,
										message: "Please enter title"
									},
									{ min: 3 },
									{
										validator: this.customNameValidator,
									}
								]}
							>
								<Input maxLength={40}
								 	  className={`${this.state.changeFontColor ? "font-style" : "input-font-style"}`}
									   placeholder='Enter name of the goal' 
									   value={this.state.goalTitle}
									   onChange={this.titleValue.bind(this)}
									   onFocus={this.changeColor}
									   style={{borderColor:this.state.titleError && "red"}}
									   onBlur={this.changeColor}  />
									   {
										this.state.titleError ? <Error>{this.state.titleError}</Error>:null
									   }
							</Form.Item>
						</Col>
						<Col xs={24}>
							<Form.Item
								label="Status"
								className="bold-label"
								name="type"
								rules={[
									{
										required: true,
										message: "Please select Status"
									},
								]}
							>
								<div style={{height:"50px"}}>
								<Select
									id="select-type" className="apt-design font-style customFiled"
									placeholder="Select Status"
									value={this.state.selectedValue}
									dropdownStyle={this.dropdownStyle}
									onChange={this.selectedvalue}
									options={[
										{ value: '10', label: '10%' },{ value: '20', label: '20%' },
										{ value: '30', label: '30%' },{ value: '40', label: '40%' },
										{ value: '50', label: '50%' },{ value: '60', label: '60%' },
										{ value: '70', label: '70%' },
										{ value: '80', label: '80%' },
										{ value: '90', label: '90%' },{ value: 'completed', label: '100%' },
									
									]}
									suffixIcon={dropdown}
									getPopupContainer={this.getPopupContainer}
								/>
								</div>
							</Form.Item>
			  			</Col>
						</div>
						<div class="formfooter">

						<Col xs={24}>
							<CustomGoalFormItem>
								<GoalCancelButton
									data-testid="hide-model2"
									loading={this.state.isloading} type="primary"
									onClick={this.editCancelButton.bind(this,this.formRef)}
								>
									Cancel
								</GoalCancelButton>
								<GoalSubmitButton
									className="submit-heading"
									loading={this.state.editGoalLoader}
									htmlType="submit"
									type="primary"
								>
									Save
								</GoalSubmitButton>
							</CustomGoalFormItem>
						</Col>
						</div>
					</Row>
				</CustomGoalForm>
			</CustomGoalModal2>
		);
	}
	renderEditLongGoalModals() {
		return (
		<CustomGoalModal
				 id="appointment-modal"
				title={
				<HeadingGoalContainer >
					<PopupTitleHeading >
						Edit Goal
				    </PopupTitleHeading>
				</HeadingGoalContainer>}
				open={
					this.state.showEditLongModel
				}
				closable={false}
				footer={null}

				okButtonProps={
					{
					style: {display: "none"},
				}
			}
				cancelButtonProps={{style: {
						display: "none",
					},
				}
				
			}
			>
				<CustomGoalForm
					data-testid="Edit-LongTerm-Data"
					ref={
						this.formRef
					}
					layout="vertical"
					    onFinish={
							()=>this.saveLongFormDetail(this.formRef)
						}
						fields={[{name: ['title'], value: this.state.editlongGoal}]}
					
				>
					<Row gutter={12}>
						<GoalForm>
						<Col xs={24}>
							<Form.Item
								label="Title"
								name="title"
								className="bold-label"
								validateFirst
								rules={
									[
									{
										required: true,message: "Please enter title"
									},
									{ 
										min: 3 
									},
									{validator: this.customNameValidator,
									}
								]}
							>
								<Input maxLength={40}
								  className={`${this.state.changeFontColor ? "font-style" : "input-font-style"}`}
								  placeholder='Enter name of the goal' 
								  value={this.state.editlongGoal}
								  onFocus={this.changeColor}
								  onBlur={this.changeColor}
								  onChange={this.editLongGoalTitle}

								   />
							</Form.Item>
						</Col>
						
						</GoalForm>
						<div class="formfooter">
						<Col xs={24}>
							<CustomGoalFormItem>
								<GoalCancelButton
									loading={this.state.isloading} 
									type="primary"
									onClick={
										() => {this.cancelButton(this.formRef)}
									}
								>
									Cancel
								</GoalCancelButton>
								<GoalSubmitButton
									className="submit-heading"
									loading={
										this.state.isLoading
									}
									htmlType="submit"
									type="primary"
								
								>
									Save
								</GoalSubmitButton>
							</CustomGoalFormItem>
						</Col>
						</div>
					</Row>
				</CustomGoalForm>
			</CustomGoalModal>
		);
	}
	renderEditClientStatusModals() {
		return (<Modal
				className="appointment-modal" id="appointment-modal"
				title={<div class="header-container2" 
				style={{ padding: "28px 24px 15px 26px" }}>
					<h5 className="title-heading" >Goal Status </h5>
				</div>}
				open={this.state.showClientEditModal}
				closable={false}
				footer={null}
				okButtonProps={{
					style: {display: "none"},
				}}
				cancelButtonProps={{
					style: {display: "none"},
				}}
			>
				<Form
					data-testid="Edit-save-data"
					ref={this.formRef}
					layout="vertical"
					className="form"
					style={{
					 padding: "0 24px 20px 24px",
					 position: "relative" }}
					onFinish={
						()=>this.saveClientEditForm(this.formRef)
					}
				
					fields={[{name: ['title'], value: this.state.goalName}]}
				>
					<Row gutter={12}>
						<div class="goalForm">
							
						<Col xs={24}>
							<Form.Item
								label="Status"
								className="bold-label"
								rules={[
									{
										required: true,
										message: "Please select Status"
									},
								]}
							>
								<div>
								<Select
									id="select-type"
									className="font-style apt-design"
									value={this.state.clientSelectedValue}
									placeholder="Select goal completion %"
									onChange={this.clientSelectedValue}
									options={[
										{ value: '10', label: '10%' },
										{ value: '20', label: '20%' },
										{ value: '30', label: '30%' },
										{ value: '40', label: '40%' },
										{ value: '50', label: '50%' },
										{ value: '60', label: '60%' },
										{ value: '70', label: '70%' },
										{ value: '80', label: '80%' },
										{ value: '90', label: '90%' },
										{ value: 'completed', label: '100%' },


									]}
									suffixIcon={dropdown}
									getPopupContainer={this.getPopupContainer}
								/>
								</div>
							</Form.Item>

			  			</Col>
						</div>
						<div class="formfooter">
						<Col xs={24}>
							<Form.Item className='btnContainer' >
								<Button
									className="cancel-btn-style"
									data-testid="hide-model1"
									type="primary"
									onClick={() => {
										this.cancelButton()
									}}
							
								>
									Cancel
								</Button>
								<Button
									className="submit-heading"
									loading={this.state.isClientLoading}
									htmlType="submit"
									type="primary"
								
								>
									Save
								</Button>
							</Form.Item>
						</Col>
						</div>
					</Row>
				</Form>
			</Modal>
		);
	}
	renderModalsDelete() {
		return (
			<Modal
			  className="delete-modal"
			  id="delete-modal"
			  open={this.state.showDeleteModel}
			  onCancel={this.hideDeleteModel}
			  footer={null}
			>
			<>
			  <DeleteGoalHeading >
				{`Are you sure you want to delete "${this.state.goalName}" from goals ?`}
			  </DeleteGoalHeading>
			  <Divider className='divider' />
			  <div style={{ display: "flex", justifyContent: "right", padding: '0 22px 22px 22px' }} >
				  <Button
				 	data-testid="hide-app"
					style={{
					  height: "56px",
					  width: "120px",
					  background: "#ffff",
					  borderColor: "#ffff",
					  borderRadius: "8px",
					  color: "#830065",
					  fontSize: '16px',
					  fontWeight: '700'
					}}
					onClick={this.hideDeleteModel.bind()} >Cancel</Button>
				<Button
					data-testid="delete-app"
					loading={this.state.deletedGoal}
					style={{
					  height: "56px",
					  minWidth: "120px",
					  background: "#830065",
					  borderColor: "#830065",
					  borderRadius: "8px",
					  color: "white",
					  fontSize: '16px',
					  fontWeight: '700'
					}}
					onClick={()=>this.deleteGoal(this.state.goalID)} >Yes, Delete</Button>
				</div>
			</>
			</Modal>
		);
	}

	renderAddALongTermGoalModal() {
		
		return (
			<CustomGoalModal
				id="appointment-modal"
				title={<HeadingGoalContainer>
					<PopupTitleHeading >
						{this.handleCondition(this.state.goalType === "long_term", "Add Long term Goal", 
						this.handleCondition(this.state.goalType === "short_term", "Add Short term Goal", "Add Medium term Goal"))}
					</PopupTitleHeading>

				</HeadingGoalContainer>}
				open={this.state.showAddModel}
				closable={false}
				footer={null}
				okButtonProps={{
					style: {
						display: "none",
					},
				}}
				cancelButtonProps={{
					style: {
						display: "none",
					},
				}}
			>
				<CustomGoalForm
					data-testid="Longterm-save-data"
					ref={this.formRef}
					layout="vertical"
					className="form"
					style={{ padding: "0 24px 20px 24px", position: "relative" }}
					onFinish={() => this.saveLongtermData(this.formRef)}

				>
					<Row gutter={12}>
						<GoalForm>
						<Col xs={24}>
							<Form.Item
								label="Title"
								name="title"
								className="bold-label"
								validateFirst
								rules={[
									{
										required: true,
										message: "Please enter title"
									},
									{ 	min: 3,
										message: "'Title' must be at least 3 characters" },
									{
										validator: this.customNameValidator,
									}
								]}
							>
								<Input maxLength={40} className="input-Font-style" placeholder='Enter name of the goal' onChange={()=>this.setState({errorMsg:""})} />
							</Form.Item>
							{
								this.state.errorMsg ? <span style={{color:"red",marginTop:"1px"}}>{this.state.errorMsg}</span>:null
							}
						</Col>
						</GoalForm>
						<div class="formfooter">
						<Col xs={24}>
							<CustomGoalFormItem className='btnContainer' >
								<GoalCancelButton
									data-testid="hide-model"
									loading={this.state.loading}
									onClick={() => this.longTermCancelBtn(this.formRef)}
								
								>
									Cancel
								</GoalCancelButton>
								<GoalSubmitButton
									className="submit-heading"
									loading={this.state.loadingGoalData}
									htmlType="submit"
									type="primary"
								
								>
									Add
								</GoalSubmitButton>
							</CustomGoalFormItem>
						</Col>
						</div>
					</Row>
				</CustomGoalForm>
				

			</CustomGoalModal>
		);
	}

	CustomEmpty = () => (
		<div style={{ textAlign: 'center',}}>
		  <div
			style={{
			  height: '100px',
			  backgroundImage: `url(${targetgoal})`,
			  backgroundSize: 'contain',
			  backgroundRepeat: 'no-repeat',
			  backgroundPosition: 'center',
			  padding:"40px",
			  marginBottom:"10px"
			}}
		  />
		 <span style={{fontSize:"16px",fontWeight:400,color:"#475569"}} >No Short term goals added yet</span>
		</div>
	  );
	  

	  CustomMediumEmpty = () => (
		<div style={{ textAlign: 'center',}}>
		  <div
			style={{
			  height: '100px',
			  backgroundImage: `url(${targetgoal})`,
			  backgroundSize: 'contain',
			  backgroundRepeat: 'no-repeat',
			  backgroundPosition: 'center',
			  padding:"40px",
			  marginBottom:"10px"
			}}
		  />
		 <span style={{fontSize:"16px",fontWeight:400,color:"#475569"}} >No Medium term goals added yet</span>
		</div>
	  );

	  longTermTableView = ()=>{
		return(
			<div style={{marginTop:`${this.state.loginType === "client" ? "40px": "0px" }`}}>
					{
						this.state.loginType !== "client"  &&

						<div data-testid="headcontent" className="header-container">
						<>
						<div className="header">
						<Client>Client :</Client>
						<ClientName>{this.state.client_name}</ClientName>
						<ChangeButton data-testid="goback"  style={{ marginLeft: '15px',}}  onClick={this.goBack}>Change</ChangeButton>
						
						</div>
						{ 
							 Boolean(!this.state.getGoalData.length > 0 ) &&
							
							<CustomAction data-testid="showlongTermModelId"  onClick={this.showLongtermModel}>
							<AddCircleOutlineIcon style={{
											cursor: "pointer",
											color: this.state.clientStatus === "closed" ? "grey" :"#830065"
										}} />

							<AddShortMediumGoal style={{
									cursor:"pointer",
									color:this.state.clientStatus ==="closed" && "grey"
								}}>Add Long Term Goal</AddShortMediumGoal>
							</CustomAction>
						}
						
					
						</>
					</div>
					}
					{
					this.state.loginType !== "client"  &&
					<Divider type="horizontal" style={{ width: "100%", margin:"0 0 30px 0" }} />
					}

				{this.state.getGoalData && this.state.getGoalData.length > 0  ?
					<div style={{borderRadius:"8px 8px 32px 8px", backgroundColor:"#FFFFFF"}}>
					 		<div class="goalheading">
					 			<span className='goalNameHeading'>Goals</span>
					 			{
								this.state.loginType !== "client" &&
								<CustomAction data-testid="showLongTerm" onClick={this.showLongtermModel} >

										<AddCircleOutlineIcon style={{
											cursor: "pointer",
											color: this.state.clientStatus === "closed" ? "grey" :"#830065"
										}} />
								<AddShortMediumGoal style={{
									cursor:"pointer",
									color:this.state.clientStatus ==="closed" && "grey"
								}}>Add Long Term Goal</AddShortMediumGoal>
								</CustomAction>
								}
							</div>
					<TableDesign
						data-testid="table"
						columns={this.longTermColumns}
						bordered
						dataSource={this.state.getGoalData}
						loading={this.state.loadingData}
						scroll={{
							y: "calc(100vh - 410px)",
							x: 'max-content'
						}}
						locale={{
							emptyText: this.CustomEmpty
						  }}
						/>	
						</div>
							:
							<div data-testid="nodatacontent" class="search-content-main-goal">
							<div class="emptybox">
									<img class="emptyboximg" src={NoDataDashboard} />
									<p className='no-data-text'>No long term goals added yet</p>
							</div>
							</div>
						}
			</div>
		)
	  }

	  showClientGoalData = ()=>{
		return(
			<>
			<MainContainerGoal data-testid="headcontent">
						{
							this.state.loginType === "client" 
							?
							<div className='clintdropdown'>
							<div class="ClientGoalHeading" style={{marginRight:"15px",marginTop:"5px"}} >
								{this.state?.getGoalData[0]?.name}
							</div>

									<FormControl variant="outlined">
										<MaterialSelect
											data-testid="selectGoalStatus4"
											native
											disabled={this.state.loginType === "client"}
											value={this.state.goalStatus}
											inputProps={{
												name: 'goalStatus',
												id: 'select-goal-status',
											  }}
											
										>
											<option value={"pending"}>Pending</option>
											<option value={"completed"} disabled={!this.state.isCompleted} >Completed</option>
										</MaterialSelect>
									</FormControl>
				 </div>
							:
						<>
						<div className="header">
						<Client>Client :</Client>
						<ClientName>{this.state.client_name}</ClientName>
						<ChangeButton data-testid="goback"  onClick={this.goBack}>Change</ChangeButton>
						</div>
								<RightContainer>
									<GoalNameSpan>{this.getGoalName()}</GoalNameSpan>
								{
									this.state.goalStatus === "completed" ?
									<span 
									 className={`name-text ${this.state.goalStatus === "completed" ?
									  "status-completed" : ""}`}>
										Completed
									</span>
									:
									<FormControl variant="outlined">
										<MaterialSelect
											data-testid="selectGoalStatus4"
											native
											value={this.state.goalStatus}
											onClick={() => this.changeGoalFlag()}
											disabled={!this.state.isCompleted ||  this.state.clientStatus === "closed"}
											onChange={this.LongTermDropDown}
											inputProps={{
												name: 'goalStatus',
												id: 'select-goal-status',
											  }}
											
										>
											<option value={"pending"}>Pending</option>
											<option value={"completed"}>Completed</option>
										</MaterialSelect>
									</FormControl>


								}		
									
								</RightContainer>
						
						</>
							}
					</MainContainerGoal>
					
				{ this.state.loginType !== "client" &&
					<Divider type="horizontal" style={{ width: "100%", margin:"0 0 30px 0" }} />}
				<div class="Goaltable" >
						<div  style={{borderRadius:"8px 8px 32px 8px", backgroundColor:"#FFFFFF"}}>
							<div class="goalheading">
								<ShortTermHeading >Short Term Goals</ShortTermHeading>
								{
								this.state.loginType !== "client" &&
								<a data-testid="showShortTerm" class="longtermbtn" onClick={this.showShorttermModel} >
								<AddCircleOutlineIcon style={{
										cursor:"pointer",
										color: this.state.clientStatus === "closed" ? "grey" :"#830065"
									}} />
								<AddShortMediumGoal style={{
								cursor:"pointer",
								color:this.state.clientStatus ==="closed" && "grey"
							}}>Add Short Term Goal</AddShortMediumGoal>
								</a>
								}
							</div>
						<TableDesign
						data-testid="table"
						columns={this.state.loginType === "client" ?
						 this.clientColumns : this.columns}
						bordered
						dataSource={this.state.shortMediumGoal && this.state.shortMediumGoal.short_term_goals}
						loading={this.state.loadingData}
						scroll={{
							y: 300,
							x: 'max-content'
						}}
						locale={{
							emptyText: this.CustomEmpty
						  }}
						/>	
						</div>

						<div style={{borderRadius:"10px 10px 32px 10px", backgroundColor:"#FFFFFF",marginTop:"20px" }}>
							<div class="goalheading">
								<ShortTermHeading >Medium Term Goals</ShortTermHeading>
								{
									this.state.loginType !== "client" &&
								<a data-testid="showMediumTerm" class="longtermbtn" onClick={this.showMediumtermModel} >
							<AddCircleOutlineIcon style={{
										cursor: "pointer",
										color: this.state.clientStatus === "closed" ? "grey" :"#830065"
									}} />
								<AddShortMediumGoal style={{
								cursor:"pointer",
								color:this.state.clientStatus ==="closed" && "grey"
							}}>Add Medium Term Goal</AddShortMediumGoal>
								</a>
								}
							</div>
						<TableDesign
						data-testid="table"
						columns={this.state.loginType === "client" ? this.clientColumns : this.columns}
						bordered
						dataSource={this.state.shortMediumGoal && this.state.shortMediumGoal.medium_term_goals}
						loading={this.state.loadingData}
						scroll={{
							y: 300,
							x: 'max-content'
						}}
						
						locale={{
							emptyText: this.CustomMediumEmpty
						  }}
					/>	
						</div>
					</div>
					</>
		)
	  }
	

	render() {
		return (
			<>
				<PageWrapper title={this.state.pageTitle} hasBack={this.state.hasBack} type="Goal" function={this.changeState}>
					{
						this.state.longTermGoalView ? this.longTermTableView():
						this.showClientGoalData()
					}
				
				</PageWrapper>

				{this.renderAddALongTermGoalModal()}
				{this.renderEditModals()}
				{this.renderEditLongGoalModals()}
				{this.renderEditClientStatusModals()}
				{this.renderModalsDelete()}
			</>
		);
	}
}

export default GoalManagementDashboardDetail;
const CustomAction = styled("a")(({theme})=>({
	display: "flex",
    alignItems: "center",
	justifyContent:"center",
	[theme.breakpoints.down("xs")]:{
		gap:4
	}
})
)

const DeleteGoalHeading = styled("div")(({theme})=>({
	padding: '30px 30px 15px 30px', 
	fontSize: '24px',
	marginTop: '20px',fontWeight: '700',letterSpacing:"-0.5%",
	[theme.breakpoints.down("xs")]:{
		fontSize: '18px',
	}
})
)
const Assinedby = styled("a")(({theme})=>({
	textTransform: "capitalize",
	fontSize: "12px",
	fontWeight: 400,
	[theme.breakpoints.down("xs")]:{
		fontSize: "12px",
	}
})
)

const Client = styled("h3")(({theme})=>({
	color: "#000",
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
    fontWeight: 400,
	[theme.breakpoints.down("xs")]:{
		fontSize: "14px",
	}
})
)
const TableDesign = styled(Table)({
	borderBottomLeftRadius: "0.5rem",
	borderBottomRightRadius: "2rem",
	overflow: "hidden",
	"& .ant-table-thead .ant-table-cell": {
	  borderBottom: "1px solid #E2E8F0 !important",
	  background: "#F1F5F9 !important",
	}
  });
const ClientName = styled("h3")(({theme})=>({
	color: "#000",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
	fontSize:"14px",
	marginLeft: '8px',
    textTransform: "capitalize",
	[theme.breakpoints.down("xs")]:{
		fontSize: "14px",
	}
})
)
const ChangeButton = styled("h3")(({theme})=>({
	color: "#830065",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
	fontSize:"14px",
	marginLeft: '15px',
    texttransform: "capitalize",
	cursor:"pointer",
	[theme.breakpoints.down("xs")]:{
		fontSize: "14px",
	}
})
)

const TableGoalHeading = styled("a")(({theme})=>({
	fontSize: "14px",
    fontWeight: 700,
    color: "#000000",
    marginBottom: "10px",
	[theme.breakpoints.down("xs")]:{
		fontSize: "14px",
	}
})
)

const RightContainer = styled("dev")(({theme})=>({
	[theme.breakpoints.down("xs")]:{
		marginBottom: "10px",
	}
})
)
const Error = styled("span")(({theme})=>({
	color: "#DC2626" ,
	textAlign: "left" ,
	fontWeight: 400 ,
	fontSize: "12px" ,
	marginTop:"5px"
})
)

const CustomGoalModal = styled(Modal)(({theme})=>({
	minHeight: "348px",
	width: "440px",
	top: "150px",
	"& .ant-modal-content":{
		height: "fit-content",
	    width: "440px",
	},
	[theme.breakpoints.down("xs")]:{
		"& .ant-modal-content":{
			maxHeight: "258px",
			width: "95vw",
		   margin:"0px auto"
		},
	},
	[theme.breakpoints.down("md")]:{
		"& .ant-modal-content":{
		   margin:"0px auto"
		},
	}
})
)
const CustomGoalModal2 = styled(Modal)(({theme})=>({
	minHeight: "348px",
	height:"457px",
	width: "440px",
	"& .ant-modal-content":{
		minHeight: "457px",
	    width: "440px",
		margin:"0px auto"
	},
	[theme.breakpoints.down("xs")]:{
		"& .ant-modal-content":{
			minHeight: "300px",
			width: "90vw",
		},
	},
})
)

const HeadingGoalContainer = styled("div")(({theme})=>({
	display: "flex",
	justifyContent: "space-between",
	width: "100%",
	height: "80px",
	margin: 0,
	padding: "35px 24px 5px 40px",
	[theme.breakpoints.down("xs")]:{
		padding: "25px 14px 2px 25px",
		height: "60px",
	}
})
)

const ShortTermHeading = styled("span")(({theme})=>({
	color: "#000",
	fontSize: "18px",
	fontStyle: "normal",
	fontWeight: 700,
	[theme.breakpoints.down("xs")]:{
		fontSize: "16px",
	}
})
)

const GoalName = styled("div")(({theme})=>({
	textTransform: "capitalize",
	fontSize: "14px",
	width:"300px",
	fontWeight: 500,
	WebkitBoxOrient: "vertical",
	WebkitLineClamp: 1,
	overflow: "hidden",
	whiteSpace: "normal",
	[theme.breakpoints.down('xs')]:{
	  whiteSpace: "nowrap",
	  fontSize: "12px",

	},

})
)

const AddShortMediumGoal = styled("span")(({theme,clientStatus})=>({
	marginLeft: "3px",
	marginTop: "1px",
	fontSize: "16px",
	fontWeight: 700,
	cursor: "pointer",
	color: clientStatus == "closed" ? "grey" : "#830065",
	[theme.breakpoints.down("xs")]:{
		fontSize: "13px",
		marginLeft:"5px"
	}
})
)
const PopupTitleHeading = styled("div")(({theme})=>({
	color: "#000000 ",
	fontSize: "24px ",
	fontWeight: 700 ,
	[theme.breakpoints.down("xs")]:{
		fontSize: "20px ",

	}
})
)
const CustomGoalForm = styled(Form)(({theme})=>({
	padding: "0 24px 20px 24px",
	position: "relative",
  
	[theme.breakpoints.down("xs")]:{
	}
})
)

const MainContainerGoal = styled("div")(({theme})=>({
	display: "flex",
    marginTop: "24px",
    justifyContent: "space-between",
  
	[theme.breakpoints.down("xs")]:{
		justifyContent: "start",
		flexDirection:"column"
	}
})
)

const GoalNameSpan = styled("span")(({theme})=>({
  color: "#000",
  textTransform: "capitalize",
  fontSize: "18px",
  fontWeight: 700,
  marginRight:"15px",
	[theme.breakpoints.down("xs")]:{
		marginLeft:"17px",
		fontSize: "14px",
	}
})
)
const GoalForm = styled("div")(({theme})=>({
	padding:" 20px 20px 30px 20px",
	width: "100%",
  
	[theme.breakpoints.down("xs")]:{
		padding: "0px 2px 5px 2px",
		width: "100%",
	}
})
)

const GoalCancelButton = styled(Button)(({theme})=>({
	height: "50px" ,
	width: "120px ",
	color: "#830065" ,
	fontWeight: 700,
	fontSize: "16px",
	border: "none",
	outline: "none",
	borderColor: "#ffff",
	marginRight: "16px",
	boxShadow: "none",
	background: "#ffff",
	"&:hover": {
		background: "white !important",
		color:"#830065"
	  }, 
	  "&:active": {
		background: "white !important",
		color:"#830065"
	  },
	  "&:visited": {
		background: "white !important",
		color:"#830065"
	  },
	  "&:focus": {
		background: "white !important",
		color:"#830065"
	  },
	[theme.breakpoints.down("xs")]:{
	fontSize: "14px",
	marginRight: "5px",
	}
})
)

const GoalSubmitButton = styled(Button)(({theme})=>({
	height: "50px",
	width: "120px",
	color: "white",
	fontWeight: 700,
	fontSize: "16px",
	border: "none",
	outline: "none",
	boxShadow: "none",
	background: "#830065",
	borderColor: "#830065",
	borderRadius: "8px",
  
	[theme.breakpoints.down("xs")]:{
		fontSize: "14px ",
		height: "30px",
		width: "90px",

	}
})
)
const CustomGoalFormItem = styled(Form.Item)(({theme})=>({
	display: "flex",
	height: "100%",
	width: "100%",
	justifyContent: "flex-end",
	alignItems: "flex-end",
  
	[theme.breakpoints.down("xs")]:{
		alignItems: "start",
		height: "80%",
	}
})
)

const CustomGoalspan = styled(Form.Item)(({theme})=>({
	color:"#0F172A",
	fontFamily: 'Inter',
	fontSize: "14px",
	whiteSpace:"nowrap",
	fontWeight: 700,
	marginTop: "4px",
	[theme.breakpoints.down("xs")]:{
		fontSize: "14px",
	
	}
})
)
// Customizable Area End
