// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Tasks from '../../blocks/tasks/src/Tasks';
import TaskList from '../../blocks/tasks/src/TaskList';
import Task from '../../blocks/tasks/src/Task';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Appointments from '../../blocks/appointmentmanagement/src/Appointments';
import AddAppointment from '../../blocks/appointmentmanagement/src/AddAppointment';
import AssessmentTest from '../../blocks/assessmenttest/src/AssessmentTest';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Notifications from '../../blocks/notifications/src/Notifications';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Documentstorage2 from '../../blocks/documentstorage2/src/Documentstorage2';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Rolesandpermissions2 from '../../blocks/rolesandpermissions2/src/Rolesandpermissions2';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Settings2 from '../../blocks/settings2/src/Settings2';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Search from '../../blocks/search/src/Search';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLogin.web';
import Scoring from '../../blocks/scoring/src/Scoring';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import Gamescore2 from '../../blocks/gamescore2/src/Gamescore2';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import Uploadmedia3 from '../../blocks/uploadmedia3/src/Uploadmedia3';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import Projectnotes from '../../blocks/projectnotes/src/Projectnotes';
import Analytics from '../../blocks/analytics/src/Analytics';
import Multilevelapproval2 from '../../blocks/multilevelapproval2/src/Multilevelapproval2';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import OTP from '../../blocks/email-account-registration/src/OTP.web';
import DefaultLayout from '../../components/src/DefaultLayout.web';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import ResetPasswordWeb from '../../blocks/forgot-password/src/ResetPassword.web';

// Customizable Area Start
import 'antd/dist/antd.css';
import AppointmentDetailsView from '../../components/src/AppointmentDetailsView';

const routeMap = {
  Tasks: {
    component: Tasks,
    path: '/Tasks'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  Task: {
    component: Task,
    path: '/Task'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Appointments: {
    component: Appointments,
    path: '/Appointments'
  },
  AddAppointment: {
    component: AddAppointment,
    path: '/AddAppointment'
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Documentstorage2: {
    component: Documentstorage2,
    path: '/Documentstorage2'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: '/Rolesandpermissions2'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/login',
    exact: true
  },
  Scoring: {
    component: Scoring,
    path: '/Scoring'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions'
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail'
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers'
  },
  Gamescore2: {
    component: Gamescore2,
    path: '/Gamescore2'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    exact: true,
    path: '/signup'
  },
  OTP: {
    component: OTP,
    path: '/otp'
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: '/forget-password'
  },
  ResetPasswordWeb: {
    component: ResetPasswordWeb,
    path: '/reset-password'
  },
  AppointmentDetailsView: {
    component: AppointmentDetailsView,
    path: '/verify-email',
    exact: true
},

  Projectnotes: {
    component: Projectnotes,
    path: '/Projectnotes'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Multilevelapproval2: {
    component: Multilevelapproval2,
    path: '/Multilevelapproval2'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },

  //   Home: {
  // component:HomeScreen,
  //     path: '/',
  //     exact: true
  //   },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};
// Customizable Area End
const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      // <View style={{ height: '100vh', width: '100vw' }}>
         <div>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        {window.location.pathname != '/login' &&
          window.location.pathname != '/signup' &&
          window.location.pathname != '/forget-password' &&
          window.location.pathname != '/reset-password' &&
          window.location.pathname != '/verify-email' &&
          window.location.pathname != '/otp' && <DefaultLayout />}
        <ModalContainer />
        </div>
      // </View>
    );
  }
}

export default App;
