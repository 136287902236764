// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import './Settings.css';
import {Link} from 'react-router-dom';
import SettingController from '../../email-account-registration/src/EmailAccountRegistrationController.web';
import { Button, Modal } from 'antd';
import PageWrapper from '../../../components/src/Pagewrapper.web';

class SettingWeb extends SettingController {
	constructor(props: any) {
		super(props);
	}
	
	render(): React.ReactNode {
		return (
			<PageWrapper title = "Settings">
				<div className='setting-div'>
					<p id="link-color" className='terms-text'><Link to="/change-password">Change Password</Link></p>
                    <div className='p-divider'></div>
                    <p id="about" className='terms-text'> <Link to="/about">About</Link></p>
                    <div className='p-divider'/>
                    <p id="link-color" className='terms-text'><a id="logout" onClick={() => this.logoutPopupModal()}>Log out</a></p>
				</div>
                <Modal 
                    className='logout-modal'
					id="logout-modal"
					title={<p className='logout-title'>Are you sure you want to log out?</p>}
					open={this.state.logoutPopup}
					onCancel={this.logoutHandleCancel}
                    style={{ borderRadius: '10px' }} 
					footer={
						<div style={{display: "flex",justifyContent: "right", padding: '22px'}} >
							<Button 
                                id='logout-cancel'
                                type="text"
                                    style={{
                                        color: "#830065",
                                        fontSize: "14px",
                                        fontWeight: "700",
                                        height: "56px",
                                        width: "120px",
                                    }}
								onClick={this.logoutHandleCancel} >Cancel</Button>
								<Button
                                id="logout-success"
								style={{
									height: "56px",
									width: "126px",
									background: "#830065",
									borderColor: "#830065",
									borderRadius: "8px",
									color: "white",
									fontSize: '14px',
									fontWeight: '700'
								}}
								onClick={() => {
                                    localStorage.removeItem('token');
									localStorage.removeItem('userType');
									localStorage.removeItem('userID');
                                }} ><a  href='/login'>Yes, Log out</a></Button>
						</div>
					}
					>

                    </Modal>
			</PageWrapper>
		);
	}
}

export default SettingWeb;
// Customizable Area End