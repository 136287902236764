export const view_image_img = require("../assets/view_image_img.png");
export const delete_icon = require("../assets/delete.png");
export const edit_icon = require("../assets/edit.png");
export const calender = require("../assets/calender.png");
export const dropdown = require("../assets/dropdown.png");
export const cancel = require("../assets/cancel.png");
export const chevron_left = require("../assets/chevron_left.png");
export const chevron_right = require("../assets/chevron_right.png");
export const calender1 = require("../assets/calender1.png");
export const location1 = require("../assets/location1.png");
export const address1 = require("../assets/address1.png");
export const type1 = require("../assets/type1.png");
export const noData= require("../assets/no-data.png")