// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';

import GoalManagementController from "./GoalManagementController.web";
import PageWrapper from '../../../components/src/Pagewrapper.web';
import { noData } from './assets';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Space, Spin, Table } from 'antd';
import {styled } from "@material-ui/core/styles";

import _ from 'lodash';
class GoalManagementDashboard extends GoalManagementController {
    constructor(props: any) {
        super(props);
    }

    columns = [
		{
			title: (<span className="client-name">Client Name</span>),
			width: 280,
			ellipsis: true,
			render: (e: GetAllClients) => (
				<span className='name-text2'>
					{_?.upperFirst(e.full_name)}
				</span>
			),
		},
		{
			title: (<span className="client-name">Total Goals</span>),
			width: 280,
			ellipsis: true,
			render: (e: GetAllClients) => (
				<span className='name-text2'>
					{e.goals}
				</span>
			),
		},
		{
			title: (<span className="client-name">Action</span>),
			dataIndex: '',
			width: 280,
			key: (event: GetAllClients) => event.id,
			render: (event: GetAllClients) => <span data-testid="tablecolumnID" className='view-text'  onClick={this.getData.bind(this,event)}>View</span>,
		},
	];
    CustomEmpty = (loader) => (
		<div style={{ textAlign: 'center',}}>
			<div style={{width:"100%",display:"flex", justifyContent:"center",height:"20%"}}>
		  <Spin spinning={loader} />
			</div>
		  <div
			style={{
			  height: '50px',
			  backgroundSize: 'contain',
			  backgroundImage: `url(${noData})`,
			  backgroundRepeat: 'no-repeat',
			  backgroundPosition: 'center',
			  padding:"20px",
			  marginTop:"30px",
			  marginBottom:"10px"
			}}
		  />
		 <span >Result not found</span>
		</div>
	  );

    render(): React.ReactNode {

        return (
            <PageWrapper title="Goal Management">
                <div className='case-content'>
                    <div className='case-search-div'>
                        <p className='case-title'>ALL CLIENTS</p>
                        <Space>
                            <CustomSearchBar
                                className='input-search'
                                name="search"
                                onChange={(event)=> this.handle_Search(event.target.value)}
                                placeholder="Search by Client name"
                                prefix={<SearchOutlined style={{ color: '#64748B', marginLeft: '6px', fontSize: '18px' }} />}
                            />
                        </Space>
                    </div>
                    <Table
                        className="custom-table"
                        data-testid="table"
                        columns={this.columns}
                        bordered
                        dataSource={this.getDataSource()}
                        scroll={{
                            y: "calc(100vh - 410px)"
                        }}
                        locale={{
							emptyText: this.CustomEmpty(this.state.isLoading)
						  }}
                    />
                </div>
            </PageWrapper>
        );
    }
}

export default GoalManagementDashboard;

const CustomSearchBar = styled(Input)(({theme})=>({
	width: '360px',color: '#64748B',
	fontSize: '16px',borderRadius: '28px',
	height: '44px',  border: '1px solid #CBD5E1',
	padding: '4px',
	[theme.breakpoints.down("xs")]:{
		width:"200px"
	}
})
)

// Customizable Area End
