import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { createRef } from 'react';

// Customizable Area Start
import { AnswerSummitI, QuestionAnswerAttributesI, AnswerI, Question, ApiCallInterface } from '../IAssessment'
import { Message } from "../../../../framework/src/Message";
import { SuccessMessage, FailedToastCenter } from "../../../../components/src/Toast";
import { FlatList,   } from "react-native";
import StorageProvider from "../../../../framework/src/StorageProvider";
export interface Assessmentdata{
  key: string,
  name_of_problem: string,
  not_at_all: boolean,
  several_days: boolean,
  more_than_half_day: boolean,
  nearly_everyday: boolean,
  assessment_id:string | null ,
  value:number | null
}
export interface AssessmentTest5{
  key: string,
  name_of_problem: string,
  never: boolean,
  rarely:boolean,
  sometimes:boolean,
  often:boolean,
  almost_always:boolean,
  value:number | null
}
export interface AssessmentTest23{
  data:[
    {
      key: string,
      name_of_problem: string,
      never: boolean,
      rarely:boolean,
      sometimes:boolean,
      often:boolean,
      almost_always:boolean,
      value:number | null
      not_at_all: boolean,
      several_days: boolean,
      more_than_half_day: boolean,
      nearly_everyday: boolean,
    }
  ],
  zero_count: number,
  one_count: number,
  two_count: number,
  three_count: number,
  total_count: number,
  physical_subscale:number,
  cognitive_subscale:number,
  psychosocial_subscale:number,
}

export interface AssessmentTestMix{
      key: string,
      name_of_problem: string,
      never: boolean,
      rarely:boolean,
      sometimes:boolean,
      often:boolean,
      almost_always:boolean,
      value:number | null
      not_at_all: boolean,
      several_days: boolean,
      more_than_half_day: boolean,
      nearly_everyday: boolean,
}
export interface AssessmentPitsburg{
  key: string,
  name_of_problem: string,
  not_during_past_month: boolean,
  less_than_once_a_week: boolean, 
  value: number | null,
  one_or_twice_a_week: boolean,
  three_or_more_times_a_week: boolean,
  assessment_id:string | null ,

}
export interface AssessmentPitsburg2{
  key: string,
  name_of_problem: string,
  assessment_id:string | null ,
  no_problem_at_all: boolean,
  only_a_very_slight_problem: boolean,
  somewhat_of_a_problem: boolean,
  a_very_big_problem: boolean,
  value:number | null

}
export interface AssessmentPitsburg3{
  key: string,
  name_of_problem: string,
  assessment_id:string | null ,
  very_good: boolean,
  fairly_good: boolean,
  fairly_bad: boolean,
  very_bad: boolean,
  value:number | null

}
export interface AssessmentPitsburg4{
  key: string,
  name_of_problem: string,
  assessment_id:string | null ,
  no_bed_partner_or_roommate: boolean,
  partner_roommate_in_other_room: boolean,
  partner_in_same_room_but_not_same_bed: boolean,
  partner_in_same_bed: boolean,
  value:number | null

}
export interface Assessment1to4{
  key: number,
  name_of_problem: string,
  value:string | null,
  answer:string | null,
  assessment_id:string | null
}

// Customizable Area End

const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  questionsList?: Question[];
  currentQuestionIndex: number;
  clientStatus:string | null
  answerQuestions: AnswerSummitI | {};
  isError: boolean;
  functionalScore:number | null
  totalScore:number|null;
  isLoading: boolean;
  countClicks:number;
  isSuccess: boolean;
  acceptLoader:boolean;
  isAssessmentSubmitted: boolean;
  answerList: QuestionAnswerAttributesI[];
  letters: string[];
  seconds: number;
  checkClick:boolean;
  Q1ErrorMsg:boolean;
  Q3ErrorMsg:boolean;
  pitsModal:boolean;
  pitsburgData:AssessmentPitsburg[];
  pitsburgData2:AssessmentPitsburg2[];
  pitsburgData3:AssessmentPitsburg3[];
  pitsburgData4:AssessmentPitsburg4[];
  pitsburgData5:AssessmentPitsburg4[];
  pitsburgData1:Assessment1to4[];
  descriptiveValue: string | undefined;
  functionalPainLoader:boolean;
  pitsburghLoader:boolean;
  showAcceptRedo:boolean;
  functionalPainScore:number | null;
  showSubmitPopUp:boolean,
  score:string[],
  errorMsg:string | null,
  totalPitsScore:number | null,
  afterSubmit:boolean,
  assessmentStatus:string | null
  tableListData:Assessmentdata[],
  tableListDeepresionData:Assessmentdata[];
  userSubmitData:AssessmentTestMix[] | null;
  pitsTableFirst:any,
  pitsTableSecond:any,
  pitsTableThird:any,
  pitsTableFour:any,
  pitsTableFive:any,
  columnOne:string,
  columnTwo:string,
  columnThree:string,
  columnFour:string,
  resultData:AssessmentTest23 | null;
  submitTime:boolean;
  tableListMSIC:AssessmentTest5[];
  testName:string | null,
  showMFISPopUp:boolean
  mergedPitsburghData:any[];
  userType: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  
  // Customizable Area End
}



export default class AssessmentQuestionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  private readonly errorTitle = "Error";
  flatListRef = createRef<FlatList<Question>>();
  interval: number | undefined;
  apiGetAssessmentQuestions: string = "";
  apiSubmitAssessmentQuestions: string = "";
  apiSubmittedAssessmentTest: string = "";
  apiGetFunctionalPainCallId:string = "";
  apiGetAdminFunctionalPainCallId:string = "";
  apiAdminRedo:string = "";
  apiAcceptCallId:string = "";
  apiViewSubmitDataCal:string="";
  apiGAD7AnxietyCallId:string= ""
  apiPitsburghCallId:string="";
  apiCalculateScoreCallId:string = "";
  // Customizable Area End    




  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    this.state = {
      questionsList: [],
      currentQuestionIndex: 0,
      isError: false,
      clientStatus:localStorage.getItem("ClientStatus"),
      isLoading: true,
      acceptLoader:false,
      Q1ErrorMsg:false,
      Q3ErrorMsg:false,
      isSuccess: false,
      pitsburghLoader:false,
      countClicks:0,
      pitsModal:false,
      functionalScore:null,
      columnOne:"",
      columnTwo:"",
      columnThree:"",
      score: [
        "0",
       '0.25-2.75',
       '3',
       '4',
       '5',
       '6',
       '7',
       '8-9',
       "10",
     ],
   
      columnFour:"",
      pitsTableFirst:[],
      pitsTableSecond:[],
      pitsTableThird:[],
      pitsTableFour:[],
      pitsTableFive:[],
      totalPitsScore:null,
      afterSubmit:false,
      checkClick:false,
      showAcceptRedo:false,
      totalScore:null,
      answerList: [],
      assessmentStatus:localStorage.getItem("AssessmentStatus"),
      userSubmitData:null,
      resultData:null,
      submitTime:false,
      answerQuestions: {},
      seconds: 0,
      isAssessmentSubmitted: false,
      letters: 'abcdefghijklmnopqrstuvwxyz'.split(''),
      descriptiveValue:'',
      functionalPainLoader:false,
      functionalPainScore:0,
      mergedPitsburghData:[],
      showMFISPopUp:false,
      showSubmitPopUp:false,
      testName: localStorage.getItem("AssessmentName"),
      userType:localStorage.getItem("userType"),
      errorMsg:null,
      tableListData:[
        { key: '1', name_of_problem: 'Feeling nervous, anxious or on edge', not_at_all: false,
         several_days: false,value:null,
         more_than_half_day: false,
         nearly_everyday: false,assessment_id:localStorage.getItem("AssessmentId")
       },
        { key: '2',value:null,
          name_of_problem: 'Not being able to stop or control worrying', not_at_all: false,
          several_days: false,
          more_than_half_day: false, nearly_everyday: false,
          assessment_id:localStorage.getItem("AssessmentId")
         },
         { name_of_problem: 'Worrying too much about different things',
          key: '3',
          not_at_all: false,several_days: false,
          more_than_half_day: false,
          nearly_everyday: false,value:null,assessment_id:localStorage.getItem("AssessmentId")
         },
         { value:null,
         key: '4', name_of_problem: 'Trouble relaxing',
         not_at_all: false,several_days: false, more_than_half_day: false,
         nearly_everyday: false, assessment_id:localStorage.getItem("AssessmentId")
         },
         
         { 
          name_of_problem: 'Being so restless that it is hard to sit still',
          key: '5',value:null, not_at_all: false,
          several_days: false, more_than_half_day: false,nearly_everyday: false,assessment_id:localStorage.getItem("AssessmentId")
        },
         { key: '6',
         name_of_problem: 'Becoming easily annoyed or irritable',
          not_at_all: false,value:null,
          several_days: false,
          more_than_half_day: false,
          nearly_everyday: false,
          assessment_id:localStorage.getItem("AssessmentId")
         },
         { key: '7',value:null,
         name_of_problem: 'Feeling afraid as if something awful might happen',
          not_at_all: false,
          several_days: false,
          more_than_half_day: false,
          nearly_everyday: false,
          assessment_id:localStorage.getItem("AssessmentId")
         },
    
      ],
      tableListDeepresionData:[
        { key: '1', name_of_problem: 'Little interest or pleasure in doing things', not_at_all: false,
         more_than_half_day: false,
         several_days: false,nearly_everyday: false,value:null,assessment_id:localStorage.getItem("AssessmentId")
       },
        { key: '2',value:null,name_of_problem: 'Feeling down, depressed, or hopeless', not_at_all: false,
          several_days: false,
          assessment_id:localStorage.getItem("AssessmentId"),
          more_than_half_day: false, nearly_everyday: false,

         },
         { name_of_problem: 'Trouble falling or staying asleep, or sleeping too much',
          key: '3', not_at_all: false,several_days: false,more_than_half_day: false,
          nearly_everyday: false,value:null,assessment_id:localStorage.getItem("AssessmentId")
         },
         { 
         key: '4', name_of_problem: 'Feeling tired or having little energy',value:null, not_at_all: false,several_days: false, more_than_half_day: false,
         nearly_everyday: false, assessment_id:localStorage.getItem("AssessmentId")
         },
         
         { 
          name_of_problem: 'Poor appetite or overeating',
          key: '5', not_at_all: false,
          several_days: false,value:null,
          more_than_half_day: false,nearly_everyday: false,assessment_id:localStorage.getItem("AssessmentId")
        },
         { value:null,key: '6',
         name_of_problem: 'Feeling bad about yourself — or that you are a failure or have let yourself or your family down',
          assessment_id:localStorage.getItem("AssessmentId"),
          not_at_all: false, several_days: false,
          more_than_half_day: false,nearly_everyday: false,
         },
         { key: '7',
         name_of_problem: 'Trouble concentrating on things, such as reading the newspaper or watching television',
          not_at_all: false,
          more_than_half_day: false,
          nearly_everyday: false,
          several_days: false,value:null,
          assessment_id:localStorage.getItem("AssessmentId")
         },
         { 
          name_of_problem: 'Moving or speaking so slowly that other people could have noticed? Or the opposite — being so fidgety or restless that you have been moving .around a lot more than usual',
         not_at_all: false, several_days: false,
         more_than_half_day: false,
         nearly_everyday: false,
         key: '8',value:null,
         assessment_id:localStorage.getItem("AssessmentId")
        },
        { key: '9', not_at_all: false,
        name_of_problem: 'Thoughts that you would be better off dead or of hurting yourself in some way',value:null,
        several_days: false,more_than_half_day: false,nearly_everyday: false,
        assessment_id:localStorage.getItem("AssessmentId")
       },
    
      ],
      tableListMSIC:[
        {
          key: '1',
          name_of_problem: 'I have been less alert',
          never: false, rarely:false,value:null,
          sometimes:false,often:false,
          almost_always:false,
        },
        {
          key: '2', value:null,name_of_problem: 'I have had difficulty paying attention for long periods of time', never: false,
          rarely:false, sometimes:false, often:false,almost_always:false,
        },
        {  never: false, rarely:false,value:null,
           key: '3', name_of_problem: 'I have been unable to think clearly',
           sometimes:false, often:false, almost_always:false,
        },
        {
          key: '4', name_of_problem: 'I have been clumsy and uncoordinated', never: false,value:null,
          rarely:false,sometimes:false,often:false,
          almost_always:false,
        },
        { sometimes:false, often:false,
          key: '5', name_of_problem: 'I have been forgetful',
          never: false,rarely:false,
          almost_always:false,value:null,
        },
        { never: false,
          key: '6',value:null,
          name_of_problem: 'I have had to pace myself in my physical activities',
          rarely:false,  sometimes:false,often:false,
          almost_always:false,
        },
        {
          key: '7',almost_always:false,value:null,
          name_of_problem: 'I have been less motivated to do anything that requires physical effort',
          never: false, rarely:false,
          sometimes:false,often:false,
          
        },
        { never: false,
          key: '8',name_of_problem: 'I have been less motivated to participate in social activities',
          sometimes:false,
          rarely:false,value:null,
          often:false,
          almost_always:false,
        },
        {
          key: '9',value:null,
          never: false,
          often:false,
          name_of_problem: 'I have been limited in my ability to do things away from home',
          rarely:false,
          sometimes:false,
          almost_always:false,
        },
        { never: false,
          rarely:false,
          sometimes:false,value:null,
          often:false,
          key: '10',
          name_of_problem: 'I have trouble maintaining physical effort for long periods',
          almost_always:false,
        },
        {
          never: false,
          rarely:false,
          sometimes:false,
          key: '11',value:null,
          name_of_problem: 'I have had difficulty making decisions',
          often:false,
          almost_always:false,
        },
        {
          sometimes:false,value:null,
          often:false,
          key: '12',
          name_of_problem: 'I have been less motivated to do anything that requires thinking',
          never: false,
          rarely:false,
          almost_always:false,
        },
        {
          key: '13',
          name_of_problem: 'My muscles have felt weak',
          never: false, rarely:false,
          sometimes:false, often:false,
          almost_always:false,value:null,
        },
        {
          key: '14',
          name_of_problem: 'I have been physically uncomfortable',
          never: false,
          almost_always:false,value:null,
          rarely:false,
          sometimes:false,
          often:false,
        },
        {
          key: '15',
          often:false,
          name_of_problem: 'I have had trouble finishing tasks that require thinking',
          never: false,
          rarely:false, sometimes:false,
          almost_always:false,value:null,
        },
        {
          never: false,
          rarely:false,
          sometimes:false,value:null,
          often:false,
          almost_always:false,
          key: '16',
          name_of_problem: 'I have had difficulty organizing my thoughts when doing things at home or at work',
        },
        {
          key: '17',
          sometimes:false,value:null,
          often:false,
          never: false,
          rarely:false,
          almost_always:false,
          name_of_problem: 'I have been less able to complete tasks that require physical effort',
        },
        {
          sometimes:false,
          often:false,value:null,
          key: '18',
          name_of_problem: 'My thinking has been slowed down',
          never: false,
          rarely:false,
          almost_always:false,
        },
        {
          sometimes:false,
          key: '19',
          never: false,value:null,
          rarely:false,
          often:false,
          name_of_problem: 'I have had trouble concentrating',
          almost_always:false,
        },
        {
          never: false,
          rarely:false,
          key: '20',
          name_of_problem: 'I have limited my physical activities',
          sometimes:false,
          often:false,
          almost_always:false,value:null,
        },
        {
          key: '21',
          never: false,
          name_of_problem: 'I have needed to rest more often or for longer periods',
          rarely:false,sometimes:false,value:null,
          often:false, almost_always:false,
        },
      ],
      pitsburgData: [
        {
          key: '5', name_of_problem: 'a.  Cannot get to sleep within 30 minutes',
          not_during_past_month: false, less_than_once_a_week: false, value: null,
          one_or_twice_a_week: false,three_or_more_times_a_week: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
        {key: '6',name_of_problem: 'b.  Wake up in the middle of the night or early morning',
          not_during_past_month: false,less_than_once_a_week: false, value: null,one_or_twice_a_week: false,three_or_more_times_a_week: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          key: '7',name_of_problem: 'c.  Have to get up to use the bathroom',
          not_during_past_month: false,less_than_once_a_week: false, value: null,
          one_or_twice_a_week: false,three_or_more_times_a_week: false,assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          key: '8',
          three_or_more_times_a_week: false,assessment_id: localStorage.getItem("AssessmentId"),
          name_of_problem: 'd.  Cannot breathe comfortably',
          not_during_past_month: false,less_than_once_a_week: false, value: null,
          one_or_twice_a_week: false,
        },
        {
          key: '9',
          name_of_problem: 'e.  Cough or snore loudly',
          one_or_twice_a_week: false,
          three_or_more_times_a_week: false,
          not_during_past_month: false,
          less_than_once_a_week: false, value: null,  assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          key: '10',
          three_or_more_times_a_week: false,
          assessment_id: localStorage.getItem("AssessmentId"),
          name_of_problem: 'f.  Feel too cold',
          not_during_past_month: false,
          less_than_once_a_week: false, value: null,
          one_or_twice_a_week: false,
        },
        {
          key: '11',
          one_or_twice_a_week: false,three_or_more_times_a_week: false,
          name_of_problem: 'g.  Feel too hot',not_during_past_month: false,
          less_than_once_a_week: false, value: null,
          assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          key: '12',
          name_of_problem: 'h.  Have bad dreams', not_during_past_month: false,
          less_than_once_a_week: false, value: null,one_or_twice_a_week: false,three_or_more_times_a_week: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          key: '13',
          name_of_problem: 'i.  Have pain', not_during_past_month: false,
          less_than_once_a_week: false, value: null, one_or_twice_a_week: false,
          three_or_more_times_a_week: false, assessment_id: localStorage.getItem("AssessmentId")
        },  
    
        {
          key: '14',
          name_of_problem: 'During the past month, how often have you taken medicine to help you sleep (prescribed or “over the counter”)?',
          not_during_past_month: false,less_than_once_a_week: false, value: null, one_or_twice_a_week: false, three_or_more_times_a_week: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          not_during_past_month: false,
          key: '15',
          name_of_problem: 'During the past month, how often have you had trouble staying awake while driving, eating meals, or engaging in social activity?',
          less_than_once_a_week: false, value: null, one_or_twice_a_week: false,three_or_more_times_a_week: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },


      ],
      pitsburgData2: [
        {
          key: '16',
          name_of_problem: 'During the past month, how much of a problem has it been for you to keep up enough enthusiasm to get things done?',
          no_problem_at_all: false, value:null,
          only_a_very_slight_problem: false,
          somewhat_of_a_problem: false,
          a_very_big_problem: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
      ],
      pitsburgData3: [
        {
          key: '17',
          name_of_problem: 'During the past month, how would you rate your sleep quality overall?',
          very_good: false, value:null,
          fairly_good: false,
          fairly_bad: false,
          very_bad: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
      ],
      pitsburgData4: [
        {
          key: '18',
          name_of_problem: 'Do you have a bed partner or room mate?',
          no_bed_partner_or_roommate: false,
          partner_roommate_in_other_room: false, value:null,
          partner_in_same_room_but_not_same_bed: false,
          partner_in_same_bed: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
      ],

      pitsburgData5: [
        {
          key: '19',
          name_of_problem: 'If you have a room mate or bed partner, ask him/her how often in the past month you have had:',
          no_bed_partner_or_roommate: false,
          partner_roommate_in_other_room: false,
          partner_in_same_room_but_not_same_bed: false,
          partner_in_same_bed: false, value:null,
          assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          key: '20',
          name_of_problem: 'a. Loud snoring',
          no_bed_partner_or_roommate: false, value:null,
          partner_roommate_in_other_room: false,
          partner_in_same_room_but_not_same_bed: false,
          partner_in_same_bed: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          key: '21',
          name_of_problem: 'b. Long pauses between breaths while asleep',
          no_bed_partner_or_roommate: false,
          partner_roommate_in_other_room: false,
          partner_in_same_room_but_not_same_bed: false, value:null,
          partner_in_same_bed: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          key: '22',
          name_of_problem: 'c. Legs twitching or jerking while you sleep',
          no_bed_partner_or_roommate: false,
          partner_roommate_in_other_room: false, value:null,
          partner_in_same_room_but_not_same_bed: false,
          partner_in_same_bed: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          key: '23',
          name_of_problem: 'd. Episodes of disorientation or confusion during sleep',
          no_bed_partner_or_roommate: false,
          partner_roommate_in_other_room: false,
          partner_in_same_room_but_not_same_bed: false, value:null,
          partner_in_same_bed: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
        {
          key: '24',
          name_of_problem: 'e. Other restlessness while you sleep, please describe:',
          no_bed_partner_or_roommate: false,
          partner_roommate_in_other_room: false, value:null,
          partner_in_same_room_but_not_same_bed: false,
          partner_in_same_bed: false,
          assessment_id: localStorage.getItem("AssessmentId")
        },
      ],
      pitsburgData1:[
     {  name_of_problem: "During the past month, what time have you usually gone to bed at night?",
        answer: null,
        assessment_id: null,
        key: 1,
        value: null
      },
        {  name_of_problem: "During the past month, how long (in minutes) has it usually taken you to fall asleep each night?vv",
        answer: null,
        assessment_id: null,
        key: 2,
        value: null},
        {  name_of_problem: "During the past month, what time have you usually gotten up in the morning?",
        answer: null,
        assessment_id: null,
        key: 3,
        value: null
      },
        {  name_of_problem: "During the past month, how many hours of actual sleep did you get at night? (This may be different than the number of hours you spent in bed.)",
        answer: null,
        assessment_id: null,
        key: 4,
        value: null},

      ]
    };

     // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.handleApiStatement(apiRequestCallId, responseJson, errorReponse)

    }
    this.receiveFunctionalPain(message)
    this.receiveGAD7Anxiety(message)
    this.receiveAdminFunctionalScore(message)
    this.receiveAcceptScore(message)
    this.receiveRedoAssessment(message)
    this.receiveSubmittedData(message);
    this.receivePitsburgh(message)
    // Customizable Area End
  }


   // Customizable Area Start

  async componentDidMount() {
    this.getAsssessmentQuestionAPI()
    this.apiViewSubmitData()
  }

  async componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>,) {
    if (this.state.seconds !== prevState.seconds) {
      if (this.state.seconds === 0) return this.submitAsssessmentQuestionAPI()
      
    }
    if(this.state.submitTime !== prevState.submitTime){
      setTimeout(()=>{
        this.setState({submitTime:false})
      },4000)
    }
  }
 
  
handleSelectedAnswer = (type: { answer?: AnswerI, index?: number, questionId?: string | number, text?: string }): void => {
    const { answer, text } = type;
    const { questionsList, currentQuestionIndex } = this.state;
    let newList = questionsList ? questionsList : []
    const list = [...newList];

    const currentQuestion = list[currentQuestionIndex];

    let question_answer_attributes: QuestionAnswerAttributesI;
    const answerList = this.state.answerList;
    const answerIndex = answerList.findIndex((item: QuestionAnswerAttributesI) => item.question_id === currentQuestion?.attributes?.id);

    if (currentQuestion.attributes && currentQuestion.attributes?.question_type === 'descriptive') {
      if (!text || text.trim().length === 0) {
        list[currentQuestionIndex].description_text = undefined;
      } else {
        list[currentQuestionIndex].description_text = text;
        this.setState({
          descriptiveValue: text
        })
      }


      if (answerIndex === -1) {
        answerList.push({
          question_id: currentQuestion?.attributes.id,
        descriptive_answer: text,
        });
      } else {
        answerList[answerIndex].descriptive_answer = text;
      }
    } else if (currentQuestion?.attributes?.question_type === 'mcq' && answer) {
      list[currentQuestionIndex].SelectedAnswerIndex = Number(answer.id);
     

      if (answerIndex === -1) {
        answerList.push({
          question_id: currentQuestion.attributes.id,
          selected_option_id: answer.id,
        });
      } else {
        answerList[answerIndex].selected_option_id = answer.id;
      }
    }

    this.setState({ questionsList: list });
  }

  handleDescriptionValue(){
    const { currentQuestionIndex, questionsList } = this.state;
    const currentQuestion: Question | undefined = questionsList && questionsList[currentQuestionIndex]


    if(currentQuestion?.attributes?.question_type === 'descriptive'){
      if(currentQuestion.description_text === undefined){
        this.setState({
          descriptiveValue:''
        })
      }else {
        this.setState({
          descriptiveValue:currentQuestion.description_text
        })
      }
     
    }

    
  }


  // handle next question
  handleNext() {
    const { questionsList, currentQuestionIndex } = this.state;
    if (questionsList?.length !== currentQuestionIndex + 1) {
      this.setState({
        currentQuestionIndex: currentQuestionIndex + 1
      },
      () => {
        this.handleDescriptionValue()
      }
      
      )

    }
   
    
  }

  // handle next question
  handlePrevious() {
    
    const { currentQuestionIndex } = this.state;
    if (currentQuestionIndex !== 0)  {
      this.setState({
        currentQuestionIndex: currentQuestionIndex - 1
      }, ()=> {
        this.handleDescriptionValue()
      })
    }
    
   
  }
  handleAnswerTabBtn(index: number) {
    this.setState({
      currentQuestionIndex: index
    })
  }

  // get answered question
  getAnsweredQuestionConstant() {
    const { currentQuestionIndex, questionsList } = this.state;
    const prevDisable = currentQuestionIndex == 0
    const nextDiable = questionsList?.length === currentQuestionIndex + 1
    const totalQuestions = questionsList?.length

    let list = this.state.questionsList?.filter((objData: Question) => {
      return objData?.SelectedAnswerIndex !== undefined || objData?.description_text !== undefined
    })
    let AnsweredQuestionCount = list?.length
    return {
      prevDisable,
      nextDiable,
      totalQuestions,
      AnsweredQuestionCount
    }
  }

  // API  Respones


  handleApiStatement(apiRequestCallId: string, responseJson:{data:object, errors:object}, errorReponse: object) {
    if (responseJson && responseJson.data) {
      if (apiRequestCallId === this.apiGetAssessmentQuestions) {
        this.getAsssessmentQuestionSuccesscallBack(responseJson.data);
      } else if (apiRequestCallId === this.apiSubmitAssessmentQuestions) {
        this.submitAsssessmentQuestionSuccesscallBack();
      } else if (apiRequestCallId === this.apiSubmittedAssessmentTest) {
        this.submitAssessmentTestApiSuccesscallBack()
      }
    } else if (responseJson && responseJson.errors) {
      if (apiRequestCallId === this.apiGetAssessmentQuestions) {
        this.getAsssessmentQuestionFailureCallBack();
      } else if (apiRequestCallId === this.apiSubmitAssessmentQuestions) {
        this.submitAsssessmentQuestionFailureCallBack();
      } else if (apiRequestCallId === this.apiSubmittedAssessmentTest) {
        this.submitAssessmentTestApiFailureCallBack()
      }
    } else if (errorReponse) {
      this.setState({
        isLoading: false,
      })
    }
  }


  apiCallAssessmentQuestions = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = data;
    let token = await StorageProvider.get("USER_TOKEN");
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    // apiCallAssessmentQuestions

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiSubmitFunctionalPain = async () => {
    let token = localStorage.getItem("token")
    let AssessmentId= localStorage.getItem("AssessmentId")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    this.setState({functionalPainLoader:true});
    const body = null
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.scalerScoreApi1}${AssessmentId}${ configJSON.scalerScoreApi2}${this.state.score[this.state.functionalScore || 0]}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiGetFunctionalPainCallId = requestMessage.messageId;
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAssessementMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  apiGAD7Anxiety = async (data:Assessmentdata[] | AssessmentTest5[]) => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    this.setState({functionalPainLoader:true});
    const body = data
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.gadAnxietyapi}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiGAD7AnxietyCallId = requestMessage.messageId;
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.submitAssessementTestAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  receiveFunctionalPain= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetFunctionalPainCallId &&
			this.apiGetFunctionalPainCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({functionalPainLoader:false});
			if(responseJson.assessment){
        this.setState({showSubmitPopUp:false,showMFISPopUp:false,afterSubmit:true})
        SuccessMessage("Assessment Submitted");
			}
      else{
        this.setState({errorMsg:responseJson.message})
      }
			
		}
  }
  receiveGAD7Anxiety= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGAD7AnxietyCallId &&
			this.apiGAD7AnxietyCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({functionalPainLoader:false});
			if(responseJson.data){
        this.setState({showSubmitPopUp:false,showMFISPopUp:false,afterSubmit:true})
        this.calculateScore();
        SuccessMessage("Assessment Submitted");
			}
      else{
        this.setState({errorMsg:responseJson.message})
      }
			
		}
  }


  //  get questions for mobile devices



  //  Refetch Functions

  onReFetch() {
    this.setState({
      isLoading: true,
      isError: false,
    }, () => {
      this.getAsssessmentQuestionAPI()
    }

    )
  }

  

  // get assessment question for 

  getAsssessmentQuestionAPI = async () => {
    this.apiGetAssessmentQuestions = await this.apiCallAssessmentQuestions({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: `${configJSON.getAllAssessementTestUrl}/${this.getId()}`,
    });
  };

  getAsssessmentQuestionSuccesscallBack = async (data: Question) => {

    if (data.attributes?.is_completed === true) {
      this.setState({
        isLoading: false,
        isError: false,
      }, () => {
        this.props.navigation.navigate('AssessmentResult', {id: data?.id })
      })
    } else {
      this.setState({
        isLoading: false,
        isError: false,
        isSuccess: true,
        questionsList: data.attributes?.questions?.data, 
        seconds: data.attributes?.duration ? data.attributes?.duration * 60 : 0
      },
        () => {
          this.handleDescriptionValue()
          this.startTimer()
        }
      )
    }


  };

  getAsssessmentQuestionFailureCallBack = () => {
    this.setState({
      isLoading: false,
      isError: true,
      isSuccess: false
    })
  };

  // submit api request
  submitAsssessmentQuestionAPI = async () => {
    this.setState({
      isLoading: true,
      isSuccess: false,
    })

    let body = {
      assessment_id: this.getId(),
      question_answer_attributes: this.state.answerList
    }
    this.apiSubmitAssessmentQuestions = await this.apiCallAssessmentQuestions({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.submitAssessementTestAPiMethod,
      endPoint: configJSON.apiSubmitAssessmentQuestions,
      body,
    });
  };

  getId() {
    return this.props.navigation.getParam('id');
  }


  submitAsssessmentQuestionSuccesscallBack = async () => {
    this.setState({
      isLoading: false,
      isError: false,
      isSuccess: true,

    }, () => {
      this.submitAssessmentApi()
    })
  };

  submitAsssessmentQuestionFailureCallBack = () => {
    this.showAlert('Error', 'something wrong please try again ')
    this.setState({
      isLoading: false,
      isSuccess: true
    })

  };


  submitAssessmentApi = async () => {
    this.apiSubmittedAssessmentTest = await this.apiCallAssessmentQuestions({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.submitAssessementTestAPiMethod,
      endPoint: `${configJSON.submitAssessementTestUrl}${this.getId()}`,
    });
  };


  submitAssessmentTestApiSuccesscallBack = async () => {
    this.setState({
      isAssessmentSubmitted: true
    })
  };

  viewResult() {
    this.setState({
      isAssessmentSubmitted: false
    }, () => {
      this.props.navigation.navigate('AssessmentResult', { id: this.getId() })
    })
  }

  submitAssessmentTestApiFailureCallBack = async () => {
    this.showAlert('Error', 'something wrong please try again ')
    this.setState({
      isLoading: false,
    })
  };
  startTimer() {
    this.interval = setInterval(() => {
      this.setState(prevState => ({ seconds: prevState.seconds - 1 }));
    }, 1000) as unknown as number
  }
  submitFunctinalPain= ()=>{    
    if(this.state.testName=="Functional-Pain"){
      this.apiSubmitFunctionalPain()
    }
    else {
     this.submitGAD7Anxiety();
    }
  }
    canSubmitGAD7Data(data:any) {
    for (const item of data) {
      const { not_at_all, several_days, more_than_half_day, nearly_everyday } = item;
      if (!not_at_all && !several_days && !more_than_half_day && !nearly_everyday) {
        return false;
      }
    }
    return true; 
  }
  openSubmitPOPupBox=(isSubmittable:any)=>{

    if(isSubmittable && this.state.testName === "GAD-7-Anxiety" ){
      this.setState({showSubmitPopUp:true})
     }
     else if(isSubmittable && this.state.testName === "PHQ-9-Depression"){
      this.setState({showSubmitPopUp:true})
     }
     else if(this.state.testName === "Functional-Pain"){
      this.setState({showSubmitPopUp:true})
     }
     else{
      FailedToastCenter("Please answer to all questions")
     }
  }
  
  openSubmitPopUp=()=>{
    if(this.state.clientStatus !== "closed"){
      this.setState({submitTime:true})
    if(this.state.resultData && this.state.resultData.data.length > 0){
      FailedToastCenter("This assessment is already submitted")
    }
    else{
      let isSubmittable;
      if(this.state.testName === "GAD-7-Anxiety" ){
        isSubmittable = this.canSubmitGAD7Data(this.state.tableListData);
      }
      if(this.state.testName === "PHQ-9-Depression" ){
        isSubmittable = this.canSubmitGAD7Data(this.state.tableListDeepresionData);
      }
      this.openSubmitPOPupBox(isSubmittable)
      }
      }
  }
  canSubmitPitsData(data:any) {
    for (const item of data) {
      const { answer, not_during_past_month, less_than_once_a_week, one_or_twice_a_week,three_or_more_times_a_week,
        very_good,fairly_good,fairly_bad,very_bad,no_bed_partner_or_roommate,partner_roommate_in_other_room,
        partner_in_same_room_but_not_same_bed,partner_in_same_bed,no_problem_at_all,only_a_very_slight_problem,somewhat_of_a_problem,
        a_very_big_problem
      } = item;
      if ( answer === null && !not_during_past_month && !less_than_once_a_week && !one_or_twice_a_week &&
         !three_or_more_times_a_week && !very_good && !fairly_good && !fairly_bad && !very_bad &&
        !no_bed_partner_or_roommate && !partner_roommate_in_other_room && !partner_in_same_room_but_not_same_bed &&
        !partner_in_same_bed && !no_problem_at_all && !only_a_very_slight_problem && !somewhat_of_a_problem && !a_very_big_problem) {
        return false;
      }
    }
    return true; 
  }
  openMainPitsPOPup = (isSubmittable:any)=>{
    if(isSubmittable && this.state.testName === "Pittsburgh-Sleep-Quality-Index" ){
      this.setState({pitsModal:true})
    }
    else{
      FailedToastCenter("Please answer to all questions")
    }
  }
  openSubmitPitsPopUp=()=>{
    if(this.state.clientStatus !== "closed"){
   
      if(this.state.resultData && this.state.resultData.data.length > 0){
        FailedToastCenter("This assessment is already submitted")
      }
      else{
        const newArray=[...this.state.pitsburgData1,...this.state.pitsburgData,...this.state.pitsburgData2
          ,...this.state.pitsburgData3,...this.state.pitsburgData4,...this.state.pitsburgData5]
          let isSubmittable;
        if(this.state.testName === "Pittsburgh-Sleep-Quality-Index" ){
            isSubmittable = this.canSubmitPitsData(newArray);
          }
          this.openMainPitsPOPup(isSubmittable)
      }
      }
  }
  canSubmitMFISData(data:any) {
    for (const item of data) {
      const { never, rarely, sometimes, often,almost_always } = item;
      if (!never && !rarely && !sometimes && !often && !almost_always) {
        return false;
      }
    }
    return true; 
  }
  openMFISPopUp=()=>{ 
    if(this.state.clientStatus !== "closed"){

      if(this.state.resultData && this.state.resultData.data.length > 0){
        FailedToastCenter("This assessment is already submitted")
      }
      else{
        let isSubmittable;
        if(this.state.testName === "Modified-Fatigue-Impact-Scale" ){
          isSubmittable = this.canSubmitMFISData(this.state.tableListMSIC);
        }
        if(isSubmittable && this.state.testName === "Modified-Fatigue-Impact-Scale" ){
          this.setState({showMFISPopUp:true})
        }
        else{
          FailedToastCenter("Please answer to all questions")
        }
      }}
    }
  hideSubmitModal= ()=>{
    this.setState({showSubmitPopUp:false,showMFISPopUp:false,showAcceptRedo:false,pitsModal:false,afterSubmit:false})

  }
  navigateToDetail = async()=>{
    const goToDetails = new Message(getName(MessageEnum.NavigationMessage));
      goToDetails.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentTestDetail");
  
      goToDetails.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      goToDetails.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  
      this.send(goToDetails);
  
    }
    getSelectedvalue = (key:any,name:string,value:number)=>{
      if(this.state.testName === "GAD-7-Anxiety" && this.state.userType ==="client"){
        this.setState({tableListData:this.state.tableListData.map((tableData)=>{
          if(tableData.key === key){
            return {
              key: tableData.key,
              name_of_problem: tableData.name_of_problem,
              not_at_all: name === "not_at_all",
              several_days: name === "several_days",
              more_than_half_day: name=== "more_than_half_day",
              nearly_everyday : name === "nearly_everyday",
              value:value,
              assessment_id:localStorage.getItem("AssessmentId")
              }
          }
          return tableData
        }),countClicks:this.state.countClicks +1})
      }
      else if(this.state.testName === "PHQ-9-Depression" && this.state.userType ==="client"){
        this.setState({tableListDeepresionData:this.state.tableListDeepresionData.map((tableData)=>{
          if(tableData.key === key){
            return {
              key: tableData.key,
              name_of_problem: tableData.name_of_problem,
              not_at_all: name === "not_at_all",
              several_days: name === "several_days",
              more_than_half_day: name=== "more_than_half_day",
              nearly_everyday : name === "nearly_everyday",
              value:value,
              assessment_id:localStorage.getItem("AssessmentId")
              }
          }
          return tableData
        }),countClicks:this.state.countClicks +1})
      }
      else if(this.state.testName === "Modified-Fatigue-Impact-Scale" && this.state.userType ==="client"){
        this.setState({tableListMSIC:this.state.tableListMSIC.map((tableData)=>{
          if(tableData.key === key){
            return {
              key: tableData.key,
              name_of_problem: tableData.name_of_problem,
              never: name === "never",
              rarely: name === "rarely",
              sometimes: name=== "sometimes",
              often : name === "often",
              almost_always : name === "almost_always",
              value:value,
              assessment_id:localStorage.getItem("AssessmentId")
              }
          }
          return tableData
        }),countClicks:this.state.countClicks +1})
      }
    }
    handleSelect=(key:any,name:string,value:number)=>{
      if(this.state.clientStatus !== "closed"){
        this.getSelectedvalue(key,name,value)
    }
  }
    changePitsburgData=(tableData:AssessmentPitsburg,key:string,name:string,value:number)=>{
      if(tableData.key === key && this.state.userType ==="client"){
        return {
          key: tableData.key,
          name_of_problem: tableData.name_of_problem,
          not_during_past_month: name==="not_during_past_month",
          less_than_once_a_week: name=== "less_than_once_a_week",
          one_or_twice_a_week: name==="one_or_twice_a_week",
          three_or_more_times_a_week: name=== "three_or_more_times_a_week",
          value:value,
          assessment_id:localStorage.getItem("AssessmentId")
          }
      }
      return tableData
    }
    changePitsburgData2=(tableData:AssessmentPitsburg2,key:string,name:string,value:number)=>{
      if(tableData.key === key && this.state.userType ==="client"){
        return {
          key: tableData.key,
          name_of_problem: tableData.name_of_problem,
          no_problem_at_all: name==="no_problem_at_all",
          only_a_very_slight_problem:name==="only_a_very_slight_problem",
          somewhat_of_a_problem: name==="somewhat_of_a_problem",
          a_very_big_problem:name==="a_very_big_problem",
          value:value,
          assessment_id:localStorage.getItem("AssessmentId")
          }
      }
      return tableData
    }
    changePitsburgData3=(tableData:AssessmentPitsburg3,key:string,name:string,value:number)=>{
      if(tableData.key === key && this.state.userType ==="client"){
        return {
          key: tableData.key,
          name_of_problem: tableData.name_of_problem,
          very_good: name ==="very_good",
          fairly_good: name === "fairly_good",
          fairly_bad: name ==="fairly_bad",
          very_bad: name ==="very_bad",
          value:value,
          assessment_id:localStorage.getItem("AssessmentId")
          }
      }
      return tableData
    }
    changePitsburgData4=(tableData:AssessmentPitsburg4,key:string,name:string,value:number)=>{
      if(tableData.key === key && this.state.userType ==="client"){
        return {
          key: tableData.key,
          name_of_problem: tableData.name_of_problem,
          no_bed_partner_or_roommate: name ==="no_bed_partner_or_roommate",
          partner_roommate_in_other_room:name === "partner_roommate_in_other_room",
          partner_in_same_room_but_not_same_bed:name === "partner_in_same_room_but_not_same_bed",
          partner_in_same_bed:name === "partner_in_same_bed",
          value:value,
          assessment_id:localStorage.getItem("AssessmentId")
          }
      }
      return tableData
    }
    handlePitsburgSelect=(key:any,name:string,value:number, testNum:string)=>{
      if(this.state.clientStatus !== "closed"){
      switch (testNum) {
        case "fifth":
          return  this.setState({pitsburgData:this.state.pitsburgData?.map((tableData:any)=>{
           return this.changePitsburgData(tableData,key,name,value)
          }),countClicks:this.state.countClicks+1})
          case "six":
          return  this.setState({pitsburgData2:this.state.pitsburgData2.map((tableData)=>{
            return this.changePitsburgData2(tableData,key,name,value)
          }),countClicks:this.state.countClicks+1})
          case "seven":
            return  this.setState({pitsburgData3:this.state.pitsburgData3.map((tableData)=>{
              return this.changePitsburgData3(tableData,key,name,value)

            }),countClicks:this.state.countClicks+1})
            case "eight":
              return  this.setState({pitsburgData4:this.state.pitsburgData4.map((tableData)=>{
                return this.changePitsburgData4(tableData,key,name,value)
             
              }),countClicks:this.state.countClicks+1})
              default: return this.setState({pitsburgData5:this.state.pitsburgData5.map((tableData)=>{
                if(tableData.key === key && this.state.userType ==="client"){
                  return {
                    key: tableData.key,
                    name_of_problem: tableData.name_of_problem,
                    no_bed_partner_or_roommate: name ==="no_bed_partner_or_roommate",
                    partner_roommate_in_other_room:name === "partner_roommate_in_other_room",
                    partner_in_same_room_but_not_same_bed:name === "partner_in_same_room_but_not_same_bed",
                    partner_in_same_bed:name === "partner_in_same_bed",
                    value:value,
                    assessment_id:localStorage.getItem("AssessmentId")
                    }
                }
                return tableData
              }),countClicks:this.state.countClicks+1})
        }
      }
    }
    
    submitGAD7Anxiety = ()=>{
      if(this.state.testName==="GAD-7-Anxiety"){
        this.apiGAD7Anxiety(this.state.tableListData)
      }
     else if(this.state.testName==="PHQ-9-Depression"){
        this.apiGAD7Anxiety(this.state.tableListDeepresionData)
      }
      else if(this.state.testName==="Modified-Fatigue-Impact-Scale"){
        this.apiGAD7Anxiety(this.state.tableListMSIC)
      }
    }
    getTableColumn = ()=>{
      if(this.state.testName==="GAD-7-Anxiety"){
       return this.state.userSubmitData &&  this.state.userSubmitData.length > 0 ? this.state.userSubmitData : this.state.tableListData
      }
     else if(this.state.testName==="Modified-Fatigue-Impact-Scale"){
        return this.state.userSubmitData &&  this.state.userSubmitData.length > 0 ? this.state.userSubmitData : this.state.tableListMSIC
       }
       else if(this.state.testName==="Pittsburgh-Sleep-Quality-Index"){
        return this.state.pitsburgData
       }
      else{
       return this.state.userSubmitData &&  this.state.userSubmitData.length > 0 ? this.state.userSubmitData : this.state.tableListDeepresionData
      }
    }
    returnFirstState=()=>{
      if(this.state.pitsTableFirst.length > 0){
        return this.state.pitsTableFirst
      }
      else{
        return this.state.pitsburgData
      }
    }
    returnSecondState=()=>{
      if(this.state.pitsTableFirst.length > 0){
        return  this.state.pitsTableSecond
      }
      else{
        return this.state.pitsburgData2
      }
    }
    returnThirdState=()=>{
      if(this.state.pitsTableThird.length > 0){
        return  this.state.pitsTableThird
      }
      else{
        return this.state.pitsburgData3
      }
    }
    returnFourState=()=>{
      if(this.state.pitsTableFour.length > 0){
        return  this.state.pitsTableFour
      }
      else{
        return this.state.pitsburgData4
      }
    }
    returnFiveState=()=>{
      if(this.state.pitsTableFive.length > 0){
        return  this.state.pitsTableFive
      }
      else{
        return this.state.pitsburgData5
      }
    }
    getPitsburghData= (caseName:string)=> (() => {
        switch (caseName) {
          case "First":
            return this.state.pitsTableFirst && this.returnFirstState();
            case "Second":
              return this.state.pitsTableSecond && this.returnSecondState();
              case "Third":
                return this.state.pitsTableThird && this.returnThirdState();
                case "Four":
                  return this.state.pitsTableFour &&  this.returnFourState();
          default:
            return this.state.pitsTableFive && this.returnFiveState() ;
        }
      })()
    

  timerState() {
    const remainingMinutes = Math.floor(this.state.seconds / 60);
    const remainingSeconds = this.state.seconds % 60;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return {
      remainingMinutes,
      remainingSeconds,
      formattedSeconds
    }
  }

  apiAdminFunctionalPain = async () => {
    let token = localStorage.getItem("token")
    let AssessmentId= localStorage.getItem("AssessmentId")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const body = null
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.viewAssessment}${AssessmentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiGetAdminFunctionalPainCallId = requestMessage.messageId;
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  receiveAdminFunctionalScore= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetAdminFunctionalPainCallId &&
			this.apiGetAdminFunctionalPainCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson.assessment){
        this.setState({totalScore: responseJson.assessment.data.attributes.score})
        if(responseJson.assessment.data.attributes.score > 1){

          this.setState({functionalScore:responseJson.assessment.data && responseJson.assessment.data.attributes.score-1})
        }
        else if(responseJson.assessment.data.attributes.score === "8-9"){
          this.setState({functionalScore:7})
        } 
        else if(responseJson.assessment.data.attributes.score === "0.25-2.75"){
          this.setState({functionalScore:1})
        }
        else if(responseJson.assessment.data.attributes.score === "10"){
          this.setState({functionalScore:8})
        }
        else{
          this.setState({functionalScore:responseJson.assessment.data && responseJson.assessment.data.attributes.score})

        }
			}
      else{
        this.setState({errorMsg:responseJson.message})
      }
			
		}
  }
  apiAcceptUserData = async () => {
    let token = localStorage.getItem("token")
    let AssessmentId= localStorage.getItem("AssessmentId")
    this.setState({acceptLoader:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const body = null
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.accept1}${AssessmentId}${configJSON.accept2}${this.state.totalScore}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiAcceptCallId = requestMessage.messageId;
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAssessementMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  receiveAcceptScore= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiAcceptCallId &&
			this.apiAcceptCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson.assessment){
        this.navigateToDetail()
        SuccessMessage("Assessment Submitted");
        this.setState({acceptLoader:false})
			}
      else{
        FailedToastCenter(responseJson.error)
        this.setState({errorMsg:responseJson.error,acceptLoader:false})
      }
			
		}
  }
   apiAdminRedu = async () => {
    this.setState({acceptLoader:true})
    let token = localStorage.getItem("token")
    let AssessmentId= localStorage.getItem("AssessmentId")
    const body = null
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.redoAssessment}${AssessmentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiAdminRedo = requestMessage.messageId;
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAssessementMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  calculateScore = async () => {
    let token = localStorage.getItem("token")
    let AssessmentId= localStorage.getItem("AssessmentId")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
       const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.calculateScore}${AssessmentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiCalculateScoreCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAssessementMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  receiveRedoAssessment= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiAdminRedo &&
			this.apiAdminRedo ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson.assessment){
        this.setState({acceptLoader:false})
        this.navigateToDetail()
        SuccessMessage(responseJson.message);
			}
      else{
        FailedToastCenter(responseJson.error)
        this.setState({errorMsg:responseJson.error,acceptLoader:false})
      }
			
		}
  }

  apiViewSubmitData = async () => {
    let token = localStorage.getItem("token")
    let AssessmentId= localStorage.getItem("AssessmentId")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const body = null
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.viewAssessmentAdmin}${AssessmentId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiViewSubmitDataCal = requestMessage.messageId;
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAssessementMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  receiveSubmittedData= (message:Message)=>{
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiViewSubmitDataCal &&
			this.apiViewSubmitDataCal ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson.assessment.data){
        this.setState({resultData:responseJson.assessment,pitsTableFirst: responseJson.assessment.data.slice(4,15),
          pitsTableSecond:responseJson.assessment.data.slice(15,16),
          pitsTableThird:responseJson.assessment.data.slice(16,17),
          pitsTableFour:responseJson.assessment.data.slice(17,18),
          pitsTableFive:responseJson.assessment.data.slice(18,24),
          totalPitsScore:responseJson.assessment.total_count,
          columnOne: responseJson.assessment.data[0].answer,
          columnTwo: responseJson.assessment.data[1].answer,
          columnThree: responseJson.assessment.data[2].answer,
          columnFour: responseJson.assessment.data[3].answer,
          userSubmitData:responseJson.assessment.data?.sort((a:any,b:any)=> a.key - b.key),
        });
			}
      else{
        this.setState({errorMsg:responseJson.message})
      }
		}
  }
showAcceptRedoModal=(type:string)=>{
  if(this.state.clientStatus !== "closed"){
  if(this.state.assessmentStatus === "completed" && type ==="accept"){
    FailedToastCenter("The Assessment is already accepted")
  }
  else{

  this.setState({showAcceptRedo:true})
  if(type==="accept"){
    this.setState({checkClick:true})
  }
  else{
    this.setState({checkClick:false})
  }
}
}
}
submitAcceptData =()=>{
  if(this.state.checkClick){
    this.apiAcceptUserData()
  }
  else{
    this.apiAdminRedu()
  }
  
}
submitPitsData=()=>{
  const newArray=[...this.state.pitsburgData1,...this.state.pitsburgData,...this.state.pitsburgData2
    ,...this.state.pitsburgData3,...this.state.pitsburgData4,...this.state.pitsburgData5]
  this.setState({mergedPitsburghData:newArray})
 this.apiPitsburgh(newArray)
}
apiPitsburgh = async (newArray:any) => {
  let token = localStorage.getItem("token")
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token
  };
  this.setState({pitsburghLoader:true});
  const body = newArray
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   `${configJSON.gadAnxietyapi}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.apiPitsburghCallId = requestMessage.messageId;
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.submitAssessementTestAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

goBacktoListing=()=>{
  this.navigateToDetail()
  this.setState({afterSubmit:false})
}
receivePitsburgh= (message:Message)=>{
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.apiPitsburghCallId &&
    this.apiPitsburghCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({pitsburghLoader:false});
    if(responseJson.data){
      this.calculateScore();
      this.setState({showSubmitPopUp:false,showMFISPopUp:false,pitsModal:false,afterSubmit:true})
      SuccessMessage("Assessment Submitted");
    } 
    else{
      this.setState({errorMsg:responseJson.message,pitsburghLoader:false})
    }
    
  }
}

  // Customizable Area End
}

