// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import { Button, Col, Divider, Form, Input, Row, Spin } from "antd";
import UserLibraryController from './UserLibraryController.web';
import PageWrapper from '../../../components/src/Pagewrapper.web';

class UpdateCaseManagement extends UserLibraryController {
    
	constructor(props: any) {
		super(props);
        this.formRef = React.createRef<FormInstance>();
	}
    navigateBack = () => {
        window.history.back();
    }
    async componentDidMount(){
        this.getSingleFile(this.getUrlParameter("id"));
      }
	render(): React.ReactNode {
		return (
            <PageWrapper title = "Case Management" hasBack>
                <div className='create-case-management-content'>
                    <div data-test-id="update-form">
                        {
                            this.state.loadingData ? <Spin /> :
                            <Form
                            className="form"
                            ref={this.formRef}
                            layout="vertical"
                            initialValues={{title: this.state.title, entry: this.state.entry}}
                            style={{ padding: "19px" }}
                        >
                            <Row gutter={12}>
                                <Col xs={24} sm={12} md={8}>
                                    <Form.Item
                                        label="Title"
                                        name="title"
                                        className="bold-label"
                                    >
                                        <Input maxLength={20} />
                                    </Form.Item>
                                </Col>
                                
                                <Col xs={24}>
                                    <Form.Item
                                        name="entry"
                                        label="Note"
                                        className="bold-label"
                                    >
                                        <Input.TextArea style={{ height: '450px', resize: 'none' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="c-divider"></div>
                                <Row style={{ marginTop: '18px' }} justify="end">
                                    <Col>
                                    <a onClick={() => window.history.back()} data-test-id="cancel" className='cancel-case'>Cancel</a>
                                        <Button
                                            data-test-id="update-case"
                                            onClick={() => {
                                                this.updateNote(this.getUrlParameter("id"), this.formRef.current.getFieldsValue().title, this.formRef.current.getFieldsValue().entry)
                                                this.navigateBack()
                                            }}
                                            htmlType="submit"
                                            type="primary"
                                            style={{
                                                background: "#830065",
                                                borderColor: "#830065",
                                                borderRadius: "8px",
                                                marginBottom: "20px",
                                                height: "56px",
                                                width: '150px',
                                                color: "white",
                                                fontWeight: "bold",
                                                marginRight: '20px'
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                        </Form>
                        }
                        
                    </div>
                </div>
		    </PageWrapper>
           
		);
	}
}

export default UpdateCaseManagement;
// Customizable Area End