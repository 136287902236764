// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import './Settings.css';
import SettingController from '../../email-account-registration/src/EmailAccountRegistrationController.web';
import PageWrapper from '../../../components/src/Pagewrapper.web';

class PrivacyPolicy extends SettingController {
	constructor(props: any) {
		super(props);
	}
    async componentDidMount(){
		this.getPrivacyPolicy();
	}
	render(): React.ReactNode {
	return (
			<PageWrapper title = "Privacy Policy" hasBack>
				<div id="privacy" className='setting-content'>
					{this.state.content_type == "privacy_policy" ? <div id="p-div" dangerouslySetInnerHTML={{__html: this.state.content_description}}></div> : <></>}
				</div>
			</PageWrapper>
		);
	}
}

export default PrivacyPolicy;
// Customizable Area End