// Customizable Area Start
import React from 'react';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import { Input, Space, Row, Col } from 'antd';
import './UserLibrary.css';
import { Link } from "react-router-dom";
import { noData, search } from "./assets";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import UserLibraryController from './UserLibraryController.web';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';

class ClientSearchScreen extends UserLibraryController {
	constructor(props: any) {
		super(props);
	}
    async componentDidMount(){
		this.searchClient();
	}
    onViewClick=(status:string)=>{
        localStorage.setItem("ClientStatus",status);
        window.scrollTo(0, 0);
    }
	render(): React.ReactNode {
		return (
			<PageWrapper title="User library">
                <div className='client-content'>
                    <div id='case-id' className='search-content-main' style={{ width: '100%' }}>
                    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <p className='case-title'>ALL CLIENTS</p>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right', marginTop: '8px' }}>
                            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                                <Input
                                    name='search'
                                    className='input-search'
                                    onChange={(e) => this.searchClient(e.target.value)}
                                    placeholder="Search by client name"
                                    prefix={<img src={search} style={{color: '#94A3B8', marginLeft: '6px', height: '18px'}}/>}
                                    style={{
                                        width: '100%', 
                                        maxWidth: '360px',
                                        color: '#64748B',
                                        fontSize: '16px',
                                        borderRadius: '28px',
                                        height: '44px',
                                        border: '1px solid #CBD5E1',
                                        padding: '4px'
                                    }}
                                />
                            </Space>
                        </Col>
                    </Row>
                    </div>
                     <div style={{marginTop: '20px', marginBottom: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {this.state.clientData.length > 0 && this.state.searchText ? (
                        <TableContainer component={Paper} 
                        style={{ 
                            maxWidth: '100%',
                            overflowX: 'auto',
                            borderBottomRightRadius: '20px',
                            borderBottomLeftRadius: '0',
                            borderTopRightRadius: '0',
                            borderTopLeftRadius: '0',
                            }}>
                            <Table aria-label="simple table" style={{ borderRadius: '0 0 32px 8px', overflow: 'hidden', minWidth: '800px' }}>
                         <TableHead style={{ backgroundColor: '#F1F5F9' }}>
                         <TableRow>
                             <TableCell style={{fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '70%' }}>Name</TableCell>
                             <TableCell style={{fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '30%' }}>Action</TableCell>
                         </TableRow>
                         </TableHead>
                         <TableBody data-test-id = "client-list">
                         {this.state.clientData?.map((row: any, i: number) => (
                             <TableRow
                             key={i} className={i === this.state.clientData.length - 1 ? 'last-row' : ''}
                             >
                             <TableCell style={{backgroundColor: "white", fontSize: 12, fontWeight: 400, color: "#0F172A" }} component="th" scope="row">
                             {row.full_name ? row.full_name.charAt(0).toUpperCase() + row.full_name.slice(1) : ''}
                             </TableCell>
                             <TableCell data-testid = "viewstatus" style={{backgroundColor: "white", fontSize: 14, fontWeight: 400, color: "#0F172A" }}><Link onClick={this.onViewClick.bind(this,row.status)} to = {`/user-library?name=${row.first_name}&id=${row.id}`}><p className="view">View</p></Link></TableCell>
                             </TableRow>
                         ))}
                         </TableBody>
                            </Table>
                        </TableContainer>
                        )
                     : 
                     (
                        <div style={{padding: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                            <img src={noData} style={{height: '50px',  marginBottom: '10px'}} />
                            <p className='no-data-font'>Result not found</p>
                        </div>
                    )
                           
                        }
                        </div>
                        </div>
			</PageWrapper>
		);
	}
}

export default ClientSearchScreen;

// Customizable Area End