Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.getAssessementMethod = "GET";
exports.allAssessementTestApiContentType = "application/json";
exports.getAllAssessementTestUrl = `bx_block_assessmenttest/assessments`;
exports.getAssessementTestCategoriesUrl = `bx_block_assessmenttest/assessment_types`;
exports.getAttemptedAssessmentsUrl = `bx_block_assessmenttest/user_all_assessments`;
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AssessmentTest";
exports.labelBodyText = "AssessmentTest Body";
exports.btnExampleTitle = "CLICK ME";
exports.mockPassword = "password!";
exports.getClientList = "bx_block_assessmenttest/assessments/clients";

  // Customizable Area End