// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const noData = require("../assets/no-data.png");
export const search = require("../assets/search.png");
export const chevron_left = require("../assets/chevron_left.png");
export const chevron_right = require("../assets/chevron_right.png");
export const dropdown = require("../assets/dropdown.png");
export const deleteTask = require("../assets/delete.png");
export const editTask = require("../assets/edit.png");
export const cancel = require("../assets/cancel.png");
// Customizable Area End