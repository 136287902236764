// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import './Settings.css';
import {Link} from 'react-router-dom';
import SettingController from '../../email-account-registration/src/EmailAccountRegistrationController.web';
import PageWrapper from '../../../components/src/Pagewrapper.web';

class SettingsWeb extends SettingController {
	constructor(props: any) {
		super(props);
	}
	
	render(): React.ReactNode {
		return (
				<PageWrapper title = "About" hasBack>
					<div id="setting-div" className='setting-div'>
						<p id="privacy" className='terms-text'><Link to="/privacy-policy">Privacy Policy</Link></p>
						<div className='p-divider'></div>
						<p id="terms" className='terms-text'><Link to="/terms-and-conditions">Terms and Conditions</Link></p>
						<div className='p-divider'/>
						<p id="cookie" className='terms-text'><Link to="/cookie-policy">Cookie Policy</Link></p>
					</div>
				</PageWrapper>
		);
	}
}

export default SettingsWeb;
// Customizable Area End