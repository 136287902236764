// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import EmailAccountLoginController from "../../catalogue/src/UserLibraryController.web";
import PageWrapper from '../../../components/src/Pagewrapper.web';
import { Row, Col } from 'antd';
import { caseStudy, journal, lifestyle, medical, occupational, phychology, physiotherapy, returnWork } from "./assets";
import './UserLibrary.css';
import {Link} from 'react-router-dom';
import { FailedToastCenter } from '../../../components/src/Toast';

class UserLibraryDashboard extends EmailAccountLoginController {
	constructor(props: any) {
		super(props);
	}

	render(): React.ReactNode {
        const userType = localStorage.getItem('userType');
        let accessibleFolders = cards.slice(0, 7);
        if (userType === "client") {
            accessibleFolders = cards; 
        }
        if(this.state.clientStatus === "closed"){
			FailedToastCenter("This case has been closed");
		}
		return (
			<PageWrapper title = "User Library">
                {
                   <div className="change-client-div">
                    <p style={{ marginRight: "24px"}}>Client: <strong>{this.getUrlParameter("name")}</strong></p>
                    {userType !== "client" ? <Link to = "/search-client"><p className="change-client">Change</p></Link> : ""}
                    </div>
                }
                <div className='user-library-divider'></div>
				<div id='library-id' className='user-library-dashboard-content'>
                <Row gutter={[16,16]}>
                    {accessibleFolders.map((card, i) => (
                        <Col key={i} xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Link to={`${card.link}?id=${this.getUrlParameter('id')}&folderType=${card.folderType}`} data-test-id = {card.folderType}>
                                <div className="user-library-card">
                                    <img className="user-library-icon" src={card.imageUrl} />
                                    <p id="folder_text" className="user-library-card-label">{card.label}</p>
                                </div>
                            </Link>
                        </Col>
                    ))}
                </Row>
				</div>
			</PageWrapper>
		);
	}
}

const cards = [
    {
        label: "Physiotherapy",
        link: '/user-library-folder',
        imageUrl: physiotherapy,
        folderType: 'physiotherapy'
    },
    {
        label: "Occupational Therapy",
        link: '/user-library-folder',
        imageUrl: occupational,
        folderType: 'occupational_therapy'
    },
    {
        label: "Return to Work",
        link: '/user-library-folder',
        folderType: 'return_to_work',
        imageUrl: returnWork,
    },
    {
        label: "Psychology",
        link: '/user-library-folder',
        folderType: 'psychology',
        imageUrl: phychology,
    },
    {
        label: "Other Medical",
        link: '/user-library-folder',
        folderType: 'other_medical',
        imageUrl: medical,
    },
    {
        label: "Lifestyle",
        link: '/user-library-folder',
        folderType: 'lifestyle',
        imageUrl: lifestyle,
    },
    {
        label: "Case management",
        link: '/user-library-folder',
        imageUrl: caseStudy,
        folderType: 'case_management'
    },
    {
        label: "My Rehabilitation Journal",
        link: '/client-rehabilitation',
        imageUrl: journal,
        folderType: 'my_rehabilitation_journal'
    }
]
export default UserLibraryDashboard;
// Customizable Area End