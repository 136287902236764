export const emptyCase = require("../assets/dashboardempty.png");
export const happy = require("../assets/happy.png");
export const sad = require("../assets/sad.png");
export const positive = require("../assets/positive.png");
export const complete = require("../assets/complete.png");
export const pending = require("../assets/pending.png");
export const ongoing = require("../assets/ongoing.png");
export const award = require("../assets/award.png");
export const joy = require("../assets/joy.png");
export const incomplete = require("../assets/incomplete.png");
export const dash = require("../assets/dash.png");
export const noGoal = require("../assets/no-goal.png");
export const completed = require("../assets/completed.png");
export const noAppointment = require("../assets/no-appointment.png");
export const userImage = require("../assets/user.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
