// Customizable Area Start
import { SuccessMessage } from "../../../components/src/Toast";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
	navigation: any;
	id: string;
	match: any;
}

interface ClientData {
		full_name: string;
}
interface S {
	clientData: any,
	loadingClientData: boolean,
	loadingDateWiseClients: boolean,
	dateWiseAllData: any,
	dateWiseSelectedData: any,
	showAddTask: boolean,
	createErrorMsg: string,
	loadingCreateTask: boolean,
	selectedDate: any,
	deletePopup: boolean,
	taskId: any,
	taskName: any,
	isEditing: boolean,
	taskData: any,
	loadingUpdateStatus: boolean,
	showUpdateStatus: boolean,
	fileName: string,
	allTaskData: any,
	filterStatus: boolean;
	filteredClientData: ClientData[],
	deleteErrorMsg: string,
	clientStatus:string | null,
}

interface SS {
	id: any;
}



export default class UserLibraryController extends BlockComponent<Props, S, SS> {
	
	apiResetGetTaskFormCallId: string = "";
	apiCreateTaskPostCallId: string = "";
	apiResetDateWiseGetClientFormCallId: string = "";
	apiDeleteTaskPostCallId: string = "";
	apiUpdateTaskPostCallId: string = "";
	apiUpdateStatusPostCallId: string = "";
	apiResetGeViewAllTaskCallId: string = "";
	
	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.NavigationPayLoadMessage),
		];
		this.state = {
			clientData: [],
			loadingClientData: false,
			loadingDateWiseClients: true,
			dateWiseAllData: [],
			dateWiseSelectedData: null,
			showAddTask: false,
			createErrorMsg: '',
			loadingCreateTask: false,
			selectedDate: '',
			deletePopup: false,
			taskId: 0,
			taskName: '',
			isEditing: false,
			taskData: null,
			loadingUpdateStatus: false,
			showUpdateStatus: false,
			fileName: '',
			allTaskData: [],
			filterStatus: false,
			filteredClientData:[],
			deleteErrorMsg: '',
			clientStatus:localStorage.getItem("ClientStatus"),

		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async receive(from: string, message: Message) {
		this.receiveGetTasks(message);
		this.receiveCreateTask(message);
		this.receiveDateWiseClients(message);
		this.receiveDeleteTask(message);
		this.receiveUpdateTask(message);
		this.receiveUpdateStatus(message);
		this.receiveGetViewAllTask(message);
	}

	async receiveGetTasks(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetTaskFormCallId &&
            this.apiResetGetTaskFormCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(responseJson && responseJson.clients){
                this.setState({clientData: responseJson.clients, loadingClientData: false});
            }
            

          }
	}

	async receiveCreateTask(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiCreateTaskPostCallId &&
			this.apiCreateTaskPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.task){
				this.dateWiseGetClients(this.state.selectedDate, this.getUrlParameter("id"));
				this.setState({ showAddTask: false,loadingCreateTask: false });
				SuccessMessage("Task Added successfully");
				
			}else if(responseJson && responseJson.message){
				this.setState({ loadingCreateTask: false, createErrorMsg: responseJson.message});
			}else if(responseJson && responseJson.error){
				this.setState({ loadingCreateTask: false, createErrorMsg: responseJson.error});
			}
			else{
				this.setState({loadingCreateTask: false})
			}
		}
	}

	receiveDateWiseClients(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetDateWiseGetClientFormCallId &&
			this.apiResetDateWiseGetClientFormCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && responseJson.data && responseJson.data.length > 0) {
				this.setState({dateWiseAllData: responseJson.data, loadingDateWiseClients: false, dateWiseSelectedData: responseJson.data[0]});
					 
			}else{
				this.setState({dateWiseAllData: [], loadingDateWiseClients: false, dateWiseSelectedData: null});
			}


		}
	}


	async receiveDeleteTask(message: Message) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiDeleteTaskPostCallId &&
			this.apiDeleteTaskPostCallId ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
	
			 if(responseJson && responseJson.message){
				this.setState({deletePopup: false })
				SuccessMessage("Task deleted successfully");
				this.dateWiseGetClients(this.state.selectedDate, this.getUrlParameter("id"));
				this.getAllTask(this.getUrlParameter("id"))
			  }else{
				this.setState({deleteErrorMsg: responseJson.error})
			}
		}
	}
	async receiveUpdateTask(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiUpdateTaskPostCallId &&
			this.apiUpdateTaskPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.data){
				this.dateWiseGetClients(this.state.selectedDate, this.getUrlParameter("id"));
				this.getAllTask(this.getUrlParameter("id"));
				this.setState({ showAddTask: false,loadingCreateTask: false });
				SuccessMessage("Task updated successfully");
				
			}else if(responseJson && responseJson.message){
				this.setState({ loadingCreateTask: false, createErrorMsg: responseJson.message});
			}else if(responseJson && responseJson.error){
				this.setState({ loadingCreateTask: false, createErrorMsg: responseJson.error});
			}
			else{
				this.setState({loadingCreateTask: false})
			}
		
			
		}
	}
	
	async receiveUpdateStatus(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiUpdateStatusPostCallId &&
			this.apiUpdateStatusPostCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if(responseJson && responseJson.task){
				this.dateWiseGetClients(this.state.selectedDate, this.getUrlParameter("id"));
				this.getAllTask(this.getUrlParameter("id"));
				this.setState({ showUpdateStatus: false, loadingUpdateStatus: false });
				SuccessMessage("Status Updated");
				
			}else if(responseJson && responseJson.message){
				this.setState({ loadingUpdateStatus: false, createErrorMsg: responseJson.message});
			}else{
				this.setState({loadingUpdateStatus: false})
			}
		
			
		}
	}

	async receiveGetViewAllTask(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGeViewAllTaskCallId &&
            this.apiResetGeViewAllTaskCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
			if(responseJson && responseJson?.data){
                this.setState({allTaskData: responseJson?.data});
            }
            
          }
	}

	getTasks = () => {
        const token = localStorage.getItem("token");
        this.setState({loadingClientData: true})
		const header = {
		  "Content-Type": configJSON.apiContentType,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetTaskFormCallId = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  configJSON.taslListApiEndPoint
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.getApiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };

	  createTask(values: any): boolean {
		const token = localStorage.getItem("token");
		this.setState({loadingCreateTask: true});
		const header = {
			"Content-Type": configJSON.apiContentType,
			"token": token
		};
		const httpBody = {
			"title": values.title,
			"from_date": values.from_date,
			"due_date": values.due_date,
			"priority": values.priority,
			"client_id": this.getUrlParameter("id")
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiCreateTaskPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.createTaskApiEndPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.postApiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};

	dateWiseGetClients = (date: any, clientId: any) => {
		this.setState({loadingDateWiseClients: true, selectedDate: date});
		const token = localStorage.getItem('token');
		const header = {
			"Content-Type": configJSON.apiContentType,
			"token": token
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiResetDateWiseGetClientFormCallId = requestMessage.messageId;
	
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.showClientDateWise}?date=${date}&client_id=${clientId}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getApiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	deleteTask(id: string): boolean {
		const token = localStorage.getItem('token');
		const header = {
			"Content-Type": configJSON.apiContentType,
			"token": token
		};

		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiDeleteTaskPostCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.deleteTaskApiEndPoint}/${id}`
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.deleteApiMethod
		);
	
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	  }

	  updateTask(values: any, id: any): boolean {
		const formatDate = (date: any) => {
			const dateObj = new Date(date);
			return dateObj.toLocaleDateString('en-CA');
		};
		const token = localStorage.getItem("token");
		this.setState({loadingCreateTask: true, 'taskData': { ...this.state.taskData, due_date: values.due_date}});
		const header = {
			"Content-Type": configJSON.apiContentType,
			"token": token
		};
		const httpBody = {
			"title": values.title,
			"from_date": formatDate(values.from_date),
			"due_date": formatDate(values.due_date),
			"priority": values.priority
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiUpdateTaskPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.createTaskApiEndPoint}/${id}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.patchApiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};

	updateStatus(values: any, id: any): boolean {
		const token = localStorage.getItem("token");
		this.setState({loadingUpdateStatus: true});
		const header = {
			"Content-Type": configJSON.apiContentType,
			"token": token
		};
		const httpBody = {
			"task": {
				"status": values.status
			}
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiUpdateStatusPostCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.createTaskApiEndPoint}/${id}/update_client_status`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.patchApiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	};

	getAllTask = (id: any) => {
        const token = localStorage.getItem("token");
		const header = {
		  "Content-Type": configJSON.apiContentType,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGeViewAllTaskCallId = requestMessage.messageId;
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.getAllTaskApiEndPoint}${id}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.getApiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };


	  getUrlParameter(name: any) {
		let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		let results = regex.exec(window?.location?.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	}

	
	showAddTaskModal = (data: any, isEditing: boolean) => {
		this.setState({taskData: isEditing ? data?.attributes : {}, isEditing});
		setTimeout(() => {
			this.setState({showAddTask: true})
		}, 300);
	}

	closeCreateTaskModal = () => {
		this.setState({showAddTask: false, taskId: 0, createErrorMsg: '' });
	}
	
	onFinishCreateTask = (values: any, id: any) => {
		if(this.state.isEditing) {
			this.updateTask(values, id);
		} else {
			this.createTask(values);
		}
	};

	deleteModalPopup = (id: string, name: string) => {
		this.setState({ deletePopup: true, taskId: id, taskName: name })
	}

	deleteHandleCancel = () => {
		this.setState({deletePopup: false, deleteErrorMsg: '' });
	}

	showUpdateStatusModal = (id: string) => {
		this.setState({showUpdateStatus: true, taskId: id})
	}

	closeUpdateStatusModal = () => {
		this.setState({showUpdateStatus: false});
	}

	onFinishUpdateStatus = (values: any, id: any) => {
		this.updateStatus(values, id);
	};

	handle_Search = (e: React.ChangeEvent<HTMLInputElement>) => {
		const search_Text = e.target.value.toLowerCase();
		const filtered_Data = this.state.clientData.filter((item: any) =>
			item.full_name.toLowerCase().includes(search_Text)
		);

		this.setState({ filteredClientData: filtered_Data, filterStatus: true });
	};

	getDataSource = () => {
		if (this.state.filterStatus) {
			return this.state.filteredClientData
		} else {
			return this.state.clientData
		}
	}

	getPopupContainer = (trigger:any)=>{
		return trigger.parentElement
	  }

}
// Customizable Area End