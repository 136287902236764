// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from '@ant-design/icons';
import { chevron_left, chevron_right, more } from './assets';
import moment from 'moment';
import Calendar from 'react-calendar'
import UserLibraryController from './UserLibraryController.web';

class MyRehabJourneyDetails extends UserLibraryController {
	constructor(props: any) {
		super(props);
	}
    async componentDidMount(){
        this.dateWiseGetEntries(this.getUrlParameter('date'))
      }


	render(): React.ReactNode {
		return (
			<PageWrapper title="My Rehabilitation Journal" hasBack >
                <div>
                    <Link to={`/create-entry?clientId=${this.getUrlParameter('id')}&folderType=${this.getUrlParameter('folderType')}`}>
                        <div className='create-file-div'>
                            <PlusCircleOutlined className='add-icon3'/>
                            <p style={{marginTop: '14px'}} className='add-file' id="create">Add Entry</p>
                        </div>
                    </Link>
                    <div data-test-id = "pop" className="screen-container" onClick = {()=> this.setState({popupVisible: false })}>
                        <div className="left-side">
                        <div className='apt-calender' style={{marginRight: '0px'}}>
                            <Calendar
                                className='calender-back'
                                data-testid="calendar-data"
                                prevLabel={<img class="image" src={chevron_left} />}
                                nextLabel={<img class="image" src={chevron_right} />}
                                next2Label={null}
                                prev2Label={null}
                                onChange={this.dateWiseGetEntries}
                                defaultValue ={new Date(this.getUrlParameter("date"))}
                            />
                            </div>
                            <p className="recent-text">RECENT ENTRIES</p>
                        {
                            this.state.dateWiseAllData && 
                            this.state.dateWiseAllData.length > 0 &&
                            this.state.dateWiseAllData.map((e: any, i: any) => (
                            <div key={i} className='case-management-card text-div' data-test-id={`note${i}`} >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        <p className='case-management-title'>{e.attributes.title}</p>
                                    </div>
                                    <div style={{ marginLeft: 'auto', position:"relative" }}>
                                        <img
                                            data-test-id = {`more${i}`}
                                            src={more}
                                            style={{ height: '24px', cursor: "pointer" }}
                                            onClick = {
                                                (event: React.MouseEvent<HTMLDivElement, MouseEvent>)=> { 
                                                    event?.stopPropagation();
                                                    this.setState({popupVisible: e.id })
                                                    
                                                }
                                                }
                                        />
                                            {this.state.popupVisible == e.id && <div className='more-div'>
                                                <p className="pointer-text" data-test-id={`view-entry${i}`} onClick= {() => this.setState({dateWiseSelectedData: e})}>View</p>
                                                <Link to={`/update-entry?id=${e.id}`}>
                                                    <p  className="pointer-text" id='view' >Edit</p>
                                                </Link>
                                            </div>
                                            }
                                    </div>
                                </div>
                                <div style={{ gridColumn: '1 / 2' }}>
                                    <p className='case-management-note'>{e.attributes.entry}</p>
                                </div>
                                <hr style={{ border: 'none', borderBottom: '1px solid #DDDDDD66', width: '100%' }} />
                                <div style={{ gridColumn: '1 / 2' }}>
                                    <p className='case-management-date'>Last edited on <span style={{fontSize: '12px', fontWeight: '600'}}>{moment(e.attributes.updated_at).format('D MMM YYYY | h:mm A')}</span></p>
                                </div>
                            </div>
                            ))
                        }
                        </div>
                        <div className="right-side">
                            {
                            this.state.dateWiseSelectedData &&
                                <div className="big-text-div">
                                    <p className="entry-title-text">{this.state.dateWiseSelectedData.attributes.title} , {this.state.dateWiseSelectedData.attributes.entry_date}</p>
                                    <p>{this.state.dateWiseSelectedData.attributes.entry}</p>
                                </div>
                            }
                        </div>
                    </div>
                    </div>
			</PageWrapper>
		);
	}
}

export default MyRehabJourneyDetails;
// Customizable Area End