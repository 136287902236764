// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import { cancel, deleteTask, editTask, noData } from './assets';
import TaskController from "./TaskController.web";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { Modal, Button, Form, Input, Select, DatePicker, Row, Col } from 'antd';
import './Task.css';
import moment from "moment";
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

class ViewAllTask extends TaskController {
    formRef: any;
	constructor(props: any) {
		super(props);
        this.formRef = React.createRef<FormInstance>();
	}
    async componentDidMount(){
		this.getAllTask(this.getUrlParameter("id"));
	}
    renderAddModalTask() {
		return (
			<Modal
				className="appointment-modal"
				id="task-modal"
				title={
                <div class="header-container1" style={{ padding: "35px 24px 16px" }}>
					<h2 style={{ fontSize: '24px', color: '#000', fontWeight: 700 }}>
                    Update Task
                    </h2>
					<img 
                        class="image" 
                        onClick={() => {
						this.formRef.current.resetFields()
						this.closeCreateTaskModal()
					}} 
                    className="cell-style" 
                    src={cancel} />
				</div>}
                    open={this.state.showAddTask}
                    closable={false}
                    footer={null}
                    width={441}
                    okButtonProps={{
                        style: {
                            display: "none",
                        },
                    }}
                    cancelButtonProps={{
                        style: {
                            display: "none",
                        },
                    }}
			>
				<Form
                    initialValues={this.state.isEditing ? {...this.state.taskData, 
                        from_date: moment(this.state.taskData?.from_date),
                        due_date: moment(this.state.taskData?.due_date)} : {}}
					data-testid="submit-data"
                    key={`${this.state.isEditing}${this.state.taskData?.id}`}
                    layout="vertical"
					className="form"
					ref={this.formRef}
					style={{ 
                        padding: "0 24px 20px 24px", 
                        position: "relative"
                     }}
					onFinish={() => {
						this.onFinishCreateTask(this.formRef.current.getFieldsValue(), this.state.isEditing ? this.state.taskData?.id : null);
					}}

				>
					<Row gutter={12}>
						<Col xs={24}>
							<Form.Item
								label={
                                    <span className="bold-label">
                                      <span>Task Name </span> 
                                      <span className="sub-text">(max 20 characters)</span>
                                    </span>
                                  }
								name="title"
								className="bold-label"
								validateFirst
								rules={[
									{
										required: true,
										message: "Please enter name",
									},
									{ min: 2, max: 20 }
								]}

							>
								<Input maxLength={20} className="font-style" placeholder='Enter name of the task' />
							</Form.Item>
						</Col>
                        <Col xs={24}>
							<Form.Item
								name="priority"
								label="Time Period"
								className="bold-label"
								rules={[
									{
										required: true,
										message: "Please select time period"
									}
								]}

							>

								<Select
									className="font-style apt-design"
									id="select-type"
									placeholder="Enter time period"
                                    getPopupContainer={this.getPopupContainer}
									options={[
										{ value: 'daily', label: 'Daily' },
										{ value: 'weekly', label: 'Weekly' },
                                        { value: 'monthly', label: 'Monthly'}
									]}
								/>
							</Form.Item>

						</Col>
						<Col xs={24}>
                                <Form.Item
                                    label="From Date"
                                    name="from_date"
                                    className="bold-label"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select from date"
                                        }
                                    ]}

							    >

                                    <DatePicker
                                        placeholder='Date of Entry'
                                        id="date"
                                        className="font-style time-design"
                                        format="DD MMM YYYY"
                                        picker="date"
                                        inputReadOnly={true}
                                    />
							    </Form.Item>
						</Col>
                        <Col xs={24}>
                                <Form.Item
                                    label="To Date"
                                    name="due_date"
                                    className="bold-label"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select to date"
                                        }
                                    ]}

							    >
                                     <DatePicker
                                        placeholder='Date of Entry'
                                        id="date"
                                        className="font-style time-design"
                                        format="DD MMM YYYY"
                                        picker="date"
                                        inputReadOnly={true}
                                    />
							    </Form.Item>
						</Col>
						<Col xs={24}>
                        <div className='divider-t'></div>
                        <div className='flex-end-mt-20'>
								<Button
									data-testid="close-model"
									onClick={() => {
										this.closeCreateTaskModal()
										this.formRef.current.resetFields()
									}}
                                    type="primary"
									className='cancel-button'
								>
									Cancel
								</Button>
								<Button
									loading={this.state.loadingCreateTask}
                                    type="primary"
									htmlType="submit"
									className='update-button'
								>
									Update
								</Button>
							</div>
						</Col>
                        {this.state.createErrorMsg !== '' ? <p data-test-id="error-msg" className='text-left-red'>{this.state.createErrorMsg}</p> : null}
					</Row>
				</Form>
			</Modal>
		);
	}
    renderTaskStatus = (taskStatus) => {
        if (taskStatus === "pending") return <div className='pending-div'>Pending</div>;
        if (taskStatus === "ongoing") return <div className='ongoing-div'>Ongoing</div>;
        if (taskStatus === "completed") return <div className='completed-div'>Completed</div>;
    };
    renderTaskActionButtons = (e) => {
        const isClosed = this.state.clientStatus === "closed";
        return (
            <div className='flex-start-center' >
            <p 
                data-testid="reassign"
                onClick={() => {
                    if (e.attributes.status !== "pending" && e.attributes.status !== "ongoing") {
                        this.showAddTaskModal(e, true);
                    }
                }}
                className="view" 
                disable={true}
                style={{
                    color: (e.attributes.status === "pending" || e.attributes.status === "ongoing") ? "grey" : "#830065",
                    cursor: "pointer"
                }}
            >
                Reassign
            </p>
            {e.attributes.status !== "completed" && (
					<>
						<DeleteOutlineIcon
							data-testid="delete-data"
                            style={{
                                cursor: "pointer",
                                color: isClosed ? "grey" : "#830065",
                                marginLeft: "12px"
                                }}
							onClick={!isClosed ? () => this.deleteModalPopup(e.attributes.id, e.attributes.title) : null}
						/>
						<EditIcon
							data-testid="edit-data"
                            style={{
                                cursor: "pointer",
                                color: isClosed ? "grey" : "#830065",
                                marginLeft: "12px"
                                }}
							onClick={!isClosed ? () => this.showAddTaskModal(e, true) : null}
						/>
					</>
				)}
        </div>
        );
    };
    
    renderAction = (e, userType) => {
        if (userType !== "client") {
            return (
                <TableCell style={{ backgroundColor: "white", width: '15%', fontSize: 14, fontWeight: 700, color: '#830065' }}>
                    {this.renderTaskActionButtons(e)}
                </TableCell>
            );
        }
        return null;
    };
    
    renderCellUpdate = (e, userType) => {
        if (userType !== "case_controller" && userType !== "case_manager" && userType !== "assistant_case_manager" && userType !== "solicitar") {
            return (
                <TableCell style={{ backgroundColor: "white", width: '15%', fontSize: 14, fontWeight: 700, color: '#830065' }}>
                    <p data-testid="update-status" onClick={() => this.showUpdateStatusModal(e.attributes.id)} className="view">Update</p>
                </TableCell>
            );
        }
        return null;
    };
    renderModalsDeleteTask() {
		return (
			<Modal
			  className="delete-modal"
			  id="delete-modal"
			  open={this.state.deletePopup}
			  closable={false} 
			  footer={null}
			>
			<>
			  <div className='custom-padding-font'>
				Are you sure you want to delete "{this.state.taskName}" from tasks?
			  </div>
              <div className='l-divider'></div>
			  <div className='custom-container' >
                    <Button
                        className='delete-task-cancel'
                        data-testid="cancel-popup"
                        onClick={()=>this.deleteHandleCancel()} >Cancel</Button>
                    <Button
                        className='delete-task-button'
                        data-testid="delete-task"
                        onClick={()=>this.deleteTask(this.state.taskId)}>
                            Yes, Delete
                    </Button>
				</div>
                {this.state.deleteErrorMsg !== '' ?
								<p data-test-id="error-msg" style={{ color: '#DC2626', textAlign: 'left', padding: '0 0 10px 10px' }}>{
									this.state.deleteErrorMsg}
								</p> : null}
			</>
			</Modal>
		);
	}

    renderUpdateStatusModalTask() {
		return (
			<Modal
				className="appointment-modal"
				id="update-status-task-modal"
				title={
                <div 
                    style={{ padding: "36px 24px 15px"}}
                    class="header-container1" 
                >
					<h2 className='custom-text'>
                    Task Status
                    </h2>
					<img class="image" onClick={() => {
						this.formRef.current.resetFields()
						this.closeUpdateStatusModal()
					}} 
                    className='custom-icon'
                     src={cancel} />
				</div>
                }
                closable={false}
                width={441}
				open={this.state.showUpdateStatus}
                cancelButtonProps={{
					style: {
						display: "none"
					}
				}}
				footer={null}
				okButtonProps={{
					style: {
						display: "none"
					}
				}}
			>
				<Form
                    className="form"
                    data-testid="save-status"
                    style={{ padding: "0 24px 20px 24px", position: "relative" }}
					ref={this.formRef}
					layout="vertical"
					onFinish={() => {
						this.onFinishUpdateStatus(this.formRef.current.getFieldsValue(),this.state.taskId);}}
				>
					<Row gutter={12}>
                        <Col xs={24}>
							<Form.Item
                                className="bold-label"
								name="status"
								label="Select Status"
								rules={[{
										required: true,
										message: "Please select time period"
									}]}
							>

								<Select
									className="font-style apt-design"
									id="select-type"
									placeholder="Select Task Status"
                                    getPopupContainer={this.getPopupContainer}
									options={[{ value: 'ongoing', label: 'Ongoing' }, { value: 'completed', label: 'Completed' }]}
								/>
							</Form.Item>

						</Col>
						<Col xs={24}>
                        <div className='ho-divider'></div>
                        <div className='f-container'>
								<Button
                                    onClick={() => {
										this.closeUpdateStatusModal()
										this.formRef.current.resetFields()
									}}
                                    type="primary"
									data-testid="cancel-status-modal"
									className='cancel-status-btn'>Cancel
								</Button>
								<Button
                                    htmlType="submit"
									loading={this.state.loadingUpdateStatus}
									type="primary"
									className='add-status-btn'>Add
								</Button>
							</div>
						    </Col>
					        </Row>
				        </Form>
			    </Modal>
		);
	}
    renderEmptyData = () => {
        return (
            <div className='container-d'>
                <img src={noData} alt="No Data" className='image-q' />
                <div className='text-no'>No task added yet</div>
            </div>
        );
    }
	render(): React.ReactNode {
        const userType = localStorage.getItem('userType');
		return (
			<PageWrapper title="All tasks" hasBack >
                        <div className='search-content-task'>
                            <div className='container-u'>
                                <p className='case-title'>List of Tasks</p>
                            </div>
                        </div>
                        
                        <div>
                        {this.state.allTaskData && this.state.allTaskData.length > 0 ? (
                            <TableContainer component={Paper} style={{ maxWidth: '100%', overflowX: 'auto',  borderRadius: '0px 0px 32px 8px' }}>
                                <Table aria-label="simple table" style={{ minWidth: '800px', borderRadius: '0 0 32px 8px', overflow: 'hidden' }} data-test-id="table">
                                    <TableHead style={{ backgroundColor: '#F1F5F9' }}>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '20%', overflow: 'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis', maxWidth: '10ch' }}>Task Name</TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Assigned on</TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Assigned Till</TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Status</TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Period</TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 700, color: '#0F172A', width: '15%' }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.allTaskData.map((e, i) => (
                                            <TableRow key={i} className={i === this.state.allTaskData.length - 1 ? 'last-row' : ''}>
                                                <TableCell 
                                                style={{ backgroundColor: "white", width: '20%', fontSize: 14, fontWeight: 400, color: "#0F172A",maxWidth: '11ch',
                                                overflow: 'hidden',whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                                            }}>{(e?.attributes?.title ?? '').charAt(0).toUpperCase() + (e?.attributes?.title ?? '').slice(1)}</TableCell>
                                                <TableCell 
                                                    style={{ 
                                                        fontWeight: 400, 
                                                        color: "#0F172A",
                                                        backgroundColor: "white",
                                                        width: '14%', 
                                                        fontSize: 14}}>
                                                            {moment(e.attributes.from_date).format('D MMM YYYY')}
                                                </TableCell>
                                                <TableCell 
                                                    style={{ fontSize: 14,backgroundColor: "white", color: "#0F172A", width: '16%', fontWeight: 400, }}>
                                                    {moment(e.attributes.due_date).format('D MMM YYYY')}
                                                </TableCell>
                                                <TableCell 
                                                    style={{ width: '15%', backgroundColor: "white" }}>{this.renderTaskStatus(e.attributes.status)}
                                                </TableCell>
                                                <TableCell 
                                                    style={{ fontSize: 14, fontWeight: 400, color: "#0F172A", backgroundColor: "white", width: '15%' }}>{(e?.attributes?.priority ?? '').charAt(0).toUpperCase() + (e?.attributes?.priority ?? '').slice(1)}</TableCell>
                                                    {this.renderAction(e, userType)}
                                                    {this.renderCellUpdate(e, userType)}
                                            
                                            </TableRow> ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>) :
                this.renderEmptyData()}
            </div>
            {this.renderUpdateStatusModalTask()}
            {this.renderAddModalTask()}
            {this.renderModalsDeleteTask()}
			</PageWrapper>
		);
	}
}

export default ViewAllTask;

// Customizable Area End