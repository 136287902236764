// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from 'react';
import './AllDashboard.css';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Input, Space, Table, Spin, Row, Col } from 'antd';
import CaseController from '../../dashboard/src/CaseController.web';
import moment from 'moment';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import _ from 'lodash';
import { emptyCase } from './assets';

class CaseControllerDashboard extends CaseController {
	constructor(props: any) {
		super(props);
	}
	
	async componentDidMount() {
		this.getCasesData();
		setTimeout(() => {
			this.setState({ loadingCaseData: false });
		}, 2000);
	}

	columns = [
		{
			title: (<span className="case-title">Unique Ref. Number</span>),
			width: 280,
			ellipsis: true,
			render: (e: any) => (<span className='unique-ref-text'>{e.attributes.unique_reference_number}</span>)
		},
		{
			title: (<span className="case-title">Name</span>),
			width: 280,
			ellipsis: true,
			render: (e: any) => (
				<span className='name-text'>
					{_.upperFirst(e.attributes.full_name)}
				</span>
			),
		},
		{
			title: (<span className="case-title">Created on</span>),
			width: 200,
			ellipsis: true,
			render: (e: any) => <span className='name-text'>{moment(e.attributes.created_at).format('D MMM YYYY')}</span>
		},
		{
			title: (<span className="case-title">Action</span>),
			dataIndex: '',
			width: 240,
			key: (e: any) => e.id,
			render: (e: any) => 
			(localStorage.getItem('userType') === 'solicitor' || localStorage.getItem('userType') === 'guest') ? <Link to={`/client-dashboard?case_id=${e.id}&name=${e.attributes.full_name.charAt(0).toUpperCase() + e.attributes.full_name.slice(1)}&id=${e.attributes.client_id}`}><a className='view-text' onClick={this.handleView.bind(this,e.attributes.status, e.attributes.client_id, e.attributes.full_name)}>View</a></Link> : 
			<Link to={`/dashboard-details?case_id=${e.id}&name=${e.attributes.full_name.charAt(0).toUpperCase() + e.attributes.full_name.slice(1)}&id=${e.attributes.client_id}`}><a className='view-text' onClick={this.handleView.bind(this,e.attributes.status, e.attributes.client_id, e.attributes.full_name )}>View</a></Link>,
			
		},
	];
	handleView = (status,CientId,name)=>{
		localStorage.setItem("ClientStatus",status)
		localStorage.setItem("userCaseId",CientId)
		localStorage.setItem("clientName",name)
		localStorage.setItem("clientId",CientId)
	}
	 
	render(): React.ReactNode {
		const userType = localStorage.getItem('userType');
		const { filteredCasesData } = this.state;
		return (
			<>
			
				<PageWrapper title="Dashboard">
					<div id='case-id' className='client-content'>
					<div className='case-search-div' style={{ width: '100%' }}>
						<Row justify="space-between" align="middle" style={{ width: '100%' }}>
							<Col xs={24} sm={12} md={12} lg={12} xl={12}>
								<p className='case-title'>CASES</p>
							</Col>
							<Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right', marginTop: '8px' }}>
								<Space style={{ width: '100%', justifyContent: 'flex-end' }}>
									<Input
										className='search-input'
										onChange={this.handleSearch}
										placeholder="Search by Ref. Number / Client name"
										prefix={<SearchOutlined style={{ color: '#94A3B8', marginLeft: '6px', fontSize: '18px', }} />}
										style={{ width: '100%', maxWidth: '360px', color: '#64748B', fontSize: '16px', borderRadius: '28px', height: '44px', border: '1px solid #CBD5E1', padding: '4px' }}
									/>
								</Space>
							</Col>
						</Row>
					</div>
						<div>
						{filteredCasesData?.length > 0 ? (
						
						<Table
							className="custom-table"
							id="table"
							columns={this.columns}
							bordered
							dataSource={this.state.filteredCasesData}
							scroll={{ x: 'max-content' }}
						/>
						) : (
							

						<div style={{ textAlign: 'center', height: '400px'}}>  
						<div
							style={{
							height: '50px',
							backgroundSize: 'contain',
							backgroundImage: `url(${emptyCase})`,
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							padding:"20px",
							marginTop:"200px",
							marginBottom:"10px"
							}}
						/>
						<span style={{color: '#b3b1b0'}}>Results not found</span>
						</div>
						)}
					</div>
					</div>
				</PageWrapper>
			</>
		);
	}
}
export default CaseControllerDashboard;
// Customizable Area End