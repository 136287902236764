import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start

import { ApiCallInterface, AssessmentItemInterface, AttemptedAssessmentInterface } from './IAssessment'
import StorageProvider from "../../../framework/src/StorageProvider";
import { ChangeEvent } from "react";
export interface ClientList{
  id: number,
  full_name: string,
  status:string,
  total_assessments: number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  assessmentList: AssessmentItemInterface[],
  assessmenttestList: AssessmentItemInterface[],
  isError: boolean;
  assessmentCategories: AssessmentItemInterface[];
  isSuccess: boolean;
  selectedCategory: string | undefined;
  selectedId: string | number | undefined;
  tabNum: number
  attemptedAssessment: AttemptedAssessmentInterface[];
  isLoading: boolean;
  clientListLoader:boolean,
   pagination:number | null
  clientNameList:ClientList[],
  filterStatus:boolean,
  errorMsg:string
  filteredClientData:ClientList[]
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetAssessmentCategories: string = "";
  apiGetAssessmentByCategory: string = "";
  apiAttemptedAssessment: string = "";
  apiGetClientListCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      assessmentList: [],
      assessmenttestList: [],
      isError: false,
      pagination:Number(localStorage.getItem("ClientPage")),
      isLoading: true,
      isSuccess: false,
      assessmentCategories: [],
      selectedCategory: '',
      selectedId: '0',
      tabNum: 1,
      attemptedAssessment: [],
      clientListLoader: false,
      clientNameList:[],
      filterStatus:false,
      filteredClientData:[],
      errorMsg:""
    }
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


// Customizable Area Start
  async componentDidMount() {
    this.getAssessmentCategoriesApi()
    this.apiGetClientList()
    this.getAttemptedAssessmentApi()
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.assessmentCategories !== this.state.assessmentCategories) {
      this.setState({
        selectedId: `${this.state?.assessmentCategories![0]?.id}`
      })
    }

  }

  apiAssessmentCall = async (data: ApiCallInterface) => {
    const { contentType, method, endPoint, body } = data;
    let token = await StorageProvider.get("USER_TOKEN")
    const header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiGetClientList = async () => {
    let token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    this.setState({clientListLoader:true});
    const body =null
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.apiGetClientListCallId = requestMessage.messageId;
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiStatement(message)
    }
    if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetClientListCallId &&
			this.apiGetClientListCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({clientListLoader:false});
			if(responseJson.clients){
				this.setState({clientNameList:responseJson.clients, filteredClientData: responseJson.clients})
			}
      else{
        this.setState({errorMsg:responseJson.message})
      }
			
		}
    // Customizable Area End
  }

  // Customizable Area Start

  handleApiStatement(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (responseJson && responseJson.data) {
      if (apiRequestCallId === this.apiGetAssessmentByCategory) {
        this.getAssessmentByCategorySuccesscallBack(responseJson.data);
      }
      else if (apiRequestCallId === this.apiGetAssessmentCategories) {
        this.getCatSuccesscallBack(responseJson.data,)
      }
      else if (apiRequestCallId === this.apiAttemptedAssessment) {
        this.getAttemptedAssessmentApiSuccesscallBack(responseJson.data)
      }
    }  else if (errorReponse) {
      this.setState({
        isLoading: false,
        
        isSuccess: false,
        isError: true,
      })
    }
  }


  getAssessmentCategoriesApi = async () => {
    this.apiGetAssessmentCategories = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAssessementTestCategoriesUrl,
    });
  };
  getAssessmentByCategoryApi = async () => {
    this.apiGetAssessmentByCategory = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAllAssessementTestUrl,
    });
  };
  getAttemptedAssessmentApi = async () => {
    this.apiAttemptedAssessment = await this.apiAssessmentCall({
      contentType: configJSON.allAssessementTestApiContentType,
      method: configJSON.getAssessementMethod,
      endPoint: configJSON.getAttemptedAssessmentsUrl,
    });
  };

  getAssessmentByCategorySuccesscallBack =  (data: AssessmentItemInterface[]) => {
    let filterData = data.filter((item: AssessmentItemInterface) => {
      {
        return item.attributes?.assessment_type === this.state.selectedCategory
      }
    })
    this.setState({
      isLoading: false,
      isError: false,
      isSuccess: true,
      assessmentList: data,
      assessmenttestList: filterData
    })
  };

  getCatSuccesscallBack =  (data: AssessmentItemInterface[]) => {
    this.setState({
      assessmentCategories: data,
      selectedCategory: data[0].attributes?.name,
      selectedId: data[0].id
    },
      () => {
        this.getAssessmentByCategoryApi()
      }
    )
  };


  getAttemptedAssessmentApiSuccesscallBack =  (data: AttemptedAssessmentInterface[]) => {
    this.setState({
      attemptedAssessment: data
    })
  };

  

  onSelected(item: AssessmentItemInterface) {
    this.props.navigation.navigate('AssessmentDetail', { id: item.id })
  }
  onAttemptedSelected(item: AttemptedAssessmentInterface) {
    this.props.navigation.navigate('AssessmentResult', { id: item.attributes?.assessment_id })
  }

  onReFetch() {
    this.setState({
      isLoading: true,
      isError: false,
    }, () => {
      this.getAssessmentCategoriesApi()
    }

    )
  }

  convertMinutesToHoursAndMinutes(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let result = hours + " hour";
    if (hours !== 1) {
      result += "s";

    }
    result += remainingMinutes + " minute";
    if (remainingMinutes !== 1) {
      result += "s";
    }
    return result;
  }


  onTabClick(numInput: number) {
    this.setState({
      tabNum: numInput
    })
  }
  handleCatSelected(item: AssessmentItemInterface) {
    this.setState({ selectedId: `${item?.id}` })
    let list = this.state.assessmentList?.filter((newItem: AssessmentItemInterface) => {
      return newItem?.attributes?.assessment_type === item.attributes?.name
    })

    this.setState({
      assessmenttestList: list
    })
  }

  handleSearch = (text: string) => {
    if (text.trim() === '') {
      let first = this.state.assessmentList.filter((item: AssessmentItemInterface) => {
        {
          return item?.attributes?.assessment_type === this.state.selectedCategory
        }
      })

      this.setState({
        assessmenttestList: first
      })
    } else {
      const filtered = this.state.assessmenttestList.filter((item: AssessmentItemInterface) => {
        const name = item.attributes?.name?.toLowerCase() ?? 'Unknown';
        return name?.includes(text.toLowerCase())
      });
      this.setState({
        assessmenttestList: filtered
      })
    }
  }

  unCompletedListAssessment(){
    let unCompletedList: AssessmentItemInterface[] = this.state.assessmenttestList.filter((item) => item.attributes?.is_completed !== true)
    return unCompletedList
  }
  getDataSource = () => {
    return this.state.filteredClientData
  }
  getClientID = async(event:ClientList)=>{
    localStorage.setItem("ClientStatus",event.status)  
    localStorage.setItem("PageNum","1")
  const goToDetails = new Message(getName(MessageEnum.NavigationMessage));
		goToDetails.addData(getName(MessageEnum.NavigationTargetMessage), "AssessmentTestDetail");

		goToDetails.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		const raiseMessage: Message = new Message(
			getName(MessageEnum.NavigationPayLoadMessage)
		);
		goToDetails.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

		this.send(goToDetails);
  await StorageProvider.set("clientId",event.id)

  }

  handleSearchData = (event: ChangeEvent<HTMLInputElement>) => {
    const search_Text = event.target.value.toLowerCase();
    const filtered_Data = this.state.clientNameList.filter((item: ClientList) =>
      item.full_name.toLowerCase().includes(search_Text)
    );

    this.setState({ filteredClientData: filtered_Data });
  };
  // Customizable Area End
}
