// Customizable Area Start
import React from 'react';
import PageWrapper from '../../../components/src/Pagewrapper.web';
import UserLibraryController from "../../catalogue/src/UserLibraryController.web";
import { Divider } from 'antd';
import './UserLibrary.css';
import moment from 'moment';

class CaseManagementDetails extends UserLibraryController {
	constructor(props: any) {
		super(props);
	}
    async componentDidMount(){
        this.getSingleFile(this.getUrlParameter("id"));
      }
	render(): React.ReactNode {
		return (
			<PageWrapper title="User library" hasBack>
                <div id='case-id' className='details-entry-div'>
                    <p data-test-id="title" className='details-title'>
                        {this.state.title}
                    </p>
                    <p className='case-management-date' style={{marginBottom: '20px'}}>Created on <span style={{fontSize: '12px', fontWeight: 600}}>{moment(this.state.created_at).format('D MMM YYYY | h:mm A')}</span></p>
                    <Divider style={{ backgroundColor: '#E2E8F0'}} />
                    <p className='details-note'>
                        {this.state.entry}
                    </p>
                </div>
			</PageWrapper>
		);
	}
}

export default CaseManagementDetails;

// Customizable Area End