// Customizable Area Start
import React from 'react';
import { Button, Col, Divider, Form, Input, Row, Spin, DatePicker } from "antd";
import type { FormInstance } from 'antd';
import UserLibraryController from '../../blocks/catalogue/src/UserLibraryController.web';
import PageWrapper from './Pagewrapper.web';
import moment from "moment";
class UpdateEntry extends UserLibraryController {
    formRef: React.RefObject<FormInstance>;
	constructor(props: any) {
		super(props);
        this.formRef = React.createRef<FormInstance>();
	}

    navigateBack = () => {
        window.history.back();
    }

    async componentDidMount() {
        this.getSingleFile(this.getUrlParameter("id"));
    }

	render(): React.ReactNode {
		return (
            <PageWrapper title="Update Entry" hasBack>
                <div className='create-case-management-content'>
                    <div data-test-id="update-form">
                        {this.state.loadingData ? (
                            <Spin />
                        ) : (
                            <Form
                                className="form"
                                ref={this.formRef}
                                layout="vertical"
                                initialValues={{
                                    title: this.state.title,
                                    entry: this.state.entry,
                                    entry_date: moment(this.state.entry_date)
                                }}
                                style={{ padding: "19px" }}
                                onFinish={(values) => {
                                    this.updateNote(
                                        this.getUrlParameter("id"),
                                        values.title,
                                        values.entry,
                                        values.entry_date
                                    );
                                    this.navigateBack();
                                }}
                            >
                                <Row gutter={12}>
                                    <Col xs={8}>
                                        <Form.Item
                                            label="Title"
                                            name="title"
                                            className="bold-label"
                                            rules={[
                                                { required: true, message: 'Please enter title' },
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || value.trim() === '' || /\s{2,}/.test(value)) {
                                                            return Promise.reject('Please enter a valid title without multiple spaces');
                                                        }
                                                        return Promise.resolve();
                                                    }
                                                }
                                            ]}
                                        >
                                            <Input maxLength={20} placeholder="Title of Entry" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={8}>
                                        <Form.Item
                                            label="Date"
                                            name="entry_date"
                                            className="bold-label"
                                            rules={[
                                                { required: true, message: 'Please select date' }
                                            ]}
                                        >
                                            <DatePicker
                                                placeholder="Date of Entry"
                                                id="date"
                                                className="font-style time-design"
                                                format="DD MMM YYYY"
                                                picker="date"
                                                inputReadOnly={true}
                                                getPopupContainer={this.handlePopupContainer}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24}>
                                        <Form.Item
                                            label="Entry"
                                            name="entry"
                                            className="bold-label"
                                            style={{ marginBottom: 0 }}
                                            rules={[
                                                { required: true, message: 'Please enter note' },
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || value.trim() === '' || /\s{2,}/.test(value)) {
                                                            return Promise.reject('Please enter a valid note without multiple spaces');
                                                        }
                                                        return Promise.resolve();
                                                    }
                                                }
                                            ]}
                                        >
                                            <Input.TextArea style={{ height: '452px', resize: 'none' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider className="c-divider" />
                                <Row style={{ marginTop: '18px' }} justify="end">
                                    <Col>
                                        <a onClick={this.navigateBack} data-test-id="cancel" className='cancel-case'>
                                            Cancel
                                        </a>
                                        <Button
                                            data-test-id="update-entry"
                                            htmlType="submit"
                                            type="primary"
                                            style={{
                                                background: "#830065",
                                                borderColor: "#830065",
                                                borderRadius: "8px",
                                                marginBottom: "20px",
                                                height: "56px",
                                                width: '150px',
                                                color: "white",
                                                fontWeight: "bold",
                                                marginRight: '20px'
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </div>
                </div>
		    </PageWrapper>
		);
	}
}

export default UpdateEntry;