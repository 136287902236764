// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
	navigation: any;
	id: string;
	match: any;
}

interface S {
	contactList: any,
    loadingContactList: boolean,
}

interface SS {
	id: any;
}



export default class ContactController extends BlockComponent<Props, S, SS> {

	apiResetGetMyContactsFormCallId: string = "";
   
	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.NavigationPayLoadMessage),
		];
		this.state = {
			contactList: [],
            loadingContactList: false
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async receive(from: string, message: Message) {
		this.receiveMyContacs(message);
	}

	async receiveMyContacs(message: any) {
		if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.apiResetGetMyContactsFormCallId &&
            this.apiResetGetMyContactsFormCallId ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
          
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(responseJson && responseJson.data){
			this.setState({contactList: responseJson && responseJson.data, loadingContactList: false});
          }else{
            this.setState({loadingContactList: false})
          }
        }
	}

    getContactList = () => {
        const token = localStorage.getItem("token");
        const case_id = localStorage.getItem("caseID");
        this.setState({loadingContactList: true});
		const header = {
		  "Content-Type": configJSON.getUserListApiContentType,
          "token": token
		};
		const requestMessage = new Message(
		  getName(MessageEnum.RestAPIRequestMessage)
		);
	
		this.apiResetGetMyContactsFormCallId = requestMessage.messageId;
	
		requestMessage.addData(
		  getName(MessageEnum.RestAPIResponceEndPointMessage),
		  `${configJSON.getMyContactsApiEndPoint}${case_id}`
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestHeaderMessage),
		  JSON.stringify(header)
		);
		requestMessage.addData(
		  getName(MessageEnum.RestAPIRequestMethodMessage),
		  configJSON.getUserListApiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	  };
}
// Customizable Area End