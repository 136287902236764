// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React, { Component } from 'react';
import './DefaultLayout.css';
import {  PlusCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Button } from 'antd';
import EmailAccountLoginController from '../../blocks/email-account-registration/src/EmailAccountRegistrationController.web';
import { Redirect, Link } from "react-router-dom";



class AppointmentDetailsView extends EmailAccountLoginController {
	constructor(props: any) {
		super(props);
	}
  
	render(): React.ReactNode {
        if(this.state.redirect){
			return(
				<Redirect data-test-id = "login-redirect" to = '/login' />);
        }
  
		return (
			<>
				<div 
					data-test-id="verify-div"
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						minHeight: '100vh',
					}}>
                    <h1>Email Verification</h1>
                    <h2>Please click verify to change the below email.</h2>
                    <h3><strong>{this.getUrlParameter('email')}</strong></h3>
                    <div 
					data-test-id="div2"
					style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <Button
							onClick={() => this.verifyEmail()}
                            data-test-id="verify_email"
                            type="primary"
                            style={{
                                background: "#830065",
                                borderColor: "#830065",
                                borderRadius: "5px",
                                height: "45px",
                                width: "100px",
                                color: "white",
                                fontWeight: "bold",
                                marginRight: "10px",
                            }}
                        >
                            Verify
                        </Button>
						<Link to="/dashboard">
							<p style={{color: '#830065', textAlign: 'center', padding: '13px', fontSize: '16px', fontWeight: 'bold'}}>Cancel</p>
						</Link>
						
                    </div>
                </div>

			</>
		);
	}
}

export default AppointmentDetailsView;
// Customizable Area End