Object.defineProperty(exports, "__esModule", {
    value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.postType = "POST";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.getAssessementTestAPiMethod = "GET";
exports.assessmentByIdApiContentType = "application/json";
exports.getAssessementTestByIdUrl = `bx_block_assessmenttest/assessments`;
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AssessmentTest";
exports.labelBodyText = "AssessmentTest Body";
exports.assessmentData= "bx_block_assessmenttest/assessments?client_id=";
exports.addAssessmentData= "bx_block_assessmenttest/assessments";
exports.assessmentHistory="bx_block_assessmenttest/assessments/assessment_history?client_id="



// Customizable Area End

