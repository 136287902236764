// Customizable Area Start
//@ts-ignore
//@ts-nocheck
import React from "react";
import { Col, Row, Button, Form, Input, Tooltip, Spin } from "antd";
import "./ForgotPassword.css";
import EmailAccountLoginController from "../../email-account-registration/src/EmailAccountRegistrationController.web";
import { InfoCircleOutlined } from "@ant-design/icons";
import rehab from "../assets/rehab.png";
import forgot_image from "../assets/forgot.png";
import { Redirect } from "react-router-dom";
import type { FormInstance } from 'antd/es/form';

class ResetPassword extends EmailAccountLoginController {
	constructor(props: any) {
		super(props);
        this.formRef = React.createRef<FormInstance>();
	}
    async componentDidMount(){
		this.getEmail();
        this.getDynamicTextAndImageForForgot()
	}
	render(): React.ReactNode {
        if(this.state.redirect){
			return(
				<Redirect id = "login-redirect" to = '/login' />);
        }
        const toolipText = (
			<div style={{maxWidth: "500px"}}>
				<ul>
					<li>At least one capital letter</li>
					<li>At least one lowercase letter</li>
					<li>At least one number</li>
					<li>Minimum character length is 8 characters</li>
				</ul>
			</div>

		);
		return (
			<>
				<Row style={{height:'100%'}}>
                    <Col xs={24} sm={24} md={12}>
						<div className="forgot-centered-content" style={{padding:'0 20px 0 20px'}}>
                        <img 
                            alt="Rehab"
                            src={rehab}
                            style={{
                                marginTop: "120px",
                                width: "100%",
                                marginBottom: '44px', 
                                height: "auto", 
                                maxWidth: "435px",
                            }}
                                    
							/>
							<img src={this.state.image_data}  style={{ width: "100%", height: "auto", marginBottom: '70px', maxWidth: "523px" }} alt="rehab"
								
							/>
							<p  style={{ margin: "auto",  maxWidth: "423px", textAlign: "center", marginBottom: "16px"}} className="forgot-headline-1">{this.state.title}</p>

							<p  style={{ marginBottom: "16px" , wordBreak: "break-word", maxWidth: "423px", margin: "auto", textAlign: "center"}} className="forgot-hi-there">{this.state.description}
							</p>
						</div>
					</Col>
					<Col xs={24} sm={24} md={12}>
						<div id="forgot" className="forgot-section">
                        <p className="forgot-headline-2">Reset Password</p>
                        <div className="centered-content-right1" style={{ marginTop: '10px', width: '100%' }}>
                            {
                                	this.state.loadingEmailData ? <Spin id="spin" /> : 
                                    <Form
                                        ref={this.formRef}
                                        layout="vertical"
                                        initialValues={{
                                            email: this.state.email,
                                        }}
                                        className="form"
                                        onFinish={this.onResetPassword}
                                        style={{padding:'0 20px 0 20px'}}
                                >
                                        <Row gutter={12}>
                                            <Col xs={24}>
                                                <Form.Item
                                                    label="Email"
                                                    name="email"
                                                    className="disable-label"
                                            >
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    className="bold-label"
                                                    label="Password"
                                                    name="password_confirmation"
                                                    rules={[{validator: this.passwordValidation}]}
                                                >
                                                    <Input.Password placeholder="Enter Your Password" />
                                                </Form.Item>
                                                <div className="toolip">
                                                    <span style={{fontSize: '12px', fontWeight: '400', color: '#0F172A'}}>
                                                        Password instructions
                                                    </span>&nbsp;
                                                    <Tooltip placement="bottomLeft" title={toolipText} color="white">
                                                        <InfoCircleOutlined style={{color: "#830065"}} />
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                            <Col xs={24}>
                                                <Form.Item
                                                    className="bold-label"
                                                    label="Confirm Password"
                                                    name="password"
                                                    dependencies={['password_confirmation']}
                                                    onPaste={(e) => e.preventDefault()}
                                                    rules={[{required:'true', message:''},({ getFieldValue }) => ({validator: (_: any, value: string) => this.confirmPasswordValidator(value, getFieldValue('password_confirmation'))})]}
                                                >
                                                    <Input.Password onChange={(e) => this.setState({ resetPasswordErrorMessage: null })} placeholder="Confirm your password" />
                                                </Form.Item>

                                            </Col>
                                            {(this.state.resetPasswordErrorMessage !== "Password should be match with Password Confirmation" &&
                                            this.state.resetPasswordErrorMessage !== "Password is invalid") ? (
                                                <span style={{ color: '#DC2626', float: 'left', textAlign: 'left' }}>
                                                    {this.state.resetPasswordErrorMessage}
                                                </span>
                                            ) : null}
                                            <Col xs={24}>
                                                <Button
                                                    data-test-id="reset_button"
                                                    onClick={() => this.onResetPassword(this.formRef.current.getFieldsValue())}
                                                    htmlType="submit"
                                                    type="primary"
                                                    loading={this.state.loadingResetPassword}
                                                    style={{
                                                        background: "#830065",
                                                        borderColor: "#830065",
                                                        borderRadius: "5px",
                                                        height: "45px",
                                                        marginTop: "10px",
                                                        width: "100%",
                                                        color: "white",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Reset Password
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                            }
                            </div>
                           
                        </div>
					</Col>
                    
				</Row>
			</>
		);
	}
}

export default ResetPassword;
// Customizable Area End
