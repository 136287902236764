Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.putApiMethodType = "PUT";
exports.deleteApiMethodType = "DELETE";
exports.patchApiMethodType = "PATCH";
exports.goalManagementURLEndPoint = "bx_block_goal_management/goal_managements";
exports.currentUserGoalURLEndPoint =
  "bx_block_goal_management/goal_managements/get_user_goals";
exports.getGroupURLEndpoint =
  "bx_block_goal_management/goal_managements/user_groups";

exports.loginURLEndPoint = "bx_block_login/logins";
exports.loginEmail = "adminTeam@gmail.com";
exports.loginPassword = "123456";

exports.dateFormat = "MM/DD/YYYY";
exports.dateTimeFormat = "HH:mm A, MM/DD/YYYY";

exports.errorGetListMessage = "No goals present!";

exports.btnExampleTitle = "CLICK ME";
exports.getClientApiEndPoint = "bx_block_goal_management/goal_managements/clients";
exports.addLongGoalApiEndPoint = "bx_block_goal_management/goal_managements";
exports.getClientGoalApiEndPoint = "bx_block_goal_management/goal_managements/client_goal?client_id=";
exports.getDeleteGoalApiEndPoint = "bx_block_goal_management/goal_managements/";
exports.getDeleteLongGoalApiEndPoint = "bx_block_goal_management/goal_managements/delete_long_term_goals/";
exports.getUpdateGoalApiEndPoint = "bx_block_goal_management/goal_managements/";
exports.reassign = "/reassign_goal?client_id="
exports.getClientGoalUpdateApiEndPoint = "bx_block_goal_management/goal_managements/";
exports.getLongTermGoalUpdateApi = "bx_block_goal_management/goal_managements/";
exports.getLongTermGoalUpdateApi2 = "/update_long_term_status?token=";

exports.getUserGoalsEndApi = "bx_block_goal_management/goal_managements/client_goal?client_id=";
exports.getUserEditGoalEndApi = "bx_block_goal_management/goal_managements/update_status?id=";
exports.getClientStatus = "&client_status=";
exports.getShortMediumGoal = "bx_block_goal_management/goal_managements/";
exports.getShortMediumGoal2 = "/show_medium_and_short_term_goals";
exports.getLongTermGoal1 = "bx_block_goal_management/goal_managements/";
exports.getLongTermGoal2 = "/update_long_term_status";
exports.getLongTermGoal3 = "/update_long_term_title";
exports.changeFlag = "bx_block_goal_management/goal_managements/";
exports.changeFlag2 = "/update_long_term_goal_flag";









// Customizable Area End
